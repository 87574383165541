import { startCase } from 'lodash'
import uuid from 'uuid/v4'
import { TEMPORARY_ID_PREFIX } from './constants'
import type { EventResult } from '@mission.io/mission-toolkit/actions'
import { cloneDeep } from 'lodash'
import ObjectId from 'bson-objectid'

export const SECONDS_TO_MS = (sec: number): number => sec * 1000
export const MINUTES_TO_MS = (min: number): number => SECONDS_TO_MS(min) * 60

/**
 * When given a string of the format TYPE_STRING, prettify it by changing it to lower case, replacing '_' with spaces,
 * and capitalizing the first letter.
 * @param {string} type
 */
export function prettifyTypeEnum(type: string): string {
	let prettyStr: string = type.toLowerCase()
	prettyStr = prettyStr.replace(/_/g, ' ')
	return startCase(prettyStr)
}
export function humanFileSize(bytes: number, si = true): string {
	const threshold = si ? 1000 : 1024

	if (Math.abs(bytes) < threshold) {
		return bytes + ' B'
	}

	const units = si
		? ['kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
		: ['KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB']
	let unit = -1

	do {
		bytes /= threshold
		unit++
	} while (Math.abs(bytes) >= threshold && unit < units.length - 1)

	return bytes.toFixed(1) + ' ' + units[unit]
}

/**
 * The array map function, implemented for object. The result will be an array of
 * values resulting from running the given callback on each entry in the object.
 * @param  {Object}   object   The object
 * @param  {Function} callback The callback function
 */
export function mapEntries<T extends object, U>(
	object: T,
	callback: <K extends keyof T>(currentValue: Required<T>[K], currentKey: K) => U
): U[] {
	const mappedValues: U[] = []

	for (const key in object) {
		if (object.hasOwnProperty(key)) {
			mappedValues.push(callback(object[key], key))
		}
	}

	return mappedValues
}

/**
 * Run a callback on each own key value pair in object
 * @param  {Object}   object   The object
 * @param  {Function} callback The callback function
 */
export function forEachEntry<T extends object>(
	obj: T,
	callback: <K extends keyof T>(value: Required<T>[K], key: K) => void
) {
	for (const key in obj) {
		callback(obj[key], key)
	}
}

/**
 * Finds the first entry that passes the test implemented by the provided function
 * @param {Object} object The object containing the entries to be tested
 * @param {Function} callback The callback function
 */
export function findEntry<T extends object>(
	object: T,
	callback: <K extends keyof T>(value: Required<T>[K], key: K) => boolean
): T[keyof T] | null {
	for (const key in object) {
		if (object.hasOwnProperty(key)) {
			if (callback(object[key], key)) return object[key]
		}
	}

	return null
}

/**
 * Checks if a given xy point is inside of a rectangle type.
 * @param {{x: number, y: number}} point
 * @param {{x: number, y: number, width: number, height: number}} rect
 * @returns boolean
 */
export function pointInsideRect(
	point: {
		x: number
		y: number
	},
	rect: {
		x: number
		y: number
		width: number
		height: number
	}
): boolean {
	return (
		rect.x < point.x &&
		point.x < rect.x + rect.width &&
		rect.y < point.y &&
		point.y < rect.y + rect.height
	)
}

/**
 * Given a number of milliseconds, formats time into a string of the format 'mm:ss'
 * where m = minutes, s = seconds
 * @param {number} totalSeconds
 */
export function getTimeFormat(totalMs: number, showMs: boolean): string {
	const totalSeconds = Math.floor(totalMs / 1000)
	const totalMinutes = Math.floor(totalSeconds / 60)
	const milliseconds = totalMs % 1000
	const seconds = totalSeconds % 60
	const minutes = totalMinutes % 60

	const withZero = (num: number) => {
		if (num / 10 >= 1) return `${num}`
		return `0${num}`
	}

	return `${minutes}:${withZero(seconds)}${showMs ? `.${milliseconds}` : ''}`
}

/**
 * Creates a new temporary id which the server should replace
 */
export function createTemporaryId(): string {
	return TEMPORARY_ID_PREFIX + uuid()
}

/**
 * Creates a new questionInfo option
 */
export function createOption(): {
	_id: string
	correct: boolean
	text: string
	result: EventResult<string>
	imageUrl: string | null | undefined
	description: string
} {
	return {
		text: '',
		result: [],
		correct: false,
		_id: createTemporaryId(),
		description: '',
		imageUrl: '',
	}
}

/**
 * Test whether any value in an object passes the test implemented by the provided function
 * @param  {Object}   object   The object
 * @param  {Function} callback The callback function
 */
export function someEntry<T extends object>(
	object: T,
	callback: <K extends keyof T>(value: Required<T>[K], key: K) => boolean
): boolean {
	for (const key in object) {
		if (object.hasOwnProperty(key)) {
			if (callback(object[key], key)) {
				return true
			}
		}
	}

	return false
}

/**
 * Checks whether the given object is an object that has the given key.
 * does typescript type narrowing.
 */
export function isObjectWithKey<T extends string>(
	obj: unknown,
	key: T
): obj is Record<string, unknown> & Record<T, unknown> {
	return typeof obj === 'object' && obj !== null && key in obj
}
/**
 * Checks whether the given id is a temporary id
 * @param {string} id
 * @returns
 */
function isTempId(id: string): boolean {
	return id.startsWith(TEMPORARY_ID_PREFIX)
}

/**
 * Checks if string is valid object id.
 * @param {string} id
 * @returns
 */
function stringIsValidObjectId(id: string): boolean {
	return ObjectId.isValid(id) && String(ObjectId(id)) === id
}

/**
 * Duplicates a given object and also replaces all ids in the object with temporary ids.
 * @param {{}} object
 */
export function duplicateObjectAndReplaceIds<O extends Record<string, unknown>>(object: O): O {
	/** Run the provided callback on an object with an id. object is typed as any because it could be an object or a array */
	const newObject = cloneDeep(object)
	forEachPossibleIdInObject(
		{ parent: newObject, key: null, currentPath: '' },
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		(_object: any, idLookupKey: string | number, currentPath: string) => {
			const id = _object[idLookupKey]
			if (id) {
				_object[idLookupKey] = createTemporaryId()
			}
		}
	)
	return newObject
}

/**
 * Given an arbitrary object, iterates through all keys in the object, calling the provided callback on each id that looks like a
 * temp id or an object id.
 *
 * Copy of forEachPossibleIdInObject in simulations-api: src/resources/actions.js
 * @param {any} params.parent The parent of the object to iterate through, if `parent` is the root object, then `parent` is the object we will iterate through
 * @param {?string} params.key A key of the parent object. If `parent` is the root object, this should be null
 * @param {string} params.currentPath The path to the current object, provided as a parameter to the callback
 * @param {(parent: any, key: string|number, currentPath: string) => void} callback the callback to run on each id
 */
function forEachPossibleIdInObject(
	{
		parent,
		key,
		currentPath,
	}: {
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		parent: any
		key: string | number | null | undefined
		currentPath: string
	},
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	runIdCallback: (parent: any, key: string | number, currentPath: string) => void
) {
	const object = key != null ? parent[key] : parent
	if (!object) {
		return
	}
	if (
		key != null &&
		typeof object === 'string' &&
		(isTempId(object) || stringIsValidObjectId(object))
	) {
		runIdCallback(parent, key, currentPath)
	} else if (Array.isArray(object)) {
		object.forEach((_, index) => {
			forEachPossibleIdInObject(
				{
					parent: object,
					key: index,
					currentPath: `${currentPath}[${index}]`,
				},
				runIdCallback
			)
		})
	} else if (typeof object === 'object') {
		Object.keys(object).forEach((subKey) => {
			forEachPossibleIdInObject(
				{
					parent: object,
					key: subKey,
					currentPath: `${currentPath ? currentPath + '.' : ''}${subKey}`,
				},
				runIdCallback
			)
		})
	}
}

const ALPHABET = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'.split('')

/**
 * Converts an index to the corresponding character in the alphabet
 * 0 => A
 * 1 => B
 * 25 => Z
 * 26 => A
 * @param {number} index The index to convert
 * @return {string} The corresponding character
 */
export function indexToAlpha(index: number): string {
	// non-null assertion is safe because we know the index is within the bounds of the array
	// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
	return ALPHABET[index % ALPHABET.length]!
}
