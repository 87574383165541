import React, { useState, useEffect, ChangeEvent } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'
import produce from 'immer'
import {
	Button,
	Modal,
	ModalHeader,
	ModalBody,
	ModalFooter,
	Label,
	Input,
	Container,
} from 'reactstrap'
import { Modal as AssetModal } from '../../common/AssetManager'
import { toast } from 'react-toastify'
import { FaPlus } from 'react-icons/fa'
import FontAwesome from 'react-fontawesome'
import CharacterBox from './CharacterBox'
import {
	getCharacters,
	setCharacters,
	setCharacter,
	updateCharacter,
} from '../../reducers/characters'
import { createCharacter, getAllCharacters, patchCharacter } from './CharacterRoutes'
import type { Character } from '../../reducers/characters'
import type { File } from '../../common/AssetManager'
const ICON_URL = 'ICON_URL'
const URL = 'URL'
export default function CharacterBank(): JSX.Element {
	const dispatch = useDispatch()
	const [searchTerm, setSearchTerm] = useState('')
	const [characterModal, setCharacterModal] = useState(false)
	const [assetModalType, setAssetModalType] = useState<typeof ICON_URL | typeof URL | null>(null)
	// characterModal details
	const [characterName, setCharacterName] = useState('')
	const [characterDescription, setCharacterDescription] = useState('')
	const [characterIconUrl, setCharacterIconUrl] = useState('')
	const [characterUrl, setCharacterUrl] = useState('')
	const [characterId, setCharacterId] = useState<string | null>(null)
	const [characterAttributes, setCharacterAttributes] = useState<
		ReadonlyArray<
			Readonly<{
				_id?: string
				name: string
				value: string
			}>
		>
	>([])
	const characters = useSelector(getCharacters)
	useEffect(() => {
		getAllCharacters().then((charactersFromServer) => dispatch(setCharacters(charactersFromServer)))
	}, [dispatch])

	/**
	 * When we close the modal, we want to clear it out so data does not remain.
	 * So we set all the modal variables to default.
	 */
	const onCharacterModalClose = () => {
		setCharacterName('')
		setCharacterDescription('')
		setCharacterIconUrl('')
		setCharacterUrl('')
		setCharacterId(null)
		setCharacterAttributes([])
		setCharacterModal(false)
		setAssetModalType(null)
	}

	/**
	 * When we edit a character, we take its values and set them in the modal
	 * for a + 1 delight.
	 */
	const onEditCharacter = (character: Character) => {
		setCharacterName(character.name)
		setCharacterDescription(character.description)
		setCharacterIconUrl(character.iconUrl)
		setCharacterUrl(character.url)
		setCharacterId(character._id)
		setCharacterAttributes(character.attributes || [])
		setCharacterModal(true)
	}

	/**
	 * Verifies that all fields are filled out in the character modal.
	 */
	const canSubmitModalData = (): boolean => {
		let attributes = true
		characterAttributes.forEach((attribute) => {
			if (!attribute.name || !attribute.value) {
				attributes = false
			}
		})
		return Boolean(
			characterName && characterDescription && characterUrl && attributes && characterIconUrl
		)
	}

	return (
		<Container>
			<ToolDiv>
				<Searchbar
					value={searchTerm}
					type="text"
					placeholder="Search..."
					onChange={(e: ChangeEvent<HTMLInputElement>) => setSearchTerm(e.target.value)}
				/>
				<AddCharacterButton color="primary" onClick={() => setCharacterModal(true)}>
					<AddButton />
					Add Character
				</AddCharacterButton>
			</ToolDiv>
			<div>
				{characters.map((character) => {
					if (character.name.toLowerCase().search(searchTerm.toLowerCase()) === -1) return null
					return (
						<CharacterBox
							key={character.name}
							onEditCharacter={onEditCharacter}
							character={character}
						/>
					)
				})}
			</div>
			<Modal isOpen={characterModal}>
				<ModalHeader>{characterId ? 'Edit Character' : 'New Character Creator'}</ModalHeader>
				<ModalBody>
					<Label for="name">Name</Label>
					<CharacterNameInput
						name="name"
						type="text"
						value={characterName}
						onChange={(e: ChangeEvent<HTMLInputElement>) => setCharacterName(e.target.value)}
					/>
					<Label for="description">Description</Label>
					<CharacterDescriptionInput
						name="description"
						type="text"
						value={characterDescription}
						onChange={(e: ChangeEvent<HTMLInputElement>) => setCharacterDescription(e.target.value)}
					/>
					<Label for="iconUrl">Character Icon Url</Label>
					<div>
						{characterIconUrl && <Img src={characterIconUrl} />}
						<Button
							name="iconUrl"
							color="primary"
							size="sm"
							onClick={() => setAssetModalType(ICON_URL)}>
							{characterIconUrl ? 'Edit URL' : 'Choose a character icon'}
						</Button>
						{characterIconUrl && (
							<Button
								className="ml-3"
								color="danger"
								size="sm"
								onClick={() => {
									setCharacterIconUrl('')
								}}>
								Delete
							</Button>
						)}
					</div>
					<Label for="url">Character Image Url</Label>
					<div>
						{characterUrl && <Img src={characterUrl} />}
						<Button name="url" color="primary" size="sm" onClick={() => setAssetModalType(URL)}>
							{characterUrl ? 'Edit URL' : 'Choose a character'}
						</Button>
						{characterUrl && (
							<Button
								className="ml-3"
								color="danger"
								size="sm"
								onClick={() => {
									setCharacterUrl('')
								}}>
								Delete
							</Button>
						)}
					</div>
					<div>
						<div className="mt-4 mb-2">Attributes</div>

						{characterAttributes.map((attribute, index) => {
							const id: string = attribute._id || index.toString()
							return (
								<Attribute key={id}>
									<div>
										<Label for={`${id}_name`}>Attribute Name</Label>
										<Input
											name={`${id}_name`}
											type="text"
											value={attribute.name}
											onChange={(e) => {
												const val = e.currentTarget.value
												setCharacterAttributes((state) =>
													produce(state, (draft) => {
														// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
														draft[index]!.name = val
													})
												)
											}}
										/>
									</div>
									<div>
										<Label for={`${id}_value`}>Attribute Value</Label>
										<Input
											name={`${id}_value`}
											type="text"
											value={attribute.value}
											onChange={(e) => {
												const val = e.currentTarget.value
												setCharacterAttributes((state) =>
													produce(state, (draft) => {
														// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
														draft[index]!.value = val
													})
												)
											}}
										/>
									</div>
									<Button
										className="mt-2"
										color="danger"
										size="sm"
										onClick={() => {
											setCharacterAttributes((state) =>
												produce(state, (draft) => {
													draft.splice(index, 1)
												})
											)
										}}>
										<FontAwesome name="trash" />
									</Button>
								</Attribute>
							)
						})}
						<Button
							className="mt-1"
							color="primary"
							size="sm"
							onClick={() =>
								setCharacterAttributes((state) => [
									...state,
									{
										name: '',
										value: '',
									},
								])
							}>
							Add Attribute
						</Button>
					</div>
					<AssetModal
						collection={'characterImages'}
						isOpen={Boolean(assetModalType)}
						onClose={() => setAssetModalType(null)}
						onFileClick={(file: File) => {
							if (assetModalType === ICON_URL) {
								setCharacterIconUrl(file.url)
							} else if (assetModalType === URL) {
								setCharacterUrl(file.url)
							}

							setAssetModalType(null)
						}}
					/>
				</ModalBody>
				<ModalFooter>
					<Button
						onClick={() => {
							if (canSubmitModalData()) {
								const requestPayload = {
									name: characterName,
									description: characterDescription,
									iconUrl: characterIconUrl,
									url: characterUrl,
									attributes: characterAttributes,
								}

								if (characterId) {
									patchCharacter(characterId, requestPayload).then((editedCharacter) => {
										if (editedCharacter) {
											dispatch(updateCharacter(editedCharacter))
										}
									})
								} else {
									createCharacter(requestPayload).then((newCharacter) => {
										if (newCharacter) {
											dispatch(setCharacter(newCharacter))
										}
									})
								}

								onCharacterModalClose()
							} else {
								toast.error('Please fill in all fields.')
							}
						}}
						color="primary">
						{characterId ? 'Save' : 'Create'}
					</Button>
					<Button onClick={onCharacterModalClose}>Cancel</Button>
				</ModalFooter>
			</Modal>
		</Container>
	)
}
const ToolDiv = styled.div`
	display: flex;
	justify-content: space-between;
	margin-bottom: 3px;
`
const AddButton = styled(FaPlus)`
	height: 10%;
	width: 10%;
`
const Searchbar = styled(Input)`
	width: 50%;
	height: auto;
`
const CharacterNameInput = styled(Input)`
	margin-bottom: 3px;
`
const CharacterDescriptionInput = styled(Input)`
	margin-bottom: 5px;
`
const AddCharacterButton = styled(Button)`
	width: 15%;
`
const Img = styled.img`
	width: 25%;
`
const Attribute = styled.div`
	border: 1px solid black;
	padding: 16px;
	border-radius: 4px;
	margin-bottom: 8px;
`
