import { intersection, union, keys, pick, cloneDeep } from 'lodash'
import type { Action, ActionMap, EventResult } from '@mission.io/mission-toolkit/actions'
import { JR_STATION_IDS, JR_PLUS_STATION_IDS } from '@mission.io/mission-toolkit/constants'
import type { AutomatedSimulation } from '../../../types/AutomatedSimulation'
import type { Phase } from '../PhaseContext'
import type { BranchEventResult, EventResultKey, EventType } from './eventResults'
import type { DurationMap, Action as ActionType, ScreensList } from '../PhaseCreator/types'
import { getSpecialEffect } from '@mission.io/mission-toolkit'
import { INITIAL_DURATION_MAP } from './constants'
import {
	DEFAULT_BRANCH_ID,
	EVENT_RESULT_DETERMINERS,
	EVENT_RESULT_DETERMINERS_WITH_BRANCHES,
	EVENT_RESULT_REMOVERS,
} from './eventResults'
import { forEachEntry, mapEntries, SECONDS_TO_MS } from '../../../helpers/functions'
import {
	TIME_LENGTH_FOR_UNKNOWN_SPECIAL_EFFECT,
	SCREEN_ACTION_TYPES,
	isScreenActionType,
	getActionTimeLength,
	ScreenActionId,
	ActionId,
	actionDefinitions,
} from '../actionDefinitions'
import { IdMap } from '../../../types/util'
import { DraggableType } from '../DragDrop/types'
import { getTitle } from '../actionDefinitions'

// ################################ SHARED HELPERS ################################

const POTENTIAL_SCREEN_ACTION_TYPES = [...SCREEN_ACTION_TYPES, 'IMAGE_OVERLAY', 'VIDEO_OVERLAY']

/**
 * Returns a list of all the direct action ids that can be created by the given action.
 * Does NOT use recursion.
 * @param {ActionId} actionId
 * @param {IdMap<Action>} actions
 */
export function getNextActionIdsFromAction(
	actionId: ActionId,
	actions: ActionMap<string>,
	determinerSubset?: string[]
): ActionId[] {
	const action = actions[actionId]
	if (!action) return []
	let resultDeterminers: Partial<typeof EVENT_RESULT_DETERMINERS> = EVENT_RESULT_DETERMINERS
	if (determinerSubset) resultDeterminers = pick(EVENT_RESULT_DETERMINERS, determinerSubset)
	const eventDeterminerKeys = intersection(
		keys(action),
		keys(resultDeterminers)
	) as Array<EventResultKey>
	return eventDeterminerKeys.reduce(
		(results: Array<ActionId>, key) => union(results, EVENT_RESULT_DETERMINERS[key](action) ?? []),
		[]
	)
}

/**
 * Returns ids of all actions that can be created directly by the given action, grouped by a branch id if the new action would be a new branch.
 * Does NOT use recursion.
 */
export function getNextActionIdsFromActionWithBranches(
	action: Action<string>
): Array<BranchEventResult> {
	const eventDeterminerKeys = intersection(
		keys(action),
		keys(EVENT_RESULT_DETERMINERS_WITH_BRANCHES)
	) as Array<EventResultKey>
	const allEventResultBranches: Record<string, EventResult<string>> = {}
	eventDeterminerKeys.forEach((key) => {
		const branches: Array<BranchEventResult> = EVENT_RESULT_DETERMINERS_WITH_BRANCHES[key](action)
		branches.forEach(({ branchId, eventResult }) => {
			if (allEventResultBranches[branchId]) {
				allEventResultBranches[branchId] = union(allEventResultBranches[branchId], eventResult)
			} else {
				allEventResultBranches[branchId] = eventResult
			}
		})
	})
	const result: Array<BranchEventResult> = []
	forEachEntry(allEventResultBranches, (eventResult, branchId) => {
		result.push({
			branchId,
			eventResult,
		})
	})
	return result
}
// ################################ SCREEN ACTIONS ALGORITHM ################################

/**
 * Given an automated simulation and a currentScreenActionId, returns that current screen's subtree in the shape of an ordered list of screenIds.
 * The list is composed of single screenIds and sets of screen ids that could potentially be reached
 * from a previous screen or set of screens in the list.
 *
 * The list may include duplicate screen ids but may not include duplicate sequences of screen ids. For example:
 * Screen 1 -> Screen 2 -> Screen 5 -> Screen 3
 *          -> Screen 3 -> Screen 4 -> Screen 6 -> Screen 7
 * Where Screen 3 is included in the list twice, but its nested screens (Screen 4 -> Screen 6 -> Screen 7) is only included once
 * @param {AutomatedSimulation} simulation
 * @param {PhaseId} currentPhaseId
 */
export function getScreenList(
	simulation: AutomatedSimulation,
	firstScreen: ScreenActionId | null | undefined,
	phaseOnly = true
): ScreensList {
	const screensList = []
	const screensSeen: Record<ScreenActionId, boolean> = {}
	if (!firstScreen) return []
	let nextScreenIds = [firstScreen]

	while (nextScreenIds.length > 0) {
		if (nextScreenIds.length === 1) {
			screensList.push(nextScreenIds[0])
		} else screensList.push(nextScreenIds)

		const newNextScreenIds = nextScreenIds.filter((screenId) => {
			const action = simulation.actions[screenId]
			// Don't get next screen ids of a new phase
			if (
				screenId !== firstScreen &&
				action &&
				'newPhase' in action &&
				action.newPhase &&
				phaseOnly
			) {
				screensSeen[screenId] = true
				return false
			}

			if (screensSeen[screenId]) return false
			else {
				// Mark a new screen as seen
				screensSeen[screenId] = true
				return true
			}
		})
		if (newNextScreenIds.length === 0) break
		// Avoids infinite loop: Only continues getting next screen ids for new screenIds we haven't seen
		nextScreenIds = getNextScreenIdsFromScreens(newNextScreenIds, simulation.actions)
	}

	// @ts-expect-error TS2322 SUPPRESS ERRORS FOR NEW OPTION noUncheckedIndexedAccess
	return screensList
}

/**
 * Given a group of screenIds and the map of all actions on a simulation,
 * returns a group of unique screen ids which can occur right after given screens.
 * @param { ScreenActionId[] } screenIds
 * @param { IdMap<Action> } actions
 */
function getNextScreenIdsFromScreens(
	screenIds: ScreenActionId[],
	actions: IdMap<Action<string>>
): ScreenActionId[] {
	return screenIds.reduce((nextScreenIds, screenId) => {
		return union(nextScreenIds, getNextScreenIdsFromAction(screenId, actions))
	}, [] as ScreenActionId[])
}

/**
 * Given an action, determines all the next possible screen action ids spawned from that action.
 * Makes use of recursion to determine possible screens actions nested within the action EventResults.
 * @param {ActionId} actionId
 * @param {IdMap<Action>} actions
 */
function getNextScreenIdsFromAction(
	actionId: ActionId,
	actions: IdMap<Action<string>>
): ScreenActionId[] {
	const allNextActionIds: ActionId[] = getNextActionIdsFromAction(actionId, actions)
	const allNextScreenIds = allNextActionIds.reduce((nextScreenIds, id) => {
		// @ts-expect-error TS2345 SUPPRESS ERRORS FOR NEW OPTION noUncheckedIndexedAccess
		if (SCREEN_ACTION_TYPES.includes(actions[id]?.type)) {
			return union(nextScreenIds, [id])
		} else {
			return union(nextScreenIds, getNextScreenIdsFromAction(id, actions))
		}
	}, [] as ScreenActionId[])
	return allNextScreenIds
}

// ################################ OTHER ACTIONS ALGORITHM ################################

/**
 * Given an automated simulation and a list of screens, returns an unordered list of ActionTypes where each object contains the
 * needed data in order to be draw in the PhaseEditor. I.e the action id, the screen where the action will occur, the duration of the action,
 * and the time which that action will occur relative to the screenId.
 * @param {AutomatedSimulation} simulation
 * @param {PhaseId} currentPhaseId
 */
export function getGuaranteedOtherActionsForScreens(
	simulation: AutomatedSimulation,
	screensList: ScreensList,
	durationMap: DurationMap
): ActionType[] {
	const allActionsLookup: Record<ActionId, ActionType> = {}
	screensList.forEach((screenIdOrIds) => {
		const screenIds = Array.isArray(screenIdOrIds) ? screenIdOrIds : [screenIdOrIds]
		screenIds.forEach((screenId) => {
			parseActions(
				[
					{
						id: screenId,
						startTime: 0,
					},
				],
				screenId,
				allActionsLookup,
				simulation.actions,
				durationMap
			)
		})
	})
	const allActions = Object.keys(allActionsLookup)
	// @ts-expect-error TS2322 SUPPRESS ERRORS FOR NEW OPTION noUncheckedIndexedAccess
	return (
		allActions
			// @ts-expect-error TS2345 SUPPRESS ERRORS FOR NEW OPTION noUncheckedIndexedAccess
			.filter((actionId) => !SCREEN_ACTION_TYPES.includes(simulation.actions[actionId]?.type))
			.map((id) => allActionsLookup[id])
	)
}

/**
 * Given a list of action ids, and the currentScreen of the action
 * Finds all connected ids to that action and adds them to a look up map where the key to the map is
 * the action Id and the value of the map is an ActionType (an object with the necessary information to be rendered in the Phase
 * Editor
 * @param {ScreenActionId} currentScreenId
 * @param {{ [ActionId]: true }} lookUp
 * @param {IdMap<Action>} actions
 */
export function parseActions(
	newIds: {
		id: ActionId
		startTime: number
	}[],
	currentScreenId: ScreenActionId,
	lookUp: Record<ActionId, ActionType>,
	actions: IdMap<Action<string>>,
	durationMap: DurationMap
): void {
	newIds.forEach(({ id: actionId, startTime }) => {
		let screenId = currentScreenId
		if (lookUp[actionId]) return
		const action = actions[actionId]
		if (!action) return
		lookUp[actionId] = {
			id: actionId,
			type: POTENTIAL_SCREEN_ACTION_TYPES.includes(action.type)
				? DraggableType.SCREEN_TYPE
				: DraggableType.ACTION_TYPE,
			title: getTitle(action),
			position: {
				screenId: currentScreenId,
				xTimeInScreen: startTime,
			},
			meta: {
				type: action.type,
				duration: getActionTimeLength(action, durationMap),
			},
		}

		if (SCREEN_ACTION_TYPES.includes(action?.type)) {
			screenId = actionId
			startTime = 0
		}

		const nextActions = getNextGuaranteedActionsWithStartTimes(
			actionId,
			startTime,
			actions,
			durationMap
		)
		parseActions(nextActions, screenId, lookUp, actions, durationMap)
	})
}

/**
 * Returns an array of actionIds and startTimes which will definitely occur from the given action.
 * Does NOT return actions that are not GUARANTEED to occur. I.E. actions that would occur from scanning certain objects,
 * completing stations, completing navigation map, or any other EVENT_RESULT_DETERMINER that is not onEnd, atTime, onStart
 * Does NOT use recursion.
 * @param {ActionId} actionId
 * @param {IdMap<Action>} actions
 */
function getNextGuaranteedActionsWithStartTimes(
	actionId: ActionId,
	startTime: number,
	actions: IdMap<Action<string>>,
	durationMap: DurationMap
): Array<{
	id: string
	startTime: number
}> {
	const action = actions[actionId]
	if (!action) return []
	const eventDeterminerKeys = intersection(
		keys(action),
		keys(EVENT_RESULT_DETERMINERS)
	) as Array<EventResultKey>
	return eventDeterminerKeys.reduce((allResultsWithTime, key) => {
		const resultsForKey = EVENT_RESULT_DETERMINERS[key](action)
		let resultsWithTime: Array<{
			id: string
			startTime: number
		}> = []

		if (key === 'onEnd') {
			if (!resultsForKey) {
				throw new Error(`\`resultsForKey\` should always have a value when \`key\` is "${key}"`)
			}
			resultsWithTime = resultsForKey.map((id: ActionId) => ({
				id,
				startTime: startTime + getActionTimeLength(action, durationMap),
			}))
		} else if (key === 'atTime' && 'atTime' in action) {
			resultsWithTime = action.atTime.reduce((results, atTimeResult) => {
				return [
					...results,
					...atTimeResult.actions.map((eventResult) => ({
						id: eventResult,
						startTime: startTime + atTimeResult.timestamp,
					})),
				]
			}, [] as Array<{ id: string; startTime: number }>)
		} else if (key === 'onStart') {
			if (!resultsForKey) {
				throw new Error(`\`resultsForKey\` should always have a value when \`key\` is "${key}"`)
			}
			resultsWithTime = resultsForKey.map((id: ActionId) => ({
				id,
				startTime,
			}))
		}

		// Only return actions that are measurable
		return union(allResultsWithTime, resultsWithTime)
	}, [] as Array<{ id: string; startTime: number }>)
}

/**
 * Finds the actionIds of all actions whose events trigger the given action.
 * @param {ActionId} actionId
 * @param {IdMap<Action>} actions
 */
export function getParentsOfActionId(
	actionId: ActionId,
	actions: IdMap<Action<string>>
): Array<{
	id: ActionId
	triggerForChild: EventResultKey
}> {
	const parents: Array<{ id: ActionId; triggerForChild: EventResultKey }> = []
	const allActionIds = keys(actions)

	for (let i = 0; i < allActionIds.length; i++) {
		const id = allActionIds[i]
		// @ts-expect-error TS2345 SUPPRESS ERRORS FOR NEW OPTION noUncheckedIndexedAccess
		const childrenOfAction = getNextEventResultsFromAction(id, actions)
		forEachEntry(childrenOfAction, (events, key) => {
			// One of the event results yields the provided action
			if (events.find((eventId) => eventId === actionId)) {
				parents.push({
					// @ts-expect-error TS2322 SUPPRESS ERRORS FOR NEW OPTION noUncheckedIndexedAccess
					id,
					triggerForChild: key,
				})
			}
		})
	}

	return parents
}

/**
 *  Some screens are referenced in multiple places. This function determines if the screenId is 'simply' referenced, meaning
 *  that if the screenId were to be removed from its parent, the screen action would still exist in the graph.
 *  1. Check to see if the screenId passed in is indeed a screen action (assume all other actions are only referenced once)
 *  2. Removes the reference from parentId to the given actionId and checks to see if the action still exists in the graph
		SIDE-NOTE: if the parent is 'MAP' then we can still check if the screen exists on the graph, if it does, then 
		we conclude that the map is referencing the action simply. 
 * @param {ActionId} actionId
 * @param {IdMap<Action>} actions
 * @param {ActionId} initialScreenActionId
 */
export function isScreenSimplyReferenced(
	screenId: ScreenActionId,
	parent:
		| 'MAP'
		| {
				id: ActionId
				eventKey: keyof typeof EVENT_RESULT_REMOVERS
		  },
	actions: IdMap<Action<string>>,
	initialScreenActionId: ActionId | null | undefined
): boolean {
	// @ts-expect-error TS2345 SUPPRESS ERRORS FOR NEW OPTION noUncheckedIndexedAccess
	if (!isScreenActionType(actions[screenId]?.type)) return false
	if (!initialScreenActionId) return false
	let actionsToTest = actions

	if (parent !== 'MAP') {
		const { eventKey, id: parentId } = parent
		const parentAction = cloneDeep(actions[parent.id])
		EVENT_RESULT_REMOVERS[eventKey](parentAction, screenId)
		// @ts-expect-error TS2322 SUPPRESS ERRORS FOR NEW OPTION noUncheckedIndexedAccess
		actionsToTest = { ...actions, [parentId]: parentAction }
	}

	return isActionInGraph(screenId, initialScreenActionId, actionsToTest)
}

/**
 * Determines if an action is reached within the action graph.
 * @param {ActionId} actionId
 * @param {ActionId} initialScreenActionId
 * @param {IdMap<Action>} actions
 */
function isActionInGraph(
	actionId: ActionId,
	currentId: ActionId,
	actions: IdMap<Action<string>>,
	reached: Record<string, boolean> = {}
): boolean {
	if (actionId === currentId) return true
	const children = getNextActionIdsFromAction(currentId, actions)
	return children
		.filter((id) => !reached[id]) // When the currentId is a leaf node, children is an empty list and this will return false
		.some((id) => {
			reached[currentId] = true
			return isActionInGraph(actionId, id, actions, reached)
		})
}

/**
 * Determines if the given event result on an action has a screen action included in it.
 * @param {Action} action
 * @param {EventType} event
 * @param {IdMap<Action>} actions
 * @param {{timestamp?: number, optionIndex?: number}} config
 */
export function actionEventResultHasScreenAction(
	action: Action<string>,
	event: EventType,
	actions: IdMap<Action<string>>,
	config?: {
		timestamp?: number
		optionIndex?: number
	}
): boolean {
	let actionIdsToCheck: ActionId[] = EVENT_RESULT_DETERMINERS[event](action) ?? []
	if (event === 'questionInfo' && 'questionInfo' in action && config?.optionIndex) {
		// @ts-expect-error TS2322 SUPPRESS ERRORS FOR NEW OPTION noUncheckedIndexedAccess
		actionIdsToCheck = action.questionInfo.options[config.optionIndex]?.result
	}

	// @ts-expect-error TS2345 SUPPRESS ERRORS FOR NEW OPTION noUncheckedIndexedAccess
	return actionIdsToCheck && actionIdsToCheck.some((id) => isScreenActionType(actions[id]?.type))
}

/**
 * Gets any option indices from a question info object on a parent action where the childId is part of the event result.
 * @param {Action} parent
 * @param {ActionId} childId
 */
export function getQuestionOptionIndicesTriggeringActionId(
	parent: Action<string>,
	childId: ActionId
): number[] {
	const indices: number[] = []

	if ('questionInfo' in parent) {
		parent.questionInfo.options.forEach((option, index) => {
			if (option.result.some((id) => id === childId)) {
				indices.push(index)
			}
		})
	}

	return indices
}

/**
 * Returns a map of all the direct event results that could occur from the given action:
 * The returned map is a key value pairing of types of triggers to their EventResults (array of actionIds)
 * Does NOT use recursion.
 * @param {ActionId} actionId
 * @param {IdMap<Action>} actions
 */
export function getNextEventResultsFromAction(
	actionId: ActionId,
	actions: IdMap<Action<string>>,
	determinerSubset?: string[]
): Record<EventResultKey, EventResult<string>> {
	const action = actions[actionId]
	if (!action) return {} as Record<EventResultKey, EventResult<string>>
	let resultDeterminers: Partial<typeof EVENT_RESULT_DETERMINERS> = EVENT_RESULT_DETERMINERS
	if (determinerSubset) resultDeterminers = pick(EVENT_RESULT_DETERMINERS, determinerSubset)
	const eventDeterminerKeys = intersection(
		keys(action),
		keys(resultDeterminers)
	) as Array<EventResultKey>
	return eventDeterminerKeys.reduce(
		(results, key) => ({
			...results,
			[key as EventResultKey]: EVENT_RESULT_DETERMINERS[key](action) ?? [],
		}),
		{} as Record<EventResultKey, EventResult<string>>
	)
}

// ################################ GET URL TO DURATION MAP ################################

/**
 * Returns a map of lengths in milliseconds for all media urls.
 * The map first organizes by field used to access media url: url, audioUrl, songUrl, or specialEffectId
 * and then by media url.
 * @param {IdMap<Action>} actions
 */
export function buildDurationMap(
	actions: IdMap<Action<string>>,
	original: DurationMap | null | undefined
): Promise<DurationMap> {
	const map: DurationMap = original || INITIAL_DURATION_MAP
	forEachEntry(actions, (action) => {
		actionDefinitions[action.type].initializeDurationMap?.(
			// @ts-expect-error We use `action.type` to get the function, so passing `action` is correct
			action,
			map
		)
	})
	let promises: Array<Promise<void>> = []
	const mapKeys = ['url', 'specialEffectId'] as const
	mapKeys.forEach((key) => {
		const subMap = map[key]
		promises = union(
			promises,
			mapEntries(subMap, (_, url) => {
				const fn = getDurationFunctionForKey(key)
				return fn(url)
					.then((duration) => {
						map[key][url] = Math.round(SECONDS_TO_MS(duration))
					})
					.catch(() => {
						console.error('Could not load duration for: ', url)
					})
			})
		)
	})
	return Promise.all(promises).then(() => {
		return map
	})
}
const FETCH_MEDIA_TIMEOUT = 10000

/**
 * Given a media action's accessor field, returns a function that takes media url or specialEffectId
 * as an argument, returning a promise containing the duration for that media in seconds.
 * @param {'url' | 'specialEffectId'} key
 */
function getDurationFunctionForKey(
	key: 'url' | 'specialEffectId'
): (arg0: string) => Promise<number> {
	const findDurationForUrl = (url: string): Promise<number> => {
		return new Promise((resolve, reject) => {
			const media = new Audio(url)

			media.onloadstart = () => {
				setTimeout(reject, FETCH_MEDIA_TIMEOUT) // Reject if timeout is reached
			}

			media.ondurationchange = () => {
				resolve(media.duration)
				media.src = '' // Cancel download after duration is found
			}
		})
	}

	if (key === 'specialEffectId') {
		return (specialEffectId): Promise<number> => {
			const filePath = getSpecialEffect(specialEffectId)?.audioSrc

			if (!filePath) {
				return Promise.resolve(TIME_LENGTH_FOR_UNKNOWN_SPECIAL_EFFECT / 1000)
			}

			return findDurationForUrl(filePath)
		}
	}

	return findDurationForUrl
}

// ################################ DERIVE PHASES FROM ACTIONS ################################

/**
 * Returns an ordered array of Phase types (containing ScreenActionId and display text) which represent the starts of new phases.
 * @param {ScreensList} screensList A list of screen ids and screen id arrays
 * @param {IdMap<Action>} actions All actions that are contained in `screenList` for reference
 */
function getOrderedPhases(orderedScreens: ScreensList, actions: IdMap<Action<string>>): Phase[] {
	const phases = new Set<string>()
	orderedScreens.forEach((screenIdOrIds: ScreenActionId[] | ScreenActionId) => {
		const screenIds = typeof screenIdOrIds === 'string' ? [screenIdOrIds] : screenIdOrIds
		screenIds.forEach((id) => {
			if (actions[id] && 'newPhase' in actions[id] && !phases.has(id)) {
				phases.add(id)
			}
		})
	})
	return Array.from(phases).map((screenId) => {
		const action = actions[screenId]
		return {
			screenId,
			display: (action && 'newPhase' in action && action.newPhase) || 'Unknown Phase Name',
		}
	})
}

/**
 * Gets high level information about the simulation, specifically the list of all phases and an estimate of the simulation length.
 * This function is useful for improving performance, as it allows only calling `getScreenList` once to get data derived from the screens list.
 * @param {?AutomatedSimulation} simulation The simulation
 * @param {?DurationMap} durationMap the durationMap for calculating sizes
 */
export function getHighLevelSimulationInfo(
	simulation: AutomatedSimulation | null | undefined,
	durationMap: DurationMap | null | undefined
): {
	phases: Array<Phase> | null | undefined
	estimatedSimulationLength: number
} {
	const initialActionId = simulation?.initialScreenActionId

	if (!initialActionId || !simulation) {
		return {
			phases: null,
			estimatedSimulationLength: 0,
		}
	}

	const orderedScreens: ScreensList = getScreenList(simulation, initialActionId, false)
	return {
		phases: getOrderedPhases(orderedScreens, simulation.actions),
		estimatedSimulationLength: getEstimatedLengthFromScreensList(
			orderedScreens,
			simulation.actions,
			durationMap
		),
	}
}

/**
 * Gets a rough estimate of the length of the provided `screensList`. It is not completely accurate as it only takes into account 1 screen from each
 * entry in `screensList`, and because some screens have timeLimits, but will not necessarily reach the full time to progress.
 */
function getEstimatedLengthFromScreensList(
	screensList: ScreensList,
	actions: IdMap<Action<string>>,
	durationMap: DurationMap | null | undefined
): number {
	let accumulatedLength = 0
	screensList.forEach((item: ScreenActionId | ScreenActionId[]) => {
		// @ts-expect-error TS2322 SUPPRESS ERRORS FOR NEW OPTION noUncheckedIndexedAccess
		const actionId: string = typeof item === 'string' ? item : item[0]
		const action = actions[actionId]
		// @ts-expect-error TS2345 SUPPRESS ERRORS FOR NEW OPTION noUncheckedIndexedAccess
		accumulatedLength += getActionTimeLength(action, durationMap || INITIAL_DURATION_MAP)
	})
	return accumulatedLength
}

export type FileRestriction = {
	mime: string
	allowedFileExtensions?: string[]
}

type FullFileRestriction = FileRestriction & { allowedFileExtensions: string[] }

const videoRestriction = {
	mime: 'video/*',
	allowedFileExtensions: ['mp4', 'm4v'],
}

const imageRestriction = {
	mime: 'image/*',
	allowedFileExtensions: ['jpeg', 'jpg', 'png', 'svg'],
}

const audioRestriction = {
	mime: 'audio/*',
	allowedFileExtensions: ['mp3', 'm4a', 'wav', 'webm'],
}

export const fileRestrictionsByType: {
	VIDEO: FullFileRestriction
	IMAGE: FullFileRestriction
	AUDIO: FullFileRestriction
	VIDEO_OR_IMAGE: FullFileRestriction
	VIDEO_OR_IMAGE_OR_AUDIO: FullFileRestriction
} = {
	VIDEO: videoRestriction,
	IMAGE: imageRestriction,
	AUDIO: audioRestriction,
	VIDEO_OR_IMAGE: {
		mime: videoRestriction.mime + ',' + imageRestriction.mime,
		allowedFileExtensions: [
			...videoRestriction.allowedFileExtensions,
			...imageRestriction.allowedFileExtensions,
		],
	},
	VIDEO_OR_IMAGE_OR_AUDIO: {
		mime: videoRestriction.mime + ',' + imageRestriction.mime + ',' + audioRestriction.mime,
		allowedFileExtensions: [
			...videoRestriction.allowedFileExtensions,
			...imageRestriction.allowedFileExtensions,
			...audioRestriction.allowedFileExtensions,
		],
	},
}

type MediaType = 'VIDEO' | 'IMAGE' | 'AUDIO'

/**
 * Gets the media type for a given `fileName`.
 * @param fileName The name of the file.
 * @return {MediaType | void}
 */
export function getMediaType(fileName: string): MediaType | void {
	const extension = fileName.split('.').pop()?.toLowerCase()
	if (!extension) {
		throw new Error(`Failed to get file extension for ${fileName}`)
	}
	if (videoRestriction.allowedFileExtensions.includes(extension)) {
		return 'VIDEO'
	} else if (imageRestriction.allowedFileExtensions.includes(extension)) {
		return 'IMAGE'
	} else if (audioRestriction.allowedFileExtensions.includes(extension)) {
		return 'AUDIO'
	}
}

/**
 * Given an action type and a field of that action, gets the file restrictions for the file
 * types expected in that field. E.g. IMAGE_SCREEN or IMAGE_OVERLAY both require only
 * image files, while SONG_CHANGE will require audio files.
 * @return {{ mime: string, allowedFileExtensions?: string[] }} A mime type, such as 'image/*'. Defaults to '*' if it can't find an
 *						appropriate type.
 */
export function getAppropriateFileRestrictions(actionType: string, field: string): FileRestriction {
	switch (actionType) {
		case 'IMAGE_OVERLAY':
		case 'IMAGE_SCREEN':
			return fileRestrictionsByType.IMAGE

		case 'VIDEO_SCREEN':
		case 'VIDEO_OVERLAY':
			return fileRestrictionsByType.VIDEO

		case 'VOCAL_TRACK':
			if (field === 'accessibilityVideoUrl') {
				return fileRestrictionsByType.VIDEO
			}

			return fileRestrictionsByType.AUDIO

		case 'SONG_CHANGE':
			return fileRestrictionsByType.AUDIO

		case 'CULMINATING_MOMENT_SCREEN': {
			return fileRestrictionsByType.IMAGE
		}

		default:
			throw new Error(`Failed to get file restrictions for ${actionType}, ${field}`)
	}
}

/**
 * All stations that can be upgraded
 */
const UPGRADABLE_STATIONS = new Set([
	JR_PLUS_STATION_IDS.DEFENSE_PLUS,
	JR_PLUS_STATION_IDS.TRACTOR_BEAM_PLUS,
	JR_PLUS_STATION_IDS.REPAIRS, // TODO: Remove these 2 JR stations as upgradable once we run the script to convert all 4+ DEFENSE and TRACTOR_BEAM stations to their PLUS counterparts
	JR_STATION_IDS.DEFENSE,
	JR_STATION_IDS.TRACTOR_BEAM,
])

/**
 * Tells whether an action represents an upgradable station
 * @param {Action} action The action to test
 * @return {boolean}
 */
function actionIsUpgradable(action: Action<string>) {
	return (
		action.type === 'NAVIGATION_SCREEN' ||
		(action.type === 'ACTIVATE_STATION' &&
			UPGRADABLE_STATIONS.has(
				// @ts-expect-error we want to pass a string here since we are validating the string
				action.stationData.stationId
			))
	)
}

/**
 * Tells whether an action supports having questions connected to it.
 * @param {Action} action The action to test
 * @return {boolean}
 */
function actionSupportsQuestions(action: Action<string>): boolean {
	return action.type === 'NAVIGATION_SCREEN' || action.type === 'ACTIVATE_STATION'
}

/**
 * Gets all actions in `actions` that are connected to `initialActionId` and support having questions connected to them. Does a breadth first search traversal of the actions
 * starting at `initialActionId` to get the resulting actions in order.
 * @param {?string} initialActionId The id of the initial action where the BFS traversal should start
 * @param {ActionMap} actions All available actions that might be connected to the graph
 * @return {Array<Action>} An ordered array of actions that support questions
 */
export function getActionsThatSupportQuestions(
	initialActionId: string | null | undefined,
	actions: ActionMap<string>
): Array<{
	action: Action<string>
	upgradable: boolean
}> {
	if (!initialActionId) {
		return []
	}

	const actionsThatSupportQuestions: Array<{ action: Action<string>; upgradable: boolean }> = []
	breadthFirstForEachAction(initialActionId, actions, (action) => {
		if (actionSupportsQuestions(action)) {
			actionsThatSupportQuestions.push({
				action,
				upgradable: actionIsUpgradable(action),
			})
		}
	})
	return actionsThatSupportQuestions
}

/**
 * A simple breadth first search algorithm that will go through all actions in `actions` that are connected to the action represented by `initialActionId`,
 * and calls `cb` on each action. Begins at `initialActionId`.
 * @param {ActionId} initialActionId The id of the action where the breadth first search algorithm should start
 * @param {ActionMap} actions All actions that should be used as part of the graph
 * @param {Function} cb A callback function that should be called for each action that is found
 */
export function breadthFirstForEachAction(
	initialActionId: ActionId,
	actions: ActionMap<string>,
	cb: (arg0: Action<string>) => unknown
) {
	const breadthFirstQueue = [initialActionId]
	const seenActions: Set<ActionId> = new Set()

	while (breadthFirstQueue.length > 0) {
		// We know that currentActionId is defined because we just checked that the queue length is greater than 0
		// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
		const currentActionId = breadthFirstQueue.shift()!

		if (seenActions.has(currentActionId)) {
			continue
		}

		seenActions.add(currentActionId)
		const currentAction = actions[currentActionId]

		if (!currentAction) {
			continue
		}

		cb(currentAction)
		const action = actions[currentActionId]

		if (!action) {
			return
		}

		const branches = getNextActionIdsFromActionWithBranches(action)

		for (const { eventResult } of branches) {
			for (const actionId of eventResult) {
				if (!seenActions.has(actionId)) {
					breadthFirstQueue.push(actionId)
				}
			}
		}
	}
}

/** ***************************************************************
 *********************** GRAPH TRAVERSAL *************************
 *****************************************************************/

/**
 * A standardized node in a graph of simulation actions. A more consumable replacement for Action.
 */
type GraphNode = {
	id: string
	// All edges for the node. If the node represents an action where there are multiple branches, each branch will be represented by a key in the edges object.
	edges: Record<string, Set<string>>
}
type Graph = {
	rootNodeId: string
	nodes: Record<string, GraphNode>
}

/**
 * Convert the simulation actions into a graph of standardized nodes, making it easier to traverse the graph of actions.
 * @param {Array<Action>} simulationActions The simulation actions to convert into a graph
 * @return {Graph} The simulation represented as a graph. There will always be a single root node represented by the initial screen action.
 */
export function convertSimulationToGraph(
	simulationActions: IdMap<Action<string>>,
	initialScreenActionId: ActionId
): Graph {
	const nodes: Record<string, GraphNode> = {}
	const rootNodeId = initialScreenActionId

	// Create a node for each action
	for (const actionId in simulationActions) {
		const action = simulationActions[actionId]

		if (!action) {
			continue
		}

		nodes[actionId] = {
			id: actionId,
			edges: {},
		}

		for (const branch of getNextActionIdsFromActionWithBranches(action)) {
			nodes[actionId].edges[branch.branchId] = new Set(branch.eventResult)
		}
	}

	return {
		rootNodeId,
		nodes,
	}
}

/**
 * A depth first search algorithm that will go through all nodes in `graph` and call `cb` on each node that is found.
 * @param {Graph} graph The graph to traverse
 * @param {Function} cb A callback function that should be called for each node that is found
 * @param recursionInfo Information required for recursion. Used internally.
 * @param recursionInfo.seenNodes A set of nodes that have already been seen. Used internally.
 * @param recursionInfo.depth The current depth of the recursion. Used internally.
 * @param recursionInfo.path The current path of the recursion. Only includes branches, not every node. Used internally.
 */
export function depthFirstForEachNode(
	graph: Graph,
	cb: (
		arg0: GraphNode,
		arg1: {
			depth: number
			path: Array<string>
		}
	) => unknown,
	recursionInfo: {
		seenNodes: Set<string>
		depth: number
		path: Array<string>
	} = {
		seenNodes: new Set(),
		depth: 0,
		path: ['root'],
	}
) {
	const { seenNodes, depth, path } = recursionInfo
	const node = graph.nodes[graph.rootNodeId]

	if (seenNodes.has(graph.rootNodeId) || !node) {
		return
	}

	seenNodes.add(graph.rootNodeId)
	cb(node, {
		depth,
		path,
	})

	for (const branchId in node.edges) {
		// @ts-expect-error TS2532 SUPPRESS ERRORS FOR NEW OPTION noUncheckedIndexedAccess
		for (const edge of node.edges[branchId]) {
			if (!seenNodes.has(edge)) {
				depthFirstForEachNode(
					{
						rootNodeId: edge,
						nodes: graph.nodes,
					},
					cb,
					{
						seenNodes,
						depth: depth + 1,
						path: branchId === DEFAULT_BRANCH_ID ? path : [...path, branchId],
					}
				)
			}
		}
	}
}

/**
 * Gets all culminating moment and collaborative culminating moment actions in the simulation, along with the id of the map that appears most recently before the culminating moment.
 * Runs a depth first search algorithm on the action graph to match culminating moments with maps. Collaborative culminating moments are not matched with maps.
 * @param {Array<Action>} simulationActions The simulation actions to get the culminating moments from
 * @param {string} initialScreenActionId The id of the initial screen action
 * @return {Array<{ culminatingMomentActionId: string, mapId: string }>} An array of culminating moments, along with the id of the map that appears most recently before the culminating moment.
 */
export function getCulminatingMomentsWithMaps(
	simulationActions: IdMap<Action<string>>,
	initialScreenActionId: ActionId
): Array<{
	culminatingMomentActionId: string
	mapId: string | null
}> {
	const culminatingMoments: Array<{
		culminatingMomentActionId: string
		path: string[]
		depth: number
		isCollaborativeCulminatingMoment: boolean
	}> = []
	const maps: Array<{
		mapId: string
		path: string[]
		depth: number
	}> = []
	const graph = convertSimulationToGraph(simulationActions, initialScreenActionId)
	depthFirstForEachNode(graph, (node, { depth, path }) => {
		// @ts-expect-error TS2322 SUPPRESS ERRORS FOR NEW OPTION noUncheckedIndexedAccess
		const action: Action<string> = simulationActions[node.id]

		if (action.type === 'CULMINATING_MOMENT_SCREEN') {
			culminatingMoments.push({
				culminatingMomentActionId: action._id,
				path: path,
				depth: depth,
				isCollaborativeCulminatingMoment: false,
			})
		} else if (action.type === 'COLLABORATIVE_CULMINATING_MOMENT_SCREEN') {
			culminatingMoments.push({
				culminatingMomentActionId: action._id,
				path: path,
				depth: depth,
				isCollaborativeCulminatingMoment: true,
			})
		} else if (action.type === 'MAP_CHANGE') {
			if (!action.mapId) {
				return
			}

			maps.push({
				mapId: action.mapId,
				path: path,
				depth: depth,
			})
		}
	})
	return culminatingMoments.map((culminatingMoment) => {
		if (culminatingMoment.isCollaborativeCulminatingMoment) {
			return {
				culminatingMomentActionId: culminatingMoment.culminatingMomentActionId,
				mapId: null,
			}
		}

		const mapsBeforeCulminatingMoment = maps
			.filter((map) => {
				const mapPath = map.path
				const mapDepth = map.depth

				// allow the map depth to be equal to the culminating moment depth
				if (mapDepth > culminatingMoment.depth) {
					return false
				}

				for (let i = 0; i < mapPath.length; i++) {
					if (mapPath[i] !== culminatingMoment.path[i]) {
						return false
					}
				}

				return true
			})
			.sort((a, b) => {
				return a.depth - b.depth
			})
		const mapId =
			mapsBeforeCulminatingMoment.length > 0
				? // @ts-expect-error TS2532 SUPPRESS ERRORS FOR NEW OPTION noUncheckedIndexedAccess
				  mapsBeforeCulminatingMoment[mapsBeforeCulminatingMoment.length - 1].mapId
				: null
		return {
			culminatingMomentActionId: culminatingMoment.culminatingMomentActionId,
			mapId,
		}
	})
}
