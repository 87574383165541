import React from 'react'
import { Button } from 'reactstrap'
import CardEffectCard from './CardEffectCard'
import type { CardEffect } from '../../../types'
import { FieldArrayFieldsProps } from 'redux-form'
type Props = {
	fields: FieldArrayFieldsProps<CardEffect>
	cardList: ReadonlyArray<{
		cardId: string
		cardName: string
	}>
}
export default function CardEffectHolder({ fields, cardList }: Props): JSX.Element {
	return (
		<div>
			{fields.map((_, i) => (
				<CardEffectCard
					cardList={cardList}
					key={i}
					onChange={(newOption: CardEffect) => {
						fields.splice(i, 1, newOption)
					}}
					delete={() => {
						fields.remove(i)
					}}
					card={fields.get(i)}
				/>
			))}
			{fields.length > 0 && <br />}
			<Button
				onClick={() =>
					fields.push({
						type: 'CARD_SWITCH',
						text: 'Click to Card Jump',
						// @ts-expect-error TS2532 SUPPRESS ERRORS FOR NEW OPTION noUncheckedIndexedAccess
						cardId: cardList.length > 0 ? cardList[0].cardId : 'None',
						outcome: 'NEUTRAL',
					})
				}
				color="primary">
				Add Card Effect
			</Button>
		</div>
	)
}
