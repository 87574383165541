import React, { useState } from 'react'
import { Nav, NavItem, NavLink, TabContent, TabPane } from 'reactstrap'
import classnames from 'classnames'
/**
 * A component for showing tabs. The parent only need to provide a list of tabs with `title` and `component`,
 * and this component will render a set of tabs `title` as the text on the tab, and `component` as the content for the tab.
 */

export default function SimulationTabs({
	tabs,
	activeTab: externalActiveTab,
	onChange,
}: {
	tabs: Array<{
		title: string
		component: JSX.Element
		hideWhenOffscreen?: boolean
	}>
	activeTab?: string
	onChange?: (arg0: string) => unknown
}): JSX.Element {
	// If you render SimulationTabs without any tabs, you're dumb
	// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
	const [localActiveTab, localSetActiveTab] = useState(tabs[0]!.title)
	const activeTab = externalActiveTab || localActiveTab
	const setActiveTab = onChange || localSetActiveTab
	return (
		<>
			<Nav tabs className="mb-2">
				{tabs.map((tab) => (
					<NavItem key={tab.title}>
						<NavLink
							className={classnames({
								active: activeTab === tab.title,
							})}
							onClick={() => setActiveTab(tab.title)}>
							{tab.title}
						</NavLink>
					</NavItem>
				))}
			</Nav>

			<TabContent activeTab={activeTab}>
				{tabs.map((tab) => (
					<TabPane tabId={tab.title} key={tab.title}>
						{tab.hideWhenOffscreen && activeTab !== tab.title ? null : tab.component}
					</TabPane>
				))}
			</TabContent>
		</>
	)
}
