import React, { ReactNode, useState } from 'react'
import { Prompt } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { getFormValues, getFormInitialValues, isDirty } from 'redux-form'
import {
	Button,
	Alert,
	UncontrolledDropdown,
	DropdownToggle,
	DropdownMenu,
	DropdownItem,
} from 'reactstrap'
import styled from 'styled-components'
import update from 'immer'
import { FaUndoAlt, FaRedoAlt } from 'react-icons/fa'
import { MdClose } from 'react-icons/md'
import { useBeforeunload } from 'react-beforeunload'
import { ThemeProvider } from 'styled-components'
import { toast } from '../../helpers/uiFunctions'
import AutomatedSimulationEditor from './AutomatedSimulationEditor'
import SimulationTabs from '../simulations/SimulationTabs'
import { AUTOMATED_REDUX_FORM_NAMES, MISSION_TYPES } from '../../helpers/constants'
import SimulationDetails from '../simulations/SimulationDetails'
import { useFullMapsFromSimulation, stringifyValidationResult } from './helpers/validationHelper'
import { undo, redo, useUndoState, useEditorSimulation } from '../../reducers/simulationEditor'
import EditorSimulationSaveContextProvider, {
	useHasMapOrderingChangedSinceLastSave,
	useSetLastSavedMapOrdering,
	useSaveContextUndoRedo,
	useSaveData,
	useLastSavedActions,
} from './EditorSimulationSaveContext'
import { QuestionsForm, useQuestionReducer } from '../questions'
import ValidationModal from './Validation/ValidationModal'
import { MissionGuideModal, generateAndUploadMissionGuidesWithToast } from './MissionGuide'
import { StationQuestions } from './StationQuestions'
import { theme } from '@mission.io/styles'
import type {
	AutomatedSimulation,
	StationQuestions as StationQuestionsType,
} from '../../types/AutomatedSimulation'
import type { FormConfig } from './FormContext'

import validation from '@mission.io/simulation-validation'
import type { ValidationResult } from '@mission.io/simulation-validation'
import { getTitle } from './actionDefinitions'
import { useUpdateAutomatedSimulation } from './queries'
import { ReduxTeams } from '../simulations/Teams'
import { isEqual } from 'lodash'
import { ReduxStore } from '../../types/ReduxStore'
import Tools from './ToolsTab'

const DETAILS_TAB = 'Details'
const EDITOR_TAB = 'Editor'
const QUESTIONS_TAB = 'Questions'
const TEAM_TAB = 'Teams'
const TOOLS_TAB = 'Tools'

/**
 * The "Automated Simulation" screen. Fetches the simulation if it's not available, and shows the
 * tabs available for automated simulations
 */

export default function AutomatedSimulationView({
	simulation,
}: {
	simulation: AutomatedSimulation
}): JSX.Element {
	const [activeTab, setActiveTab] = useState(EDITOR_TAB)
	const [validationResult, setValidationResult] = useState<ValidationResult | null | undefined>(
		null
	)
	const [selectedForm, setSelectForm] = useState<FormConfig | null | undefined>(null)
	const {
		submit: submitQuestions,
		isDirty: isQuestionFormDirty,
		...questionProps
	} = useQuestionReducer(simulation?.questions, simulation._id)
	return (
		<EditorSimulationSaveContextProvider actions={simulation.actions} mapIds={simulation.mapIds}>
			<div>
				<Header
					simulation={simulation}
					currentTab={activeTab}
					setValidationResult={(validationResult: ValidationResult) => {
						setValidationResult(validationResult)
						setSelectForm(null)
					}}
					submitQuestions={submitQuestions}
					questionIds={questionProps.questions.map((question) => question._id)}
					isQuestionFormDirty={isQuestionFormDirty}
					setSelectForm={setSelectForm}
				/>
				<SimulationTabs
					activeTab={activeTab}
					onChange={setActiveTab}
					tabs={[
						{
							title: DETAILS_TAB,
							component: (
								<div css="margin: 0px 122px">
									<SimulationDetails
										form={AUTOMATED_REDUX_FORM_NAMES.DETAILS}
										simulation={simulation}
										initialValues={{
											name: simulation.name,
											tag: simulation.tag,
											subjectMatter: simulation.subjectMatter,
											status: simulation.status,
											summary: simulation.summary,
											grades: simulation.grades,
											narration: simulation.narration,
											briefing: simulation.briefing,
											standards: simulation.standards,
											prerequisiteKnowledge: simulation.prerequisiteKnowledge,
											leaderboardStatus: simulation.leaderboardStatus,
											crossCurricularConnections: simulation.crossCurricularConnections,
											suggestedVocabulary: simulation.suggestedVocabulary,
											walkthroughVideoUrl: simulation.walkthroughVideoUrl,
											briefingVideoUrl: simulation.briefingVideoUrl,
											generatesAnalytics: simulation.generatesAnalytics,
											categories: simulation.categories,
											...(simulation.controlSet === 'K-3'
												? {}
												: {
														maxQuestions: simulation.maxQuestions,
												  }),
											missionGuide: simulation.missionGuide,
											science3D: simulation.science3D,
										}}
									/>
								</div>
							),
						},
						{
							title: EDITOR_TAB,
							component: <AutomatedSimulationEditor selectedForm={selectedForm} />,
						},
						{
							title: QUESTIONS_TAB,
							component: (
								<div className="px-4 text-cloud-blue bg-space-blue">
									{/* @ts-expect-error We want to pass a different theme for question-views */}
									<ThemeProvider theme={theme}>
										<QuestionsForm {...questionProps} id={simulation._id} />
										{simulation.controlSet === MISSION_TYPES.JUNIOR_PLUS && (
											<StationQuestions questions={questionProps.questions} />
										)}
									</ThemeProvider>
								</div>
							),
							hideWhenOffscreen: true,
						},
						...(simulation.controlSet === MISSION_TYPES.JUNIOR_PLUS
							? [
									{
										title: TEAM_TAB,
										component: <ReduxTeams />,
										hideWhenOffscreen: true,
									},
							  ]
							: []),
						{
							title: TOOLS_TAB,
							component: <Tools />,
							hideWhenOffscreen: true,
						},
					]}
				/>
				{validationResult && (
					<ValidationModal
						validationResult={validationResult}
						closeModal={() => setValidationResult(null)}
						showForm={(form: FormConfig) => {
							setValidationResult(null)
							setSelectForm(form)
						}}
					/>
				)}
			</div>
		</EditorSimulationSaveContextProvider>
	)
}

function Header({
	simulation,
	currentTab,
	setValidationResult,
	submitQuestions,
	isQuestionFormDirty,
	questionIds,
	setSelectForm,
}: {
	simulation: AutomatedSimulation
	currentTab: string
	setValidationResult: (validationResult: ValidationResult) => void
	submitQuestions: () => void
	isQuestionFormDirty: boolean
	questionIds: string[]
	setSelectForm: (formConfig: FormConfig) => unknown
}) {
	const [isSaving, setIsSaving] = useState(false)
	const [validationAlert, setValidationAlert] = useState<null | {
		error?: string
		trace?: string
		unreachable?: string
	}>(null)
	const formValues = useSelector<ReduxStore, AutomatedSimulation>(
		// @ts-expect-error We expect the DETAILS form to be a AutomatedSimulation
		getFormValues(AUTOMATED_REDUX_FORM_NAMES.DETAILS)
	)
	const initialFormValues = useSelector<ReduxStore, AutomatedSimulation>(
		// @ts-expect-error We expect the DETAILS form to be a AutomatedSimulation
		getFormInitialValues(AUTOMATED_REDUX_FORM_NAMES.DETAILS)
	)
	const formIsDirty = useSelector(isDirty(AUTOMATED_REDUX_FORM_NAMES.DETAILS))
	const editorSimulation = useEditorSimulation()
	const fullMaps = useFullMapsFromSimulation(editorSimulation)
	const setLastSavedMapOrdering = useSetLastSavedMapOrdering()
	const [isMissionGuideOpen, setIsMissionGuideOpen] = useState(false)
	const { needsSave, onSave } = useSaveData()
	const { lastSavedActions, setLastSavedActions } = useLastSavedActions()

	const { mutateAsync: updateSimulation } = useUpdateAutomatedSimulation()

	const hasMapOrderingChanged = useHasMapOrderingChangedSinceLastSave(editorSimulation?.mapIds)
	const simulationNeedsSave =
		formIsDirty ||
		needsSave ||
		hasMapOrderingChanged ||
		(editorSimulation?.stationQuestions &&
			stationQuestionsHasInvalidQuestions(editorSimulation?.stationQuestions, questionIds))
	const saveButtonEnabled = (isQuestionFormDirty || simulationNeedsSave) && !isSaving
	const blockNavigationMessage =
		'You have unsaved changes! Are you sure? Your unsaved changes will be lost.'
	// Blocks refreshing the page or pressing the back button when it will exit mission creator, i.e. when the page would "unload"
	useBeforeunload(() => (saveButtonEnabled ? blockNavigationMessage : null))

	const onValidate = () => {
		if (editorSimulation && editorSimulation.initialScreenActionId) {
			let result

			try {
				result = validation.validateSimulation(
					Object.values(editorSimulation.actions),
					editorSimulation.mapEventResults,
					fullMaps,
					editorSimulation.initialScreenActionId
				)
			} catch (err) {
				console.error(err)
				toast.error('Error occurred while validating simulation')
				return
			}

			setValidationResult(result)
			const { complexValidation } = result

			if (complexValidation) {
				if (complexValidation.ending.mustMakeToEnd) {
					toast.success('Simulation reaches the end!')
				}

				const resultStrings = stringifyValidationResult(complexValidation, editorSimulation)

				if (complexValidation.unreachable || complexValidation.error || complexValidation.trace) {
					setValidationAlert(resultStrings)
				}
			}
		}
	}

	/**
	 * Performs the necessary saves for the simulation, including saving the simulation and saving the questions
	 */
	function save(): Promise<void> {
		return new Promise((resolve) => {
			// The questions save completely separately from the rest of the simulation
			if (isQuestionFormDirty) {
				submitQuestions()

				if (!simulationNeedsSave) {
					resolve()
					return
				}
			}

			if (simulationNeedsSave) {
				let simulationData: Partial<AutomatedSimulation> = {}

				;(Object.keys(formValues) as Array<keyof AutomatedSimulation>).forEach((key) => {
					if (!isEqual(formValues[key], initialFormValues[key])) {
						// @ts-expect-error This is what we want
						simulationData[key] = formValues[key]
					}
				})

				if (editorSimulation) {
					simulationData = {
						...simulationData,
						mapIds: editorSimulation.mapIds,
						actions: editorSimulation.actions,
						initialScreenActionId: editorSimulation.initialScreenActionId,
						mapEventResults: editorSimulation.mapEventResults,
						stationQuestions:
							editorSimulation.stationQuestions &&
							cleanStationQuestions(editorSimulation.stationQuestions, questionIds),
						teams: editorSimulation.teams,
					}
				}

				updateSimulation(
					{ simulationId: simulation._id, simulationData, lastSavedActions },
					{
						onSuccess: ({ simulation: savedSimulation }) => {
							if (savedSimulation) {
								setLastSavedActions(savedSimulation.actions)
								setLastSavedMapOrdering(savedSimulation.mapIds)
								onSave()
							}
						},
						onError: (error) => {
							if (error instanceof Error) {
								console.error(error)
								toast.error(error.message || 'There was an unexpected error saving the simulation')
								return
							}

							if (error.general) {
								toast.error('Unable to save general simulation details', error.general)
							}

							if (error.actions) {
								const { actionId: failedActionId, message: failedActionMessage } =
									getFailedActionData(error.actions.failedAction)
								toast.error(
									<div css="display: flex; flex-direction: column; gap: var(--spacing);">
										<div>Unable to update simulation actions</div>
										{failedActionId && editorSimulation?.actions[failedActionId] && (
											<div>
												Error in{' '}
												<Button
													color="link"
													css="color: darkgray; padding: 0; &:hover { color: lightgray; }"
													onClick={(e) => {
														e.stopPropagation()
														setSelectForm({
															id: failedActionId,
															graph: false,
														})
													}}>
													{getTitle(editorSimulation.actions[failedActionId])}
												</Button>
											</div>
										)}
										<div>{failedActionMessage || error.actions?.message}</div>
									</div>
								)
							}
						},
					}
				).finally(resolve)
			}
		})
	}

	return (
		<StyledHeader>
			{/* Blocks navigating within Mission Creator */}
			<Prompt when={saveButtonEnabled} message={blockNavigationMessage} />
			{editorSimulation ? (
				<MissionGuideModal
					simulation={{ ...editorSimulation, ...formValues, mapIds: editorSimulation.mapIds }}
					onRequestClose={() => setIsMissionGuideOpen(false)}
					isOpen={isMissionGuideOpen}
				/>
			) : null}
			<h3 className="text-2xl font-bold mb-2 ml-2">{simulation.name}</h3>
			<div>
				{[EDITOR_TAB, QUESTIONS_TAB, TEAM_TAB].includes(currentTab) && <UndoButtons />}
				{editorSimulation ? (
					<Button onClick={() => setIsMissionGuideOpen(true)} color="secondary" size="sm">
						Mission Guide
					</Button>
				) : null}
				<UncontrolledDropdown group>
					<Button
						disabled={!saveButtonEnabled}
						onClick={async () => {
							setIsSaving(true)
							await save()
							setIsSaving(false)
						}}
						color="primary"
						size="sm">
						Save
					</Button>
					<DropdownToggle caret color="primary" size="sm" />
					<DropdownMenu>
						<DropdownItem
							onClick={async () => {
								setIsSaving(true)
								await Promise.all([
									save(),
									editorSimulation && generateAndUploadMissionGuidesWithToast(editorSimulation),
								])
								setIsSaving(false)
							}}>
							Save with Guides
						</DropdownItem>
					</DropdownMenu>
				</UncontrolledDropdown>

				{currentTab === EDITOR_TAB && (
					<Button color="secondary" size="sm" onClick={onValidate}>
						Validate
					</Button>
				)}
			</div>
			{validationAlert && (
				<FloatingAlert color="danger">
					<div css="position: relative;">
						<Cancel onClick={() => setValidationAlert(null)} />
						{validationAlert.error && <div>Error: {validationAlert.error}</div>}
						{validationAlert.trace && <div>{validationAlert.trace}</div>}
						{validationAlert.unreachable && (
							<div>Actions that are never reached: {validationAlert.unreachable}</div>
						)}
					</div>
				</FloatingAlert>
			)}
		</StyledHeader>
	)
}

/**
 * Get expected data about why an action failed to save. Could fail to get any of the expected data.
 * @returns {Object} An object with the id of the failed action at `actionId`, and a message to display to the user at `message`
 */
function getFailedActionData(failedAction: unknown): {
	actionId?: string | null | undefined
	message?: ReactNode
} {
	if (!failedAction || typeof failedAction !== 'object') {
		return {}
	}

	const actionId =
		'id' in failedAction && typeof failedAction.id === 'string' ? failedAction.id : null

	if (
		!('errors' in failedAction) ||
		!failedAction.errors ||
		typeof failedAction.errors !== 'object' ||
		Array.isArray(failedAction.errors)
	) {
		return {
			actionId,
		}
	}

	const errors = failedAction.errors as Record<string, unknown>

	if (Object.keys(errors).length === 0) {
		return {
			actionId,
		}
	}

	return {
		actionId,
		message: Object.keys(errors).map((errorKey, i) => {
			const message: unknown = errors[errorKey]

			if (typeof message === 'string') {
				return (
					<>
						<b>{errorKey}:</b> {message}
						<br />
					</>
				)
			}

			return null
		}),
	}
}

/**
 * Undo and redo buttons that hook up to the simulationEditor redux store
 */
function UndoButtons() {
	const dispatch = useDispatch()
	const { canUndo, canRedo } = useUndoState()
	const { undo: saveContextUndo, redo: saveContextRedo } = useSaveContextUndoRedo()
	return (
		<>
			<Button
				onClick={() => {
					saveContextUndo()
					dispatch(undo())
				}}
				disabled={!canUndo}
				size="sm">
				<FaUndoAlt />
			</Button>
			<Button
				onClick={() => {
					saveContextRedo()
					dispatch(redo())
				}}
				disabled={!canRedo}
				size="sm">
				<FaRedoAlt />
			</Button>
		</>
	)
}

const StyledHeader = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	position: sticky;
	top: 0;
	background-color: white;
	z-index: 4;
	padding-top: 8px;
	button {
		margin-left: 4px;
	}
`
const FloatingAlert = styled(Alert)`
	position: absolute !important;
	width: 80%;
	left: 50%;
	transform: translateX(-50%);
`
const Cancel = styled(MdClose)`
	position: absolute;
	cursor: pointer;
	right: 0;
`

/**
 * Tells whether `stationQuestions` has any invalid questions, meaning it has a question that does not exist in `questionIdsArray`.
 * @param {StationQuestionsType} stationQuestions The StationQuestions object to check
 * @param {string[]} questionIdsArray All question ids on the simulation
 * @return {boolean}
 */
function stationQuestionsHasInvalidQuestions(
	stationQuestions: StationQuestionsType | null | undefined,
	questionIdsArray: string[]
): boolean {
	if (!stationQuestions) {
		return false
	}

	const questionIds = new Set(questionIdsArray)
	return Object.keys(stationQuestions).some((actionId) =>
		// @ts-expect-error TS2532 SUPPRESS ERRORS FOR NEW OPTION noUncheckedIndexedAccess
		stationQuestions[actionId].questionIds.some((questionId) => !questionIds.has(questionId))
	)
}

/**
 * Pure function that removes all questions ids from `stationQuestions` that do not exist in `questionIdsArray`.
 * @param {StationQuestionsType} stationQuestions The StationQuestions object to be cleaned
 * @param {string[]} questionIdsArray All question ids on the simulation
 * @return {StationQuestionsType}
 */
function cleanStationQuestions(
	stationQuestions: StationQuestionsType,
	questionIdsArray: string[]
): StationQuestionsType {
	const questionIds = new Set(questionIdsArray)
	return update(stationQuestions, (draft: StationQuestionsType) => {
		Object.keys(draft).forEach((actionId) => {
			// @ts-expect-error TS2532 SUPPRESS ERRORS FOR NEW OPTION noUncheckedIndexedAccess
			draft[actionId].questionIds = draft[actionId].questionIds.filter((questionId) =>
				questionIds.has(questionId)
			)
		})
	})
}
