import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Field, FieldArray } from 'redux-form'
import { FormGroup, Label, FormText, Alert } from 'reactstrap'
import type { Card as CardType, NormalizedMap } from '../../types'
import { selectors } from '../../setup/tiles'
import TextList from '../../common/TextList'
import FormTextEditor from '../../common/FormTextEditor'
import './CardDetails.css'
import type { ReduxStore } from '../../types/ReduxStore'
const objectivesList = TextList('objectives')
type ReduxProps = {
	maps: NormalizedMap[]
}
type OwnProps = {
	prevMapId: string | null | undefined
	simulationId: string
	card: CardType
	cardList: {
		cardId: string
		cardName: string
	}[]
}
type Props = OwnProps & ReduxProps
type State = {
	newCard: boolean
}

class CardDetails extends Component<Props, State> {
	constructor(props: Props) {
		super(props)
		this.state = {
			newCard: false,
		}
	}

	componentDidUpdate(prevProps: Props, prevState: State) {
		if (prevProps.card._id !== this.props.card._id) {
			this.setState({
				newCard: true,
			})
		}

		if (prevProps.card._id === this.props.card._id && this.state.newCard) {
			this.setState({
				newCard: false,
			})
		}
	}

	render(): JSX.Element {
		const { maps, prevMapId } = this.props
		const { newCard } = this.state
		const prevMap = maps.find((map) => map._id === prevMapId)
		const prevMapName = prevMap ? prevMap.name : 'No Map :('
		return (
			<div>
				<h3>Launchpad Card Details</h3>

				<FormGroup>
					<Label>Instructions</Label>
					<FormText>
						Miscellaneous information the flight director should know for this part of the mission.
					</FormText>
					<Field
						name="directorInstructions"
						component={FormTextEditor}
						props={{
							reset: newCard,
						}}
					/>
				</FormGroup>

				<FormGroup>
					<Label>Objectives</Label>
					<FormText>
						Objectives students must accomplish that the flight director must watch for.
					</FormText>
					<FieldArray name="cardObjectives" component={objectivesList} />
				</FormGroup>

				<FormGroup>
					<Label>Summary</Label>
					<FormText>Brief description of what occurs in the story on this card.</FormText>
					<Field
						name="summary"
						component={FormTextEditor}
						props={{
							reset: newCard,
						}}
					/>
				</FormGroup>

				<h3>Warp Jump</h3>
				<FormGroup>
					<Label>Warp Jump Options</Label>
					<FormText>Flag if this card is right before or after a jump.</FormText>
					<div>
						<Field name="jumpEnd" id="jumpEnd" component="input" type="checkbox" />
						<span className="checkbox-title">
							<strong>End Warp Jump</strong> This is the card immediately after they exit their jump
							and arrive at a new location. Notes: 1) If this is selected, you should have started a
							jump on a previous card, and 2) If this is selected, you should also probably select a
							new map below.
						</span>
					</div>
					<div>
						<Field name="jumpPrep" id="jumpPrep" component="input" type="checkbox" />
						<span className="checkbox-title">
							<strong>Prep Warp Jump</strong>
							This card is right before a jump (ship must warp jump between this card and the next)
						</span>
					</div>
				</FormGroup>

				{this.props.card.jumpEnd && !this.props.card.mapId && (
					<Alert color="warn">
						If this is the end of a jump, please give this card a new map so the students land
						somewhere interesting!
					</Alert>
				)}

				<FormGroup>
					<Label for="mapId">Map</Label>
					<FormText>
						The map that defines the map for this card. If left blank, this card will use{' '}
						{`"${prevMapName}"`}
					</FormText>
					<Field name="mapId" component="select" className="form-control">
						<option />
						{maps.map((map) => (
							<option value={map._id} key={map._id}>
								{map.name}
							</option>
						))}
					</Field>
				</FormGroup>
			</div>
		)
	}
}

const mapStateToProps = (state: ReduxStore, ownProps: OwnProps) => {
	// @ts-expect-error TS2532 SUPPRESS ERRORS FOR NEW OPTION noUncheckedIndexedAccess
	const mapIds = selectors.simulations.store(state)[ownProps.simulationId].mapIds
	return {
		maps: mapIds.map((mapId) => selectors.maps.store(state)[mapId]),
	}
}

// @ts-expect-error TS2345 SUPPRESS ERRORS FOR NEW OPTION noUncheckedIndexedAccess
export default connect(mapStateToProps)(CardDetails)
