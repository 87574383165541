import React, { useMemo, useCallback, ReactNode } from 'react'
import { useDrag, useDrop } from 'react-dnd'
import { keyBy } from 'lodash'
import { useSelector, useDispatch } from 'react-redux'
import update from 'immer'
import styled from 'styled-components'
import { theme } from '@mission.io/styles'
import type { ClientQuestion } from '@mission.io/question-toolkit'
import { Question } from '@mission.io/question-views'
import { GrUpgrade } from 'react-icons/gr'
import {
	selectors,
	useEditorSimulation,
	setStationQuestions,
} from './../../reducers/simulationEditor'
import { getActionsThatSupportQuestions } from './helpers/algorithms'
import { prettifyTypeEnum } from './../../helpers/functions'
import { useSaveContextUpdate } from './EditorSimulationSaveContext'
import type { StationQuestions as StationQuestionsType } from '../../types/AutomatedSimulation'
import { Action, ActionMap } from '@mission.io/mission-toolkit/actions'

const DRAG_TYPES = {
	QUESTION: 'STATION_QUESTIONS__QUESTION',
}
const MAX_QUESTIONS_PER_ACTION = 3
type OnSetActionQuestion = (arg0: {
	actionId: string | null | undefined
	questionId: string
}) => unknown

/**
 * Gets a set of all question ids that are connected to any action in `questionActions`
 */
function getUsedQuestions(
	stationQuestions: StationQuestionsType,
	questionActions: Array<{
		action: Action<string>
		upgradable: boolean
	}>
): Set<string> {
	const usedQuestions = new Set<string>()

	if (!stationQuestions) {
		return usedQuestions
	}

	questionActions.forEach(({ action }) => {
		stationQuestions[action._id]?.questionIds.forEach((questionId) => usedQuestions.add(questionId))
	})
	return usedQuestions
}

/**
 * A react component that shows which questions are connected to which stations.
 */
export function StationQuestions({ questions }: { questions: ClientQuestion[] }): JSX.Element {
	const editorSimulation = useEditorSimulation()
	const stationQuestions: StationQuestionsType | null | undefined =
		editorSimulation?.stationQuestions
	const actions: ActionMap<string> | null | undefined = useSelector(selectors.getActions)
	const questionStationActions = useMemo(
		() => getActionsThatSupportQuestions(editorSimulation?.initialScreenActionId, actions || {}),
		[editorSimulation?.initialScreenActionId, actions]
	)
	const questionMap = keyBy(questions, '_id')
	const usedQuestions = getUsedQuestions(stationQuestions || {}, questionStationActions)
	const onUpdate = useSaveContextUpdate()
	const dispatch = useDispatch()
	const onSetActionQuestion: OnSetActionQuestion = useCallback(
		({
			actionId,
			questionId: questionIdToAdd,
		}: {
			actionId: string | null | undefined
			questionId: string
		}) => {
			if (!stationQuestions) {
				return
			}

			const newStationQuestions = update(stationQuestions, (draft: StationQuestionsType) => {
				Object.keys(draft).forEach((actionId) => {
					// @ts-expect-error TS2532 SUPPRESS ERRORS FOR NEW OPTION noUncheckedIndexedAccess
					draft[actionId].questionIds = draft[actionId].questionIds.filter(
						(questionId) => questionId !== questionIdToAdd
					)

					// @ts-expect-error TS2532 SUPPRESS ERRORS FOR NEW OPTION noUncheckedIndexedAccess
					if (draft[actionId].questionIds.length === 0) {
						delete draft[actionId]
					}
				})

				if (!actionId) {
					return
				}

				if (!draft[actionId]) {
					draft[actionId] = {
						questionIds: [],
					}
				}

				draft[actionId].questionIds.push(questionIdToAdd)
			})
			dispatch(setStationQuestions(newStationQuestions))
			onUpdate()
		},
		[dispatch, stationQuestions, onUpdate]
	)
	return (
		<div css="margin: var(--spacing2x) 0;">
			<h2>Station Questions</h2>
			<div
				css={`
					display: flex;
					gap: var(--spacing2x);
					align-items: stretch;
					> div {
						margin-top: var(--spacing);
					}
				`}>
				<div css="width: max(40vw, 300px)">
					{!stationQuestions ? (
						<StyledAction style={{ height: '150px' }}>
							Unable to find linked questions...
						</StyledAction>
					) : (
						questionStationActions.map(({ action, upgradable }) => {
							// @ts-expect-error TS2322 SUPPRESS ERRORS FOR NEW OPTION noUncheckedIndexedAccess
							const connectedQuestions: ClientQuestion[] | null | undefined = stationQuestions?.[
								action._id
							]?.questionIds
								.map((questionId) => questionMap[questionId])
								.filter(Boolean)
							return (
								<SingleActionQuestionsContainer
									action={action}
									upgradable={upgradable}
									connectedQuestions={connectedQuestions || []}
									onAddQuestion={onSetActionQuestion}
									key={action._id}
								/>
							)
						})
					)}
				</div>
				<QuestionBank
					questions={questions.filter((question) => !usedQuestions.has(question._id))}
					onSetActionQuestion={onSetActionQuestion}
				/>
			</div>
		</div>
	)
}

/**
 * A simple helper component to display a list of questions with a title. If the question list is empty, displays the passed `emptyText`
 * @param {string} props.title The title aka header to display
 * @param {ClientQuestion[]} props.questions The questions to display
 * @param {string} props.emptyText Text to display if `questions` is empty
 */
function QuestionGroup({
	title,
	questions,
	emptyText,
	...props
}: {
	title: ReactNode
	questions: ClientQuestion[]
	emptyText: string
}) {
	return (
		<div {...props} className="p-2">
			<h3>{title}</h3>
			{questions && questions.length
				? questions.map((question) => <DraggableQuestion key={question._id} question={question} />)
				: emptyText}
		</div>
	)
}

/**
 * A container for matching questions to a single action
 * @param {Action} props.action The action to which questions can be linked
 * @param {ClientQuestion[]} props.connectedQuestions All questions that are connected to this action
 */
function SingleActionQuestionsContainer({
	action,
	connectedQuestions,
	onAddQuestion,
	upgradable,
}: {
	action: Action<string>
	connectedQuestions: ClientQuestion[]
	onAddQuestion: OnSetActionQuestion
	upgradable: boolean
}) {
	const [{ isOver, canDrop }, drop] = useDrop(
		{
			accept: DRAG_TYPES.QUESTION,

			drop(item: { id: string }) {
				onAddQuestion({
					actionId: action._id,
					questionId: item.id,
				})
			},

			collect(monitor) {
				return {
					isOver: monitor.isOver(),
					canDrop: monitor.canDrop(),
				}
			},

			canDrop() {
				return connectedQuestions.length < MAX_QUESTIONS_PER_ACTION
			},
		},
		[action._id, onAddQuestion]
	)
	const style: {
		filter?: string
	} = {}

	if (isOver && canDrop) {
		style.filter = 'brightness(0.9)'
	}

	return (
		<StyledAction key={action._id} ref={drop} style={style}>
			<QuestionGroup
				title={
					<div css="display: flex; justify-content: space-between;">
						{prettifyTypeEnum(
							action.type === 'ACTIVATE_STATION' ? action.stationData.stationId : action.type
						)}
						{upgradable && <GrUpgrade title="Upgradable" />}
					</div>
				}
				questions={connectedQuestions}
				emptyText="No Linked Questions"
			/>
			{isOver && !canDrop && <i>This station already has {MAX_QUESTIONS_PER_ACTION} actions</i>}
		</StyledAction>
	)
}

/**
 * Displays all passed `questions` as a list of `DraggableQuestion`s
 */
function QuestionBank({
	questions,
	onSetActionQuestion,
}: {
	questions: ClientQuestion[]
	onSetActionQuestion: OnSetActionQuestion
}) {
	const [{ isOver }, drop] = useDrop(
		{
			accept: DRAG_TYPES.QUESTION,

			drop(item: { id: string }) {
				onSetActionQuestion({
					actionId: undefined,
					questionId: item.id,
				})
			},

			collect(monitor) {
				return {
					isOver: monitor.isOver(),
				}
			},
		},
		[onSetActionQuestion]
	)
	const style: Record<string, string> = {}

	if (isOver) {
		style.backgroundColor = theme.neutralLight
		style.filter = 'brightness(0.9)'
	}

	return (
		<div
			ref={drop}
			css={`
				flex: 1;
				transition: 200ms;
			`}
			style={style}>
			<QuestionGroup
				title="Unassigned Questions"
				questions={questions}
				emptyText={'No Unassigned Questions'}
			/>
		</div>
	)
}

/**
 * Displays a single question in it's "collapsible" form. The question is only draggable if it has been saved.
 */
function DraggableQuestion({ question }: { question: ClientQuestion }) {
	const isDraggable = !!question._id
	const [, drag] = useDrag(
		{
			type: DRAG_TYPES.QUESTION,
			item: {
				id: question._id,
			},

			canDrag() {
				return isDraggable
			},
		},
		[question._id, isDraggable]
	)
	return (
		<div ref={drag} css="* { box-sizing: content-box; }" className="mt-2">
			<Question isCollapsibleQuestion teacherQuestion question={question} />
			{!isDraggable && (
				<i css="margin: 0 var(--spacing); font-size: 0.8em">
					This question must be saved before being added to an action
				</i>
			)}
		</div>
	)
}

const StyledAction = styled.div`
	margin-bottom: var(--spacing2x);
	border-radius: 4px;
	transition: 200ms;
`
