import React, { useState, useEffect, useRef, useCallback } from 'react'
import {
	NavigationMap as NavigationMapViewer,
	applyPatches,
} from '@mission.io/navigation-map-client'
import { startNavigationMap, EVENTS, updateHelpers } from '@mission.io/navigation-map-server'
import KeyManager from './managers/KeyManager'
import styled from 'styled-components'
import { SERVER_UPDATE_TIME } from './constants'
import produce, { Patch, produceWithPatches } from 'immer'
import useInterval from 'use-interval'
import type {
	NavigationMap,
	Entity,
	InputData,
	ServerType,
	SerializedServerState,
	ServerSideClientState,
	Event,
} from '@mission.io/navigation-map-server'
import type { DebugConfig, ClientState } from '@mission.io/navigation-map-client'

type Props = {
	navigationMap: NavigationMap
	entities: Entity[]
}
const MOCK_NETWORK_MAX_LAG = 2000 // ms

const CHANGE_MOCK_NETWORK_LAG_AFTER = 5000 // ms

const MAX_SHIP_HEALTH = 100
enum FINISH_TYPES {
	SHIP_DEATH = 'SHIP_DEATH',
	OTHER_DEATH = 'OTHER_DEATH',
	SUCCESS = 'SUCCESS',
}
export default function Preview({ navigationMap, entities }: Props): JSX.Element {
	const [updateTime] = useState(SERVER_UPDATE_TIME)
	const server = useRef<ServerType | null | undefined>(null)
	const previousServerSideClientState = useRef<ServerSideClientState | null | undefined>(null)
	const previousClientState = useRef<ClientState | null | undefined>(null)
	const updateStats = useRef<{
		updateSize: number
		averageUpdateSize: number
		maxUpdateSize: number
		minUpdateSize: number
		totalUpdateSize: number
		updateCount: number
	}>({
		updateSize: 0,
		averageUpdateSize: 0,
		maxUpdateSize: -Infinity,
		minUpdateSize: Infinity,
		totalUpdateSize: 0,
		updateCount: 0,
	})
	const [clientState, setClientState] = useState<ClientState | null | undefined>(null)
	const [keyManager, setKeyManager] = useState<KeyManager | null | undefined>(null)
	const shipHealth = useRef(MAX_SHIP_HEALTH)
	const [finish, setFinish] = useState<keyof typeof FINISH_TYPES | null | undefined>(null)
	const options = useRef<DebugConfig>({
		keepCollision: false,
	})
	const updatesInNetwork = useRef<
		Array<{
			update: ClientState
			sentTime: number
		}>
	>([])
	const [isUsingMockNetwork, setIsUsingMockNetwork] = useState(false)
	const startNewServer = useCallback(() => {
		const newServer = startNavigationMap(navigationMap, entities, (events: Event[]) => {
			events.forEach((event) => {
				if (event.type === EVENTS.DAMAGE_SHIP) {
					shipHealth.current = Math.max(
						0,
						Math.min(MAX_SHIP_HEALTH, shipHealth.current - event.amount)
					)

					if (shipHealth.current <= 0) {
						setFinish(FINISH_TYPES.SHIP_DEATH)
					}

					return
				}

				if (event.type === EVENTS.OTHER_DEATH) {
					setFinish(FINISH_TYPES.OTHER_DEATH)
					return
				}

				if (event.type === EVENTS.SUCCESS) {
					setFinish(FINISH_TYPES.SUCCESS)
					return
				}
			})
		})
		server.current = newServer
		setFinish(null)
		shipHealth.current = MAX_SHIP_HEALTH
		return () => newServer.end()
	}, [navigationMap, entities, setFinish, shipHealth])
	useEffect(() => {
		const keyManager = new KeyManager()
		setKeyManager(keyManager)
		keyManager.startListening()
		return () => keyManager.stopListening()
	}, [])
	useEffect(() => {
		if (!navigationMap) {
			return
		}

		return startNewServer()
	}, [navigationMap, startNewServer])
	useEffect(() => {
		if (!isUsingMockNetwork) {
			if (updatesInNetwork.current.length) {
				// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
				setClientState(updatesInNetwork.current[updatesInNetwork.current.length - 1]!.update)
				updatesInNetwork.current = []
			}

			return
		}

		// mock network latency
		let timerId: NodeJS.Timeout | undefined
		let lagUpdateTimerId: NodeJS.Timeout | undefined
		let lagTime = 0

		const update = () => {
			const currentTime = Date.now()
			const resolveUpTo = currentTime - lagTime
			let nextState: ClientState | null | undefined = null

			while (updatesInNetwork.current.length) {
				// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
				if (updatesInNetwork.current[0]!.sentTime < resolveUpTo) {
					nextState = updatesInNetwork.current.shift()?.update
					continue
				}

				break
			}

			if (nextState != null) {
				setClientState(nextState)
			}

			timerId = setTimeout(() => {
				update()
			}, lagTime)
		}

		update()

		const updateLagTime = () => {
			lagTime = MOCK_NETWORK_MAX_LAG * Math.random()
			lagUpdateTimerId = setTimeout(() => {
				updateLagTime()
			}, CHANGE_MOCK_NETWORK_LAG_AFTER)
		}

		updateLagTime()
		return () => {
			clearTimeout(timerId)
			clearTimeout(lagUpdateTimerId)
		}
	}, [isUsingMockNetwork])
	useInterval(() => {
		if (!server.current) {
			return
		}

		const currentServer = server.current
		let inputs: InputData[] = []

		if (keyManager) {
			inputs = getInputs(keyManager)
		}

		const updateData: SerializedServerState = currentServer.update(updateTime, [
			...inputs,
			{
				controlKey: 'SHIP_HEALTH',
				value: {
					current: shipHealth.current,
					max: MAX_SHIP_HEALTH,
				},
			},
		])
		let patches: Patch[] | null | undefined = null
		// @ts-expect-error - Because ServerSideClient isn't typed correctly, ts is picking the wrong overload
		const [newServerSideClientState, immerPatches]: [ServerSideClientState, Patch[]] =
			produceWithPatches(
				previousServerSideClientState.current,
				(previous: ServerSideClientState | null | undefined) => {
					const result = updateHelpers.updatePreviousServerSideClientState(
						previous,
						updateData,
						options.current.keepCollision
					)

					if (Array.isArray(result)) {
						patches = result[1].value
						return result[0]
					}

					return result
				}
			)
		previousServerSideClientState.current = newServerSideClientState
		let newClientState: ClientState = updateHelpers.convertToClientState(newServerSideClientState)

		if (patches) {
			const patchesToApply = patches
			newClientState = produce(
				previousClientState.current,
				(previous: ClientState | null | undefined) => applyPatches(previous, patchesToApply)
			)
		} else {
			patches = immerPatches
		}

		previousClientState.current = newClientState
		let updateSize = JSON.stringify(patches || []).length

		if (patches === undefined) {
			// set action
			updateSize = JSON.stringify(newClientState).length
		}

		updateStats.current = {
			updateSize,
			averageUpdateSize: 0,
			maxUpdateSize: Math.max(updateStats.current.maxUpdateSize, updateSize),
			minUpdateSize: Math.min(updateStats.current.minUpdateSize, updateSize),
			totalUpdateSize: updateStats.current.totalUpdateSize + updateSize,
			updateCount: updateStats.current.updateCount + 1,
		}
		updateStats.current.averageUpdateSize =
			updateStats.current.totalUpdateSize / updateStats.current.updateCount
		newClientState = produce(newClientState, (draft: ClientState) => {
			draft.stats = { ...draft.stats, ...updateStats.current }
		})

		if (isUsingMockNetwork) {
			updatesInNetwork.current.push({
				update: newClientState,
				sentTime: Date.now(),
			})
		} else {
			setClientState(newClientState)
		}
	}, updateTime)
	useEffect(() => {
		if (finish && server.current) {
			server.current.end()
			server.current = null
		}
	}, [finish])

	if (!clientState) {
		return <h1>Loading... </h1>
	}

	if (finish) {
		return <StyledFinish>Finished With Event: {`"${finish}"`}</StyledFinish>
	}

	return (
		<NavigationMapWrapper>
			<label>
				<input
					type="checkbox"
					checked={isUsingMockNetwork}
					onChange={(event) => setIsUsingMockNetwork(event.target.checked)}
				/>
				Mock Unstable Laggy Network
			</label>
			<NavigationMapViewer
				debugging={true}
				onDebugConfigChange={(newDebugConfig: DebugConfig) => {
					options.current = newDebugConfig
				}}
				shouldReset={false}
				clientState={clientState}
				showDebugOptions={true}
				width={1920}
				height={1080}
			/>
		</NavigationMapWrapper>
	)
}
const StyledFinish = styled.h1`
	color: black;
`
const NavigationMapWrapper = styled.div`
	width: 100vw;
	height: 100vh;
`

function getInputs(keyManager: KeyManager): Array<InputData> {
	let x = 0
	let y = 0

	if (keyManager.isKeyPressed('w')) {
		y -= 1
	}

	if (keyManager.isKeyPressed('s')) {
		y += 1
	}

	if (keyManager.isKeyPressed('a')) {
		x -= 1
	}

	if (keyManager.isKeyPressed('d')) {
		x += 1
	}

	return [
		{
			controlKey: 'THRUSTERS',
			value: {
				angle: Math.atan2(y, x),
				magnitude: Math.sqrt(x ** 2 + y ** 2),
			},
		},
	]
}
