import React, { Component } from 'react'
import { Stage, Layer, Rect, Circle, Wedge, Star, Image as KonvaImage, Text } from 'react-konva'
import type { MapObjects } from '../../../../types'
import { connect } from 'react-redux'
import { centerCoordinates, unCenterCoordinates } from './helpers'
import OtherDefaultIcon from './otherDefaultIcon.svg'
import useImage from 'use-image'
import './MapImage.css'
import type { ReduxStore } from '../../../../types/ReduxStore'
// @ts-expect-error TS can't find the nested module
import { KonvaEventObject } from 'konva/types/Node'
const ONE_CHARACTER_LENGTH = 12
const SHORT_NAME_CUT_OFF = 10
const SHORT_LENGTH = 9
const LONG_LENGTH = 7
type Props = {
	objectIds: {
		celestialBodies: string[]
		ships: string[]
		other: string[]
		contacts: string[]
	}
	color: string | null | undefined
	backgroundImage: string | null | undefined
	onDrag: (key: string, _id: string, point: [number, number]) => void
	allowDrag?: boolean
	onObjectClick?: (objectId: string) => void
	showHidden?: boolean
}
type ReduxProps = {
	objects: MapObjects
}
type State = {
	image: HTMLImageElement | undefined
	tooltip:
		| {
				x: number
				y: number
				text: string
		  }
		| null
		| undefined
}

class MapImage extends Component<Props & ReduxProps, State> {
	gridColor = '#ddd'
	size = 300
	offset = 2

	constructor(props: Props & ReduxProps) {
		super(props)
		this.state = {
			image: undefined,
			tooltip: null,
		}
	}

	loadImage(backgroundImage: string | null | undefined) {
		if (!backgroundImage) {
			this.setState((state) => ({ ...state, image: undefined }))
		} else {
			const image = new window.Image()

			image.onload = () => {
				this.setState((state) => ({ ...state, image: image }))
			}

			image.src = backgroundImage
		}
	}

	componentDidMount() {
		this.loadImage(this.props.backgroundImage)
	}

	componentDidUpdate(prevProps: Props & ReduxProps) {
		if (prevProps.backgroundImage !== this.props.backgroundImage) {
			this.loadImage(this.props.backgroundImage)
		}
	}

	render(): JSX.Element {
		if (!this.props.objects) {
			return <div className="MapImage">... loading</div>
		}

		return (
			<div className="MapImage">
				<Stage width={this.size} height={this.size} className="map-stage">
					<Layer>
						{this.renderMap()}
						{this.renderCelestialBodies()}
						{this.renderOtherObjects()}
						{this.renderShips()}
						{this.renderContacts()}
					</Layer>
					{/* ToolTip Layer */}
					<Layer>
						<Rect
							x={this.state.tooltip?.x}
							y={this.state.tooltip?.y}
							width={this.getLength(this.state.tooltip?.text)}
							height={20}
							offsetX={25}
							offsetY={25}
							cornerRadius={2}
							visible={Boolean(this.state.tooltip)}
							fill="#808080"
							stroke="#808080"
						/>
						<Text
							x={this.state.tooltip?.x}
							y={this.state.tooltip?.y}
							offsetX={23}
							offsetY={20}
							opacity={1}
							visible={Boolean(this.state.tooltip)}
							fill="#FFFFFF"
							text={this.state.tooltip?.text}
						/>
					</Layer>
				</Stage>
			</div>
		)
	}

	renderMap() {
		const centerX = this.size / 2
		const centerY = this.size / 2
		const diameter = this.size - this.offset
		return [
			<Rect
				x={0}
				y={0}
				width={this.size}
				height={this.size}
				stroke={'black'}
				strokeWidth={1}
				key={'self'}
			/>,
			<KonvaImage
				key={'backgroundMapImage'}
				width={this.size}
				height={this.size}
				image={this.state.image}
			/>,
			<Circle
				x={centerX}
				y={centerY}
				radius={diameter / 2}
				stroke={'black'}
				fill={this.state.image ? '' : this.props.color || undefined}
				key={'main-circle'}
			/>,
		]
	}

	onClickObject = (object: { _id: string }) => {
		const mapFormDiv = document.getElementById(object._id)

		if (mapFormDiv) {
			mapFormDiv.scrollIntoView({
				behavior: 'smooth',
			})
		}

		if (this.props.onObjectClick) {
			this.props.onObjectClick(object._id)
		}
	}

	renderCelestialBodies(): Array<JSX.Element | null> {
		return this.props.objects.celestialBodies.map((celestialBody, index) => {
			const [x, y] = this.toViewCoordinates([celestialBody.location.x, celestialBody.location.y])
			const radius = celestialBody.type === 'PLANET' ? 100 : 4
			const fill = celestialBody.type === 'PLANET' ? 'blue' : 'brown'

			if (!celestialBody.hidden || this.props.showHidden) {
				return (
					<Circle
						x={x}
						y={y}
						radius={radius}
						fill={fill}
						draggable={this.props.allowDrag}
						key={celestialBody._id}
						onDragStart={(e) => this.removeTooltip()}
						onDragEnd={(e) => {
							const [newX, newY] = this.fromViewCoordinates([e.target.attrs.x, e.target.attrs.y])
							this.props.onDrag('celestialBodies', celestialBody._id, [newX, newY])
							this.setTooltip(e.target.attrs.x, e.target.attrs.y, celestialBody.name)
						}}
						onClick={() => this.onClickObject(celestialBody)}
						onMouseEnter={(e) => this.setTooltip(x, y, celestialBody.name)}
						onMouseLeave={(e) => this.removeTooltip()}
					/>
				)
			}

			return null
		})
	}

	renderShips(): Array<JSX.Element | null> {
		return this.props.objects.ships.map((ship) => {
			const [x, y] = this.toViewCoordinates([ship.location.x, ship.location.y])
			let fill

			if (ship.type === 'FRIENDLY') {
				fill = 'green'
			} else if (ship.type === 'FOE') {
				fill = 'red'
			} else {
				fill = 'gray'
			}

			if (!ship.hidden || this.props.showHidden) {
				return (
					<Ship
						x={x}
						y={y}
						fill={fill}
						key={ship._id}
						draggable={this.props.allowDrag}
						onDragStart={() => this.removeTooltip()}
						onDragEnd={([viewX, viewY]: [number, number]) => {
							const [newX, newY] = this.fromViewCoordinates([viewX, viewY])
							this.props.onDrag('ships', ship._id, [newX, newY])
							this.setTooltip(viewX, viewY, ship.name)
						}}
						onClick={() => this.onClickObject(ship)}
						onMouseEnter={() => this.setTooltip(x, y, ship.name)}
						onMouseLeave={() => this.removeTooltip()}
					/>
				)
			}

			return null
		})
	}

	renderOtherObjects(): Array<JSX.Element | null> {
		return this.props.objects.other.map((otherObject, index) => {
			const width = otherObject.icon ? otherObject.icon.width || 25 : 25
			const height = otherObject.icon ? otherObject.icon.height || 25 : 25
			const [x, y] = this.toViewCoordinates(
				[otherObject.location.x, otherObject.location.y],
				width,
				height
			)

			if (!otherObject.hidden || this.props.showHidden) {
				return (
					<OtherObject
						x={x}
						y={y}
						draggable={this.props.allowDrag ?? true}
						onDragStart={() => this.removeTooltip()}
						onDragEnd={(e: KonvaEventObject<DragEvent>) => {
							const [newX, newY] = this.fromViewCoordinates(
								[e.target.attrs.x, e.target.attrs.y],
								width,
								height
							)
							this.props.onDrag('other', otherObject._id, [newX, newY])
							this.setTooltip(e.target.attrs.x, e.target.attrs.y, otherObject.name)
						}}
						key={otherObject._id}
						width={width}
						height={height}
						imageUrl={otherObject.icon ? otherObject.icon.url : OtherDefaultIcon}
						onClick={() => this.onClickObject(otherObject)}
						onMouseEnter={() => this.setTooltip(x, y, otherObject.name)}
						onMouseLeave={() => this.removeTooltip()}
					/>
				)
			}

			return null
		})
	}

	renderContacts(): Array<JSX.Element | null> {
		return this.props.objects.contacts.map((contact, index) => {
			const [x, y] = this.toViewCoordinates([contact.location.x, contact.location.y])
			let fill

			if (contact.type === 'FRIENDLY') {
				fill = 'green'
			} else if (contact.type === 'FOE') {
				fill = 'red'
			} else {
				fill = 'gray'
			}

			if (!contact.hidden || this.props.showHidden) {
				return (
					<Star
						x={x}
						y={y}
						numPoints={4}
						innerRadius={3}
						outerRadius={5}
						fill={fill}
						draggable={this.props.allowDrag}
						onDragStart={(e) => this.removeTooltip()}
						onDragEnd={(e) => {
							const [newX, newY] = this.fromViewCoordinates([e.target.attrs.x, e.target.attrs.y])
							this.props.onDrag('contacts', contact._id, [newX, newY])
							this.setTooltip(e.target.attrs.x, e.target.attrs.y, contact.name)
						}}
						key={contact._id}
						onClick={() => this.onClickObject(contact)}
						onMouseEnter={(e) => this.setTooltip(x, y, contact.name)}
						onMouseLeave={(e) => this.removeTooltip()}
					/>
				)
			}

			return null
		})
	}

	toViewCoordinates([x, y]: [number, number], width = 0, height = 0): [number, number] {
		const [viewX, viewY] = [x + this.size / 2, -y + this.size / 2]
		return centerCoordinates([viewX, viewY], [width, height])
	}

	fromViewCoordinates([x, y]: [number, number], width = 0, height = 0): [number, number] {
		const [viewX, viewY] = unCenterCoordinates([x, y], [width, height])
		return [viewX - this.size / 2, -viewY + this.size / 2]
	}

	getLength(text: string | null | undefined): number {
		if (!text) return 0
		const stringLength = text.length
		const lengthMultiple =
			stringLength === 1
				? ONE_CHARACTER_LENGTH
				: stringLength < SHORT_NAME_CUT_OFF
				? SHORT_LENGTH
				: LONG_LENGTH
		return stringLength * lengthMultiple
	}

	setTooltip(x: number, y: number, name: string) {
		this.setState((prevState) => ({
			...prevState,
			tooltip: {
				x: x,
				y: y,
				text: name,
			},
		}))
	}

	removeTooltip() {
		this.setState((prevState) => ({ ...prevState, tooltip: null }))
	}
}

type ShipProps = {
	[key: string]: unknown
	x: number
	y: number
	fill: string
	draggable?: boolean
	onDragEnd?: (arg0: [number, number]) => void
}

const Ship = ({ x, y, fill, draggable = false, onDragEnd, ...rest }: ShipProps) => {
	const shipSize = 20
	const angle = 45
	const rotation = 90 - angle / 2
	return (
		<Wedge
			{...rest}
			x={x}
			y={y - shipSize / 2}
			radius={shipSize}
			angle={angle}
			rotation={rotation}
			fill={fill}
			draggable={draggable}
			onDragEnd={
				onDragEnd
					? (e) => {
							const { x: newX, y: newY } = e.target.attrs
							onDragEnd([newX, newY + shipSize / 2])
					  }
					: undefined
			}
		/>
	)
}

function mapStateToProps(state: ReduxStore, ownProps: Props) {
	const celestialBodies = ownProps.objectIds.celestialBodies
		.map((id) => state.mapObjects.celestialBodies[id])
		.filter((celestialBody) => !!celestialBody)
	const ships = ownProps.objectIds.ships
		.map((id) => state.mapObjects.ships[id])
		.filter((ship) => !!ship)
	const other = ownProps.objectIds.other
		.map((id) => state.mapObjects.other[id])
		.filter((object) => !!object)
	const contacts = ownProps.objectIds.contacts
		.map((id) => state.mapObjects.contacts[id])
		.filter((object) => !!object)
	return {
		objects: {
			celestialBodies,
			ships,
			other,
			contacts,
		},
	}
}

export default connect(mapStateToProps)(MapImage)
/**
 * Renders 'other' map objects. Takes an imageUrl as a prop which is used to get the
 * image. All other props are passed directly to the react-konva `Image` component
 */

function OtherObject({ imageUrl, ...props }: { [key: string]: unknown; imageUrl: string }) {
	const [image] = useImage(imageUrl)
	return <KonvaImage {...props} image={image} />
}
