import axios from 'axios'
import { createTile, createSyncTile } from 'redux-tiles'
import { change } from 'redux-form'
import update, { Spec } from 'immutability-helper'
import { keyBy } from 'lodash'
import type { FullMap, MapObjectType } from '../../types'
import type {
	CelestialBody,
	Contact,
	NormalizedMap,
	NormalizedObjects,
	OtherObject,
	Ship,
} from '../../types/MapTypes'
import { updateMapObjectStore, type State as MapObjectsStore } from '../../reducers/mapObjects'
import { config } from '../../config'
import { TileParams } from '../../types/tiles'

const URL = `${config.simulationsApiUrl}/api/maps`

const storeTile = createSyncTile({
	type: ['maps', 'store'],
	initialState: {},
	fns: {
		addMany: ({ params: maps, selectors, getState, dispatch }: TileParams<FullMap[]>) => {
			const currentMaps = selectors.maps.store(getState())
			let celestialUpdate = {}
			let shipUpdate = {}
			let otherUpdate = {}
			let contactUpdate = {}
			const normalizedMaps = maps.map((map) => {
				const results = getMapObjectUpdates(map)
				celestialUpdate = {
					...celestialUpdate,
					...('celestialBodies' in results && results.celestialBodies),
				}
				shipUpdate = { ...shipUpdate, ...('ships' in results && results.ships) }
				otherUpdate = { ...otherUpdate, ...('other' in results && results.other) }
				contactUpdate = { ...contactUpdate, ...('contacts' in results && results.contacts) }
				return getNormalizedMap(map)
			})
			dispatch(
				updateMapObjectStore({
					celestialBodies: celestialUpdate,
					ships: shipUpdate,
					other: otherUpdate,
					contacts: contactUpdate,
				})
			)
			return { ...currentMaps, ...keyBy(normalizedMaps, '_id') }
		},
		addMap: ({ params: map, selectors, getState, dispatch }: TileParams<FullMap>) => {
			dispatch(updateMapObjectStore(getMapObjectUpdates(map)))
			return update(selectors.maps.store(getState()), {
				[map._id]: {
					$set: getNormalizedMap(map),
				},
			})
		},
		updateMap: ({ params: map, selectors, getState }: TileParams<NormalizedMap>) => {
			const maps = selectors.maps.store(getState())
			return update(maps, {
				[map._id]: {
					$set: map,
				},
			})
		},
		deleteMap: ({ params: mapId, selectors, getState }) => {
			const maps = selectors.maps.store(getState())
			return update(maps, {
				$unset: [mapId],
			})
		},
	},
})
const copyMap = createTile({
	type: ['maps', 'copyMap'],
	fn: async ({
		params: { mapId, linkOptions },
	}: TileParams<{ mapId: string; linkOptions: string | null | undefined }>) => {
		const result = await axios.post(`${URL}/${mapId}/copy`, {
			linkOptions,
		})
		return result.data
	},
})
const addMap = createTile({
	type: ['maps', 'addMap'],
	fn: async ({ params: map }: { params: FullMap | null | undefined }) => {
		const result = await axios.post(`${URL}`, {
			map: map,
		})
		const { data: insertedMap } = result
		return insertedMap
	},
})
const deleteMap = createTile({
	type: ['maps', 'deleteMap'],
	fn: async ({ params: mapId, actions, dispatch }: TileParams<string>) => {
		await axios.delete(`${URL}/${mapId}`)
		dispatch(actions.maps.store.deleteMap(mapId))
	},
})
export type PatchParamsType = {
	id: string
	name?: string
	color?: string | null | undefined
	backgroundImage?: string | null | undefined
	objects?: NormalizedObjects
}
const patch = createTile({
	type: ['maps', 'patch'],
	fn: async ({ params }: { params: PatchParamsType }) => {
		const { id, ...updateFields } = params
		const result = await axios.patch(`${URL}/${id}`, updateFields)
		return result.data
	},
	nesting: ({ id }) => [id],
})
const addObject = createTile({
	type: ['maps', 'addObject'],
	fn: async ({
		params: { mapId, type, hidden },
		dispatch,
		actions,
	}: TileParams<{
		mapId: string
		type: MapObjectType
		hidden?: boolean
	}>): Promise<FullMap | undefined> => {
		try {
			const result = await axios.post(`${URL}/${mapId}/objects`, {
				type: type,
				hidden: hidden || false,
			})
			const updatedMap = result.data
			dispatch(updateMapObjectStore(getMapObjectUpdates(updatedMap)))
			dispatch(actions.maps.store.updateMap(getNormalizedMap(updatedMap)))
			dispatch(change(`maps.${updatedMap._id}`, 'objects', updatedMap.objects))
			return updatedMap
		} catch (e) {
			console.log(e)
		}
	},
	nesting: ({ mapId, type }) => [mapId, type],
})
const addPreexistingObject = createTile({
	type: ['maps', 'addPreexistingObject'],
	fn: async ({
		params,
		dispatch,
		actions,
	}: TileParams<{ mapId: string; type: string; objectId: string }>) => {
		try {
			const result = await axios.post(`${URL}/${params.mapId}/objects`, {
				type: params.type,
				objectId: params.objectId,
			})
			const updatedMap = result.data
			dispatch(updateMapObjectStore(getMapObjectUpdates(updatedMap)))
			dispatch(actions.maps.store.updateMap(getNormalizedMap(updatedMap)))
			dispatch(change(`maps.${updatedMap._id}`, 'objects', updatedMap.objects))
			return updatedMap
		} catch (e) {
			console.log(e)
		}
	},
})
const deleteObject = createTile({
	type: ['maps', 'deleteObject'],
	fn: async ({
		params: { mapId, objectId },
		dispatch,
		actions,
	}: TileParams<{ mapId: string; objectId: string }>) => {
		try {
			const result = await axios.delete(`${URL}/${mapId}/objects/${objectId}`)
			const updatedMap = result.data
			dispatch(updateMapObjectStore(getMapObjectUpdates(updatedMap)))
			dispatch(actions.maps.store.updateMap(getNormalizedMap(updatedMap)))
			dispatch(change(`maps.${updatedMap._id}`, 'objects', updatedMap.objects))
			return updatedMap
		} catch (e) {
			console.log(e)
		}
	},
	nesting: ({ mapId, objectId }) => [mapId, objectId],
})

/**
 * Given a map with all map objects in it, return a map with all map objects replaced with object ids.
 * @param  {FullMap} map A map with map objects nested under objects
 * @return {NormalizedMap}     An object with the same shape as the map argument but with object ids in place of objects
 */
function getNormalizedMap(map: FullMap): NormalizedMap {
	const { objects: mapObjects, ...restMap } = map
	return {
		...restMap,
		objects: {
			celestialBodies: mapObjects.celestialBodies.map((celestialBody) => celestialBody._id),
			ships: mapObjects.ships.map((ship) => ship._id),
			other: mapObjects.other.map((other) => other._id),
			contacts: mapObjects.contacts.map((char) => char._id),
		},
	}
}

/**
 * A kind of opposite to the function getNormalizedMap, this function takes a list of maps and
 * the map objects store, returning a list of the maps with map objects populated.
 * @param {NormalizedMap[]} maps
 * @param {MapObjectsStore} allMapObjects
 */
export function getFullMapsFromNormalizedMaps(
	maps: NormalizedMap[],
	allMapObjects: MapObjectsStore
): FullMap[] {
	return maps.map((map: NormalizedMap): FullMap => {
		const { objects: mapObjects, ...restMap } = map
		return {
			...restMap,
			objects: {
				celestialBodies: mapObjects.celestialBodies
					.map((id) => allMapObjects.celestialBodies[id])
					.filter((cb) => !!cb),
				ships: mapObjects.ships.map((id) => allMapObjects.ships[id]).filter((ship) => !!ship),
				other: mapObjects.other.map((id) => allMapObjects.other[id]).filter((other) => !!other),
				contacts: mapObjects.contacts
					.map((id) => allMapObjects.contacts[id])
					.filter((contact) => !!contact),
			},
		}
	})
}

/**
 * Given a map, builds an immutability-helper object that can be used to update the
 * mapObjects redux store from the map. The returned object will be able to completely
 * populate the mapObjects store based on the objects in the given map.
 * @param  {FullMap} map A map with all map objects nested on it
 * @return {Object}     The immutability helper object for updating the mapObjects store
 */
function getMapObjectUpdates(map: FullMap): Spec<MapObjectsStore> {
	const objectUpdates: {
		celestialBodies: Record<string, { $set: CelestialBody }>
		ships: Record<string, { $set: Ship }>
		other: Record<string, { $set: OtherObject }>
		contacts: Record<string, { $set: Contact }>
	} = {
		celestialBodies: {},
		ships: {},
		other: {},
		contacts: {},
	}
	map.objects.celestialBodies.forEach((celestialBody) => {
		objectUpdates.celestialBodies[celestialBody._id] = {
			$set: celestialBody,
		}
	})
	map.objects.ships.forEach((ship) => {
		objectUpdates.ships[ship._id] = {
			$set: ship,
		}
	})
	map.objects.other.forEach((other: OtherObject) => {
		objectUpdates.other[other._id] = {
			$set: other,
		}
	})
	map.objects.contacts.forEach((char) => {
		objectUpdates.contacts[char._id] = {
			$set: char,
		}
	})
	return objectUpdates
}

const tiles = [
	storeTile,
	addMap,
	copyMap,
	deleteMap,
	patch,
	addObject,
	deleteObject,
	addPreexistingObject,
]
export default tiles
