import React, { Component } from 'react'
import { connect } from 'react-redux'
import { debounce } from 'lodash'
import { selectors, actions } from '../../../../setup/tiles'
import Map from '../../Map'
import './style.css'
import type { NormalizedMap } from '../../../../types/MapTypes'
import type { PatchParamsType } from '../../tiles'
import type { ReduxStore } from '../../../../types/ReduxStore'
type Props = {
	mapIds: string[]
	deleteMap: (arg0: string) => void
}
type ReduxProps = {
	updateRemoteMap: (arg0: PatchParamsType) => void
	updateLocalMap: (arg0: NormalizedMap) => void
}
type MapStateToProps = {
	maps: NormalizedMap[]
}
/**
 * Displays a list of Maps
 * @param {Map[]} maps an array of Maps
 */

class MapsList extends Component<Props & MapStateToProps & ReduxProps> {
	render(): JSX.Element {
		return (
			<div className="MapsList">
				{this.props.maps.map((map: NormalizedMap) => (
					<Map
						key={map._id}
						map={map}
						deleteMap={() => this.props.deleteMap(map._id)}
						onUpdate={(newMap: NormalizedMap) => {
							this.props.updateLocalMap(newMap)
							this.updateRemoteMap({
								id: map._id,
								...newMap,
							})
						}}
					/>
				))}
			</div>
		)
	}

	updateRemoteMap: (arg0: PatchParamsType) => void = debounce(this.props.updateRemoteMap, 3000)
}

const mapStateToProps = (state: ReduxStore, { mapIds }: Props) => {
	const allMaps = selectors.maps.store(state)
	return {
		maps: mapIds.map((mapId) => allMaps[mapId]).filter((map) => !!map),
	}
}

const mapDispatchToProps = {
	updateRemoteMap: actions.maps.patch,
	updateLocalMap: actions.maps.store.updateMap,
}
export default connect(mapStateToProps, mapDispatchToProps)(MapsList)
