import React from 'react'
import { useDispatch } from 'react-redux'
import { FormGroup, FormText, Label } from 'reactstrap'
import { actions } from '../../setup/tiles'
import './SimulationImage.css'
import { useQueryClient } from 'react-query'
type Props = {
	simulationId: string
	imageUrl: string | null | undefined
	imageThumbnailUrl: string | null | undefined
}
export default function SimulationThumbnail({
	simulationId,
	imageThumbnailUrl,
}: Props): JSX.Element {
	const dispatch = useDispatch()
	const queryClient = useQueryClient()
	return (
		<div id="SimulationImage">
			<FormGroup>
				<Label for="thumbnail">Image</Label>
				<FormText>Image and thumbnail for this simulation.</FormText>

				<div className="mb-2">
					{imageThumbnailUrl && (
						<div>
							<div className="thumbnail-wrap">
								<span
									title="Delete image"
									onClick={() =>
										dispatch(
											actions.simulations.deleteImage({
												simulationId,
											})
										)
									}>
									&times;
								</span>
								<img src={imageThumbnailUrl} alt="Thumbnail" />
							</div>
						</div>
					)}
					{!imageThumbnailUrl && <i>No image uploaded</i>}
				</div>

				<input
					id="thumbnail"
					name="thumbnail"
					type="file"
					accept="image/*"
					className="form-control-file"
					onChange={(e) => {
						if (e.target.files && e.target.files.length > 0) {
							e.persist()
							dispatch(
								actions.simulations.putImage({
									simulationId: simulationId,
									// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
									image: e.target.files[0]!,
									queryClient,
								})
							).then(() => {
								e.target.value = ''
							})
						}
					}}
				/>
			</FormGroup>
		</div>
	)
}
