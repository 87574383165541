import React from 'react'
import type { OnSelect, WorldComponent } from '../types'
import styled from 'styled-components'
import { PRIMITIVE_TYPES, DEFAULT_COLOR } from '../constants'
import { useEntity } from '../hooks'
import {
	FaSquare,
	FaImage,
	FaCircle,
	FaUser,
	FaQuestion,
	FaCamera,
	FaArrowUp,
	FaArrowDown,
	FaTrash,
} from 'react-icons/fa'
type SwapPositions = (index1: string, index2: string) => void
type Props = {
	objects: WorldComponent[]
	onSelect: OnSelect
	selected: string | null | undefined
	swapPositions: SwapPositions
	onDelete: (_id: string) => void
}
const DISABLED_COLOR = 'grey'
const ENABLED_COLOR = 'black'
const TRASH_COLOR = 'darkred'
const TYPE_ICONS = {
	MEDIA: FaImage,
	ELLIPSE: FaCircle,
	RECTANGLE: FaSquare,
	ENTITY: FaUser,
	CAMERA: FaCamera,
}
export default function ObjectOverview({
	objects,
	onSelect,
	selected,
	swapPositions,
	onDelete,
}: Props): JSX.Element {
	return (
		<OverviewWrapper>
			{objects
				.map((object, index) => (
					<Overview
						object={object}
						key={object._id}
						onSelect={onSelect}
						selected={selected}
						swapPositions={swapPositions}
						swapUpWith={
							(objects[index + 1] &&
								// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
								objects[index + 1]!.type !== PRIMITIVE_TYPES.CAMERA &&
								// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
								objects[index + 1]!._id) ||
							null
						}
						swapDownWith={
							(object.type !== PRIMITIVE_TYPES.CAMERA &&
								objects[index - 1] &&
								// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
								objects[index - 1]!._id) ||
							null
						}
						onDelete={onDelete}
					/>
				))
				.reverse()}
		</OverviewWrapper>
	)
}

function Overview({
	object,
	onSelect,
	selected,
	swapPositions,
	swapUpWith,
	swapDownWith,
	onDelete,
}: {
	object: WorldComponent
	onSelect: OnSelect
	selected: string | null | undefined
	swapPositions: SwapPositions
	swapUpWith: string | null | undefined
	swapDownWith: string | null | undefined
	onDelete: (_id: string) => void
}) {
	const entity = useEntity(object.type === PRIMITIVE_TYPES.ENTITY ? object.entity : null)
	const ObjectType = TYPE_ICONS[object.type] || FaQuestion
	return (
		<StyledOverview onClick={() => onSelect(object._id)} $isSelected={selected === object._id}>
			<GeneralIcons>
				<ObjectType color={'color' in object ? object.color : DEFAULT_COLOR} />
				<FaArrowUp
					color={swapUpWith ? ENABLED_COLOR : DISABLED_COLOR}
					onClick={() => {
						if (swapUpWith) {
							swapPositions(object._id, swapUpWith)
						}
					}}
				/>
				<FaArrowDown
					color={swapDownWith ? ENABLED_COLOR : DISABLED_COLOR}
					onClick={() => {
						if (swapDownWith) {
							swapPositions(object._id, swapDownWith)
						}
					}}
				/>
			</GeneralIcons>
			<ObjectName>{object.name || entity?.name || 'Anonymous'}</ObjectName>
			{object.type !== PRIMITIVE_TYPES.CAMERA ? (
				<FaTrash
					color={TRASH_COLOR}
					onClick={() => {
						onDelete(object._id)
					}}
				/>
			) : null}
		</StyledOverview>
	)
}

const StyledOverview = styled.div<{ $isSelected: boolean }>`
	display: flex;
	width: 100%;
	justify-content: space-between;
	background-color: ${(props) => (props.$isSelected ? 'lightblue' : 'white')};

	> * {
		flex: 0 1 1;
	}

	&:hover {
		cursor: pointer;
		background-color: ${(props: { $isSelected: boolean }) =>
			props.$isSelected ? 'rgb(131, 179, 200)' : 'lightgrey'};
	}
`
const OverviewWrapper = styled.div`
	min-width: 15%;
	width: 15%;
	max-width: 15%;
`
const ObjectName = styled.div`
	word-wrap: break-word;
	min-width: 0;
	flex: 1 1 0;
	text-align: center;
`
const GeneralIcons = styled.div`
	display: flex;
`
