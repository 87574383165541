import React from 'react'
import Markdown from 'markdown-to-jsx'
import styled from 'styled-components'
import { MathComponent } from 'mathjax-react'
// components that markdown is allowed to display
const DEFAULT_ALLOWED_COMPONENTS = new Set([
	'pre',
	'div',
	'p',
	'code',
	'li',
	'ol',
	'ul',
	'img',
	'strong',
	'em',
	'span',
	'a',
	'del',
	'u',
	'h1',
	'h2',
	'h3',
	'h4',
	'h5',
	'h6',
])

/**
 * MathLanguage - support rendering the math language (MathJax)
 *
 * @param {Object} props - react props
 * @param {string} props.className - a class name for the rendered component
 * @param {string} props.children - the code string to parse and render
 * @param {string} props.isBlock - true if this should be rendered as a code block, false otherwise
 *
 * @return {React$Node}
 */
function MathLanguage({
	className,
	children,
	isBlock,
}: {
	className?: string | null | undefined
	children?: string | null | undefined
	isBlock?: boolean | undefined
}): JSX.Element | null {
	if (!children || typeof children !== 'string') {
		return null
	}

	return <MathComponent tex={children} display={isBlock} />
}

const LanguageRenderers = {
	math: MathLanguage,
	katex: MathLanguage,
	latex: MathLanguage,
}
const DEFAULT_OVERRIDES = {
	code: function Code({ className, children, ...rest }: { className?: string; children: string }) {
		let languageString = className
		let codeSnippet = children
		const isBlock = className?.startsWith('lang-') ?? false

		if (!languageString && typeof children === 'string' && children.startsWith('lang-')) {
			const matches = children.match(/^lang-[^\s]*\s/)

			if (matches?.length) {
				languageString = matches[0].slice(0, matches[0].length - 1)
				codeSnippet = children.slice(languageString.length + 1, children.length)
			}
		}

		const language = languageString?.replace('lang-', '')
		const Component =
			language && language in LanguageRenderers
				? LanguageRenderers[language as keyof typeof LanguageRenderers]
				: 'code'
		return (
			<Component
				className={`${className || ''} ${language || ''}`}
				{...(typeof Component !== 'string'
					? {
							isBlock,
					  }
					: {})}>
				{codeSnippet}
			</Component>
		)
	},
}

/**
 * NotAllowed - a component rendered in place of unallowed components
 */
function NotAllowed({ children }: { children: JSX.Element }) {
	return children || null
}

export type CustomComponentProps = { children: string; className?: string }
export type CustomComponent = (props: CustomComponentProps) => JSX.Element
export type CustomComponentsMap = {
	// note: the component will be rendered by markdown when it encounters an html tag with the `componentMarker` name (ie. <componentMarker />)
	[componentMarker: string]: CustomComponent
}

/**
 * MarkdownWithOverrides - display markdown with the default settings
 *
 * @param {Object} props - the react props
 * @param {string} props.children - the markdown to render
 * @param {?Array<string>} props.disabledComponents - the components to refuse to render
 * @param {?CustomComponentsMap} props.customComponents? - custom components which should be renderable by markdown
 */

export default function MarkdownWithOverrides({
	children,
	disabledComponents,
	customComponents,
}: {
	children: string
	disabledComponents?: string[]
	customComponents?: CustomComponentsMap
}): JSX.Element {
	const allowedComponents = new Set(DEFAULT_ALLOWED_COMPONENTS)
	const componentOverrides: {
		[elementName: string]: CustomComponent
	} = { ...DEFAULT_OVERRIDES }

	if (customComponents) {
		Object.keys(customComponents).forEach((elementName) => {
			allowedComponents.add(elementName)
			// @ts-expect-error TS2322 SUPPRESS ERRORS FOR NEW OPTION noUncheckedIndexedAccess
			componentOverrides[elementName] = customComponents[elementName]
		})
	}

	if (disabledComponents) {
		disabledComponents.forEach((option) => {
			allowedComponents.delete(option)
			delete componentOverrides[option]
		})
	}

	const overrides = new Proxy(
		{},
		{
			get: function (_, name, ...rest) {
				if (typeof name === 'string' && allowedComponents.has(name)) {
					return name in componentOverrides
						? componentOverrides[name as keyof typeof componentOverrides]
						: undefined
				}

				return {
					component: NotAllowed,
				}
			},
		}
	)
	return (
		<div className="prose max-w-none">
			<StyledMarkdown
				options={{
					disableParsingRawHTML: false,
					overrides,
				}}>
				{children}
			</StyledMarkdown>
		</div>
	)
}
const StyledMarkdown = styled(Markdown)`
	& code {
		color: blue;
	}

	& img {
		max-width: 100%;
	}
`
