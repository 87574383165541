import type { Simulation } from '../../../types/Simulation'
import type { NormalizedMap } from '../../../types/MapTypes'
import React, { ChangeEvent, Component, RefObject } from 'react'
import { sortBy } from 'lodash'
import { connect } from 'react-redux'
import classNames from 'classnames'
import { Spinner } from '../../../common/Spinner'
import MapImage from '../Map/MapImage/MapImage'
import { Modal, Button, InputGroup, Input, ModalHeader, ModalFooter, ModalBody } from 'reactstrap'
import { selectors, actions } from '../../../setup/tiles'
import FontAwesome from 'react-fontawesome'
import './mapCopy.css'
import type { ReduxStore } from '../../../types/ReduxStore'
type Props = {
	copyTo: string
	onClose: (...args: Array<unknown>) => unknown
	className?: string
	isOpen: boolean
}
type ReduxProps = {
	simulations: Simulation[]
	maps: Record<string, NormalizedMap>
	hasLoaded: boolean
	isLoading: boolean
	onLoadSimulations: typeof actions.simulations.getAll
	addMapIdToSimulation: typeof actions.simulations.addMap
	copyMap: typeof actions.maps.copyMap
	addMap: typeof actions.maps.store.addMap
	onLoadSimulation: typeof actions.simulations.get
}
type State = {
	mode: 'SIMULATION' | 'MAPS'
	maps: string[]
	filter: string
	nestedOpen: boolean
	selectedSimulationId: string | null | undefined
	selectedMapId: string | null | undefined
	toolTipStatus: Record<string, unknown>
}

class CopyModal extends Component<Props & ReduxProps, State> {
	searchRef: RefObject<HTMLInputElement>

	constructor(props: Props & ReduxProps) {
		super(props)
		this.state = {
			mode: 'SIMULATION',
			filter: '',
			maps: [],
			selectedSimulationId: null,
			nestedOpen: false,
			selectedMapId: null,
			toolTipStatus: {},
		}
		this.searchRef = React.createRef<HTMLInputElement>()
	}

	componentDidMount() {
		if (this.props.simulations.length === 1) this.getSimuations()
	}

	getSimuations = () => {
		const { hasLoaded, onLoadSimulations } = this.props
		if (!hasLoaded) onLoadSimulations()
	}
	onOpen = () => {
		this.getSimuations()
		this.setState({
			mode: 'SIMULATION',
		})

		if (this.searchRef.current) {
			this.searchRef.current.focus()
		}
	}
	toggleNestedModal = () => {
		this.setState({
			nestedOpen: !this.state.nestedOpen,
		})
	}
	handleFilterChange = (e: ChangeEvent<HTMLInputElement>) => {
		this.setState({
			filter: e.target.value,
		})
	}
	handleSimulationClick = (id: string) => {
		const simulation = this.props.simulations.find((sim) => sim._id === id)

		if (!simulation) {
			return
		}

		this.setState({
			mode: 'MAPS',
			filter: '',
			maps: simulation.mapIds,
			selectedSimulationId: id,
		})
	}
	copyMap = async (mapId: string | null | undefined, linkOptions?: string | null | undefined) => {
		const result = await this.props.copyMap({
			mapId,
			linkOptions,
		})

		if (result && result.data) {
			await this.props.addMapIdToSimulation({
				id: this.props.copyTo,
				mapId: result.data._id,
			})
			this.props.addMap(result.data, linkOptions)
		}

		await this.props.onLoadSimulation(this.props.copyTo)
	}
	onCopyPress = (mapId: string) => {
		this.setState({
			selectedMapId: mapId,
		})

		if (this.state.selectedSimulationId === this.props.copyTo) {
			this.toggleNestedModal()
		} else {
			this.copyMap(mapId)
			this.onClose()
		}
	}
	searchSimulations = () => {
		if (this.state.mode !== 'SIMULATION') {
			this.setState({
				filter: '',
				mode: 'SIMULATION',
				selectedSimulationId: null,
				selectedMapId: null,
			})
		}
	}
	onClose = () => {
		this.setState({
			filter: '',
			mode: 'SIMULATION',
			selectedSimulationId: null,
			selectedMapId: null,
			nestedOpen: false,
		})
		this.props.onClose()
	}
	handleToolTipToggle = (key: string) => {
		this.setState((state) => ({
			toolTipStatus: { ...state.toolTipStatus, [key]: !state.toolTipStatus[key] },
		}))
	}
	handleCopyOptionButtonClick = (option?: string) => {
		this.copyMap(this.state.selectedMapId, option)
		this.onClose()
	}

	render(): JSX.Element | null {
		const { isLoading } = this.props
		if (!this.props.isOpen) return null
		return (
			<Modal
				className="MapCopyModal"
				isOpen={this.props.isOpen}
				centered={true}
				size="lg"
				onOpened={this.onOpen}>
				<div className="input-container">
					<InputGroup className="search">
						<Input
							type="text"
							className="map-search"
							name="map-copy-input"
							value={this.state.filter}
							onChange={this.handleFilterChange}
							placeholder={this.state.mode === 'MAPS' ? "Search 'Maps'" : "Search 'Simulations'"}
							innerRef={this.searchRef}
						/>
					</InputGroup>
					<Button onClick={this.onClose} color="danger">
						X
					</Button>
				</div>
				<div
					className={classNames(
						'map-copy-results',
						this.state.mode === 'MAPS' ? 'maps' : 'simulations'
					)}>
					{isLoading ? <Spinner /> : this.renderResults()}
				</div>
				<Modal isOpen={this.state.nestedOpen} centered size="lg" className="copy-modal-options">
					<ModalHeader>Map Copy Options</ModalHeader>
					<ModalBody>
						<p>Would you like to make a Normal Copy or a Hard Copy?</p>
						<p>
							A <code>Normal Copy</code> will allow you to edit objects on the two maps separately,
							while modifying an object from a <code>Hard Copy</code> will modify the object on the
							original map and vice versa.
						</p>
					</ModalBody>
					<ModalFooter className="modal-button-group">
						<Button
							color="primary"
							onClick={() => {
								this.handleCopyOptionButtonClick('HARD_COPY')
							}}>
							Hard Copy
						</Button>
						<Button
							color="primary"
							onClick={() => {
								this.handleCopyOptionButtonClick()
							}}>
							Normal Copy
						</Button>
						<Button
							color="warning"
							onClick={() => {
								this.setState({
									nestedOpen: false,
									selectedMapId: null,
								})
							}}>
							Cancel
						</Button>
					</ModalFooter>
				</Modal>
			</Modal>
		)
	}

	renderResults(): JSX.Element | undefined {
		if (this.state.mode === 'MAPS') {
			if (this.state.maps.length > 0) {
				return (
					<div className="results map">
						<div className="results-back clickable" onClick={this.searchSimulations}>
							<FontAwesome name="arrow-left" />
							<span className="back-text">Back to Simulations</span>
						</div>
						<div className="contents">
							{this.state.maps
								.map((mapId) => this.props.maps[mapId])
								.filter(
									(map): map is NormalizedMap =>
										!!map?.name.toLowerCase().includes(this.state.filter.toLowerCase())
								)
								.map((map) => {
									return (
										<MapResultCard
											key={map._id}
											map={map}
											onclick={() => {
												this.onCopyPress(map._id)
											}}
										/>
									)
								})}
						</div>
					</div>
				)
			}

			return (
				<div className="no-results">
					<div className="results-back clickable" onClick={this.searchSimulations}>
						<FontAwesome name="arrow-left" />
						<span className="back-text">Back to Simulations</span>
					</div>
					<div className="contents">
						<h3>No maps in selected simulation</h3>
					</div>
				</div>
			)
		}

		if (this.props.simulations) {
			return (
				<div className="contents clickable">
					{this.props.simulations
						.filter((simulation) =>
							simulation.name.toLowerCase().includes(this.state.filter.toLowerCase())
						)
						.map((simulation) => {
							return (
								<SimulationResultCard
									key={simulation._id}
									title={simulation.name}
									onclick={() => this.handleSimulationClick(simulation._id)}
								/>
							)
						})}
				</div>
			)
		}
	}
}

function SimulationResultCard(props: {
	className?: string
	title: string
	onclick: (...args: Array<unknown>) => unknown
}) {
	return (
		<div
			className={classNames(props.className, 'SimulationResultCard', 'clickable')}
			onClick={props.onclick}>
			{props.title}
		</div>
	)
}

function MapResultCard(props: {
	map: NormalizedMap
	onclick: (...args: Array<unknown>) => unknown
	className?: string
}) {
	return (
		<div
			className={classNames(props.className, 'MapResultCard', 'clickable')}
			onClick={props.onclick}>
			<h3>{props.map.name}</h3>
			<MapImage
				objectIds={props.map.objects}
				color={props.map.color}
				backgroundImage={props.map.backgroundImage}
				onDrag={() => {
					// do nothing
				}}
			/>
		</div>
	)
}

function mapStateToProps(state: ReduxStore) {
	const apiData = selectors.simulations.getAll(state)
	let simulations = Object.values(selectors.simulations.store(state))
	simulations = sortBy(simulations, (s) => s.name)
	const allMaps = selectors.maps.store(state)
	return {
		maps: allMaps,
		hasLoaded: apiData.fetched,
		isLoading: apiData.isPending,
		simulations: simulations,
	}
}

const mapDispatchToProps = {
	onLoadSimulations: actions.simulations.getAll,
	addMapIdToSimulation: actions.simulations.addMap,
	copyMap: actions.maps.copyMap,
	addMap: actions.maps.store.addMap,
	onLoadSimulation: actions.simulations.get,
}
export default connect(mapStateToProps, mapDispatchToProps)(CopyModal)
