import React, { useMemo, useState } from 'react'
import { CondensedActionGraph, CondensedActionGraphNode } from '@mission.io/mission-toolkit'
import {
	DEFAULT_GRAPH_CONFIG,
	FAILURE_COLOR,
	GraphWrapper,
	END_PATH_BREAK_COLOR,
	SUCCESS_COLOR,
	SizedGraph,
	StyledNode,
	formatDuration,
	interpolateBetweenGreenAndLightBlue,
	isCondensedNodeInDeadEnd,
	DEAD_END_NODE_COLOR,
} from './graphHelpers'
import { useSetSelectedActions } from './Context'

type Link = {
	source: string
	target: string
	color?: string
}

/**
 * CondensedActionGraphRenderer - render the given condensedActionGraph
 *
 * @param {CondensedActionGraph<string>} condensedActionGraph - the graph to render
 *
 * @return {JSX.Element}
 */
export function CondensedActionGraphRenderer({
	condensedActionGraph,
}: {
	condensedActionGraph: CondensedActionGraph<string>
}): JSX.Element {
	const [shouldColorLinksToEnd, setShouldColorLinksToEnd] = useState(false)
	const [shouldColorLinksToDeath, setShouldColorLinksToDeath] = useState(false)
	const [shouldColorEndPathBreakSpecialRule, setShouldColorEndPathBreakSpecialRule] =
		useState(false)

	const setSelectedActions = useSetSelectedActions()

	const { graphConfig } = useMemo(() => {
		const maxDepth = condensedActionGraph.maxDepthFromStart || 1
		const actionViewGenerator = (node: CondensedActionGraphNode<string>): JSX.Element => {
			const { id, meta, duration } = node
			const proportionToMaxDepth = (meta.stepsFromStart ?? 0.5) / maxDepth
			const backgroundColor = isCondensedNodeInDeadEnd(node)
				? DEAD_END_NODE_COLOR
				: interpolateBetweenGreenAndLightBlue(proportionToMaxDepth)
			return (
				<StyledNode
					className="[&&]:border border-solid"
					style={{
						backgroundColor,
						borderColor: backgroundColor,
						borderTopColor: meta.containsEndAction ? SUCCESS_COLOR : backgroundColor,
						borderBottomColor: meta.containsDeathAction ? FAILURE_COLOR : backgroundColor,
					}}>
					<div>
						{id} - {meta.stepsFromStart ?? 'N/A'}
					</div>
					<div>Duration: {formatDuration(duration)}</div>
					<div>
						Till Death:{' '}
						{meta.pessimisticDurationUntilDeath
							? formatDuration(meta.pessimisticDurationUntilDeath.duration)
							: 'N/A'}
					</div>
					<div>
						Till End:{' '}
						{meta.pessimisticDurationUntilEnd
							? formatDuration(meta.pessimisticDurationUntilEnd.duration)
							: 'N/A'}
					</div>
				</StyledNode>
			)
		}
		const graphConfig = {
			...DEFAULT_GRAPH_CONFIG,
			node: {
				...DEFAULT_GRAPH_CONFIG.node,
				viewGenerator: actionViewGenerator,
			},
		}

		return { graphConfig }
	}, [condensedActionGraph])

	// color edges which lead to a node which contains END_MISSION action
	const graphToRender = useMemo(() => {
		const nodeLookup: Record<string, CondensedActionGraphNode<string>> = {}
		condensedActionGraph.nodes.forEach((node) => (nodeLookup[node.id] = node))
		let maxStepsToEnd = 1
		condensedActionGraph.nodes.forEach((node) => {
			if (node.meta.stepsFromEnd == null) {
				return
			}
			maxStepsToEnd = Math.max(maxStepsToEnd, node.meta.stepsFromEnd.stepCount)
		})

		const links: Link[] = []
		const coloredLinks: Link[] = []

		condensedActionGraph.links.forEach((link) => {
			const sourceNode = nodeLookup[link.source]
			const targetNode = nodeLookup[link.target]

			if (shouldColorEndPathBreakSpecialRule && link.specialRules?.breakOnEndPath) {
				coloredLinks.push({
					...link,
					color: END_PATH_BREAK_COLOR,
				})
				return
			}

			if (
				shouldColorLinksToDeath &&
				// @ts-expect-error TS18048 SUPPRESS ERRORS FOR NEW OPTION noUncheckedIndexedAccess
				link.target === sourceNode.meta.pessimisticDurationUntilDeath?.nextNodeId
			) {
				coloredLinks.push({
					...link,
					color: FAILURE_COLOR,
				})
				return
			}

			if (
				shouldColorLinksToEnd &&
				// @ts-expect-error TS18048 SUPPRESS ERRORS FOR NEW OPTION noUncheckedIndexedAccess
				link.target === sourceNode.meta.pessimisticDurationUntilEnd?.nextNodeId
			) {
				coloredLinks.push({
					...link,
					color: SUCCESS_COLOR,
				})
				return
			}

			// @ts-expect-error TS18048 SUPPRESS ERRORS FOR NEW OPTION noUncheckedIndexedAccess
			if (sourceNode.meta.stepsFromEnd != null && targetNode.meta.stepsFromEnd != null) {
				links.push({
					...link,
					color: interpolateBetweenGreenAndLightBlue(
						1 -
							Math.max(
								// @ts-expect-error TS18048 SUPPRESS ERRORS FOR NEW OPTION noUncheckedIndexedAccess
								sourceNode.meta.stepsFromEnd.stepCount,
								// @ts-expect-error TS18048 SUPPRESS ERRORS FOR NEW OPTION noUncheckedIndexedAccess
								targetNode.meta.stepsFromEnd.stepCount
							) /
								maxStepsToEnd
					),
				})
				return
			}
			links.push(link)
		})

		return {
			...condensedActionGraph,
			links: [...links, ...coloredLinks], // force colored links to appear on top of defaultly colored links
		}
	}, [
		condensedActionGraph,
		shouldColorLinksToDeath,
		shouldColorLinksToEnd,
		shouldColorEndPathBreakSpecialRule,
	])

	return (
		<>
			<div className="w-full flex flex-row gap-2">
				<label>
					<input
						type="checkbox"
						checked={shouldColorLinksToDeath}
						onChange={() => setShouldColorLinksToDeath(!shouldColorLinksToDeath)}
					/>
					Color Death Links
				</label>
				<label>
					<input
						type="checkbox"
						checked={shouldColorLinksToEnd}
						onChange={() => setShouldColorLinksToEnd(!shouldColorLinksToEnd)}
					/>
					Color End Links
				</label>
				<label>
					<input
						type="checkbox"
						checked={shouldColorEndPathBreakSpecialRule}
						onChange={() =>
							setShouldColorEndPathBreakSpecialRule(!shouldColorEndPathBreakSpecialRule)
						}
					/>
					Color End Path Breaks
				</label>
			</div>
			<GraphWrapper>
				<SizedGraph
					id="condensed-action-graph"
					data={graphToRender}
					config={graphConfig}
					onClickNode={(_, node) => {
						// We log the node on click so we can see all fields on the node
						console.log(node)
						setSelectedActions(new Set(node.actions.map(({ actionId }) => actionId)))
					}}
				/>
			</GraphWrapper>
		</>
	)
}
