import { useDebugValue } from 'react'
import undoable, { ActionCreators } from 'redux-undo'
import produce from 'immer'
import { keys, intersection, difference } from 'lodash'
import { useSelector } from 'react-redux'
import type {
	AutomatedSimulation,
	MapObjectResults,
	MapEventResult,
	StationQuestions,
	SimulationTeam,
	MapObjectResultType,
	ControlSet,
} from '../types/AutomatedSimulation'
import type { ClientQuestion as Question } from '@mission.io/question-toolkit'
import type {
	CreativeCanvasStationData,
	EventResult,
	ScreenAction,
	Action as SimulationAction,
	JrPlusActivateStationAction,
	ActionMap,
	ActivateStationAction,
	ActivateStationActionWithStationData,
} from '@mission.io/mission-toolkit/actions'
import {
	CREATIVE_CANVAS_STATION,
	JR_PLUS_STATION_IDS,
	SHARED_STATION_IDS,
} from '@mission.io/mission-toolkit/constants'
import type { ReduxStore } from '../types/ReduxStore'
import {
	ActionId,
	convertActionIfNecessary,
	ActionReference as SimulationActionReference,
	type ScreenActionId,
} from '../features/automatedSimulations/actionDefinitions'
import {
	getParentsOfActionId,
	getNextActionIdsFromAction,
	isScreenSimplyReferenced,
	actionEventResultHasScreenAction,
} from '../features/automatedSimulations/helpers/algorithms'
import { getTitle } from '../features/automatedSimulations/actionDefinitions'
import {
	handleJrPlusStationChange,
	EVENT_KEY_TO_STATION,
} from '../features/automatedSimulations/helpers/stations'
import {
	EVENT_RESULT_REMOVERS,
	EVENT_RESULT_ADDERS,
	EVENT_RESULT_DETERMINERS,
	EVENT_RESULT_ID_UPDATERS,
} from '../features/automatedSimulations/helpers/eventResults'
import { ReducerWarningError, ReducerFailedError } from '../helpers/errors'
import { forEachEntry, someEntry } from '../helpers/functions'
import type { EventType } from '../features/automatedSimulations/helpers/eventResults'
import type { TileParams } from '../types/tiles'
import { IdMap } from '../types/util'
import { selectors as tileSelectors } from '../setup/tiles'
import type { NormalizedMap } from '../types/MapTypes'

const DEFAULT_START_PHASE_NAME = 'Mission Start'
const SET_MAP_ORDERING = 'SET_MAP_ORDERING'
type SetMapOrderingAction = {
	type: 'SET_MAP_ORDERING'
	payload: {
		mapIds: string[]
	}
}
// ACTION CREATORS
export function undo(): ReturnType<typeof ActionCreators.undo> {
	return ActionCreators.undo()
}
export function redo(): ReturnType<typeof ActionCreators.redo> {
	return ActionCreators.redo()
}
const SET_EDITOR_SIMULATION = 'SET_EDITOR_SIMULATION'
type SetEditorSimulationAction = {
	type: 'SET_EDITOR_SIMULATION'
	payload: {
		simulation: AutomatedSimulation
		updateActions: boolean
	}
}

/**
 * The action creator for the actual SET_EDITOR_SIMULATION action
 */
function setEditorSimulation_(
	simulation: AutomatedSimulation,
	updateActions: boolean
): SetEditorSimulationAction {
	return {
		type: SET_EDITOR_SIMULATION,
		payload: {
			simulation,
			updateActions,
		},
	}
}

/**
 * Sets the current editor simulation to the passed simulation and clears the history
 */
export function setEditorSimulation(
	simulation: AutomatedSimulation,
	clearHistory: boolean,
	updateActions = true
): (arg0: TileParams<void>) => void {
	return function ({ dispatch, getState }: TileParams<void>) {
		dispatch(setEditorSimulation_(simulation, updateActions))

		if (clearHistory) {
			dispatch(ActionCreators.clearHistory())
		}
	}
}
type AddEventResultToActionArguments = {
	actionId: ActionId
	eventResultId: ActionId
	config: {
		event: EventType
		timestamp?: number
	}
}
type AddEventResultToActionAction = {
	type: 'ADD_EVENT_RESULT_TO_ACTION'
	payload: AddEventResultToActionArguments
}

/**
 * Adds an actionId to an eventResult of a given action. Does NOT create a new action OR move an action. 
 * This simply allows for referencing actions that already exist.
 * @param {{ActionId }} actionId  the id of the action we wish to add an event result to.
 * @param {{ActionId}} eventResultId the id of the action that will be added as part of an event result
 * @param {{
		event: EventType,
		timestamp?: number,
	}} config Configuration for adding an action to its parent, the event is the event type for which the action will be added (onStart, atTime) 
	along with any additional information that may be needed
 */
export function addEventResultToAction(
	payload: AddEventResultToActionArguments
): AddEventResultToActionAction {
	return {
		type: 'ADD_EVENT_RESULT_TO_ACTION',
		payload,
	}
}
type AddActionToSimulationConfigArguments = {
	to: ActionId | null | undefined
	event: EventType
	timestamp?: number
	optionIndex?: number
	shiftScreens?: boolean
	currentPhase?: string | null | undefined
}
type AddActionToSimulationAction = {
	type: 'ADD_ACTION'
	payload: {
		simAction: SimulationAction<string>
		config: AddActionToSimulationConfigArguments
	}
}

/**
 * Adds an action to the simulation.actions map and makes sure its attached to its parent action correctly.
 */
export function addActionToSimulation(
	action: SimulationAction<string>,
	config: AddActionToSimulationConfigArguments
): AddActionToSimulationAction {
	return {
		type: 'ADD_ACTION',
		payload: {
			simAction: action,
			config,
		},
	}
}
type MoveActionOnSimulationConfigArguments = {
	to: ActionId | null | undefined
	event: EventType
	timestamp?: number
	convertToScreen?: boolean
	shiftScreens?: boolean
	currentPhase?: string | null | undefined
}
type MoveActionOnSimulationAction = {
	type: 'MOVE_ACTION'
	payload: {
		id: string
		config: MoveActionOnSimulationConfigArguments
	}
}

/**
 * set the map ordering for the mission guide
 */
export function setMapOrdering(mapIds: string[]): SetMapOrderingAction {
	return {
		type: SET_MAP_ORDERING,
		payload: {
			mapIds,
		},
	}
}

/**
 * Moves an action on a simulation by removing the action from any of its parents and attaching it to a new parent
 */
export function moveActionOnSimulation(
	actionId: ActionId,
	config: MoveActionOnSimulationConfigArguments
): MoveActionOnSimulationAction {
	return {
		type: 'MOVE_ACTION',
		payload: {
			id: actionId,
			config,
		},
	}
}
type UpdateActionAndAddActionsAction = {
	type: 'UPDATE_ACTION_AND_ADD_ACTIONS'
	payload: {
		simulationAction: SimulationAction<string>
		actions: (SimulationAction<string> | SimulationActionReference)[]
	}
}
export function updateActionAndAddActions(
	action: SimulationAction<string>,
	actions: (SimulationAction<string> | SimulationActionReference)[]
): UpdateActionAndAddActionsAction {
	return {
		type: 'UPDATE_ACTION_AND_ADD_ACTIONS',
		payload: {
			simulationAction: action,
			actions,
		},
	}
}
type DeleteMapAction = {
	type: 'DELETE_MAP'
	payload: {
		mapId: string
	}
}

/**
 * Deletes a mapId from the simulation.
 */
export function deleteMap(mapId: string): DeleteMapAction {
	return {
		type: 'DELETE_MAP',
		payload: {
			mapId: mapId,
		},
	}
}
type DeleteObjectAction = {
	type: 'DELETE_OBJECT'
	payload: {
		objectId: string
	}
}

/**
 * Deletes an object from a simulation
 */
export function deleteObject(objectId: string): DeleteObjectAction {
	return {
		type: 'DELETE_OBJECT',
		payload: {
			objectId: objectId,
		},
	}
}
type UpdateMapObjectResultsAndAddActionsAction = {
	type: 'UPDATE_MAP_OBJECT_RESULTS_AND_ADD_ACTIONS'
	payload: {
		mapId: string
		objectId: string
		mapObjectResults: MapObjectResults
		actions: (SimulationAction<string> | SimulationActionReference)[]
	}
}

/**
 * Updates the map object results for a given mapId and objectId.
 */
export function updateMapObjectResultsAndAddActions(
	mapId: string,
	objectId: string,
	mapObjectResults: MapObjectResults,
	actions: (SimulationAction<string> | SimulationActionReference)[]
): UpdateMapObjectResultsAndAddActionsAction {
	return {
		type: 'UPDATE_MAP_OBJECT_RESULTS_AND_ADD_ACTIONS',
		payload: {
			mapId,
			objectId,
			mapObjectResults,
			actions,
		},
	}
}
type DeleteActionOnSimulationAction = {
	type: 'DELETE_ACTION'
	payload: {
		id: ActionId
	}
}

/*
 * Deletes an action from the simulation, and all of its children that are now never reached
 * @param {ActionId} actionId
 */
export function deleteActionOnSimulation(actionId: ActionId): DeleteActionOnSimulationAction {
	return {
		type: 'DELETE_ACTION',
		payload: {
			id: actionId,
		},
	}
}
// Action that can directly set any property in the state
type SetKeyAction<K extends keyof AutomatedSimulation> = {
	type: 'EDITOR_SIMULATION__SET_KEY'
	payload: {
		key: K
		value: AutomatedSimulation[K]
	}
}

/**
 * Set the `stationQuestions` property directly on the editorSimulation
 */
export function setStationQuestions(value: StationQuestions): SetKeyAction<'stationQuestions'> {
	return {
		type: 'EDITOR_SIMULATION__SET_KEY',
		payload: {
			key: 'stationQuestions',
			value: value,
		},
	}
}

/**
 * Set the `teams` property directly on the editorSimulation
 */
export function setTeams(value: SimulationTeam[]): SetKeyAction<'teams'> {
	return {
		type: 'EDITOR_SIMULATION__SET_KEY',
		payload: {
			key: 'teams',
			value: value,
		},
	}
}
// TYPES
// A single version of the state in the undoable history
type BaseState = AutomatedSimulation | null | undefined
// The full undoable state as visible in redux
export type State = {
	past: BaseState[]
	present: BaseState
	future: BaseState[]
}
export type Action =
	| SetEditorSimulationAction
	| AddActionToSimulationAction
	| MoveActionOnSimulationAction
	| DeleteMapAction
	| DeleteObjectAction
	| UpdateMapObjectResultsAndAddActionsAction
	| UpdateActionAndAddActionsAction
	| DeleteActionOnSimulationAction
	| AddEventResultToActionAction
	| SetMapOrderingAction
	| SetKeyAction<keyof AutomatedSimulation>
export function simulationEditorReducer(state: BaseState = null, action: Action): BaseState {
	switch (action.type) {
		case SET_EDITOR_SIMULATION: {
			const { simulation, updateActions } = action.payload

			if (!updateActions) {
				if (!state) {
					return state
				}

				return {
					...simulation,
					actions: state.actions,
					initialScreenActionId: state.initialScreenActionId,
					mapEventResults: state.mapEventResults,
				}
			}

			return simulation
		}

		case 'ADD_ACTION': {
			if (!state) return state
			return produce(state, (draft) => {
				const {
					simAction,
					config: { to: targetActionId, event, shiftScreens, currentPhase, ...extraConfig },
				} = action.payload

				if (shiftScreens) {
					doScreenShift(simAction, targetActionId, currentPhase, draft)
				}

				if (targetActionId) {
					addActionToParentActions(draft, targetActionId, simAction, event, extraConfig)
				} else if (!isScreenAction(simAction)) {
					throw new ReducerWarningError(
						'You may only add a screen action without a target parent action'
					)
				} else if (!draft.initialScreenActionId) {
					// Action is first on simulation
					draft.initialScreenActionId = simAction._id
					simAction.newPhase = DEFAULT_START_PHASE_NAME
				}

				draft.actions[simAction._id] = simAction
			})
		}

		/* Remove event result from all parent actions, and add event result to new parent */
		case 'MOVE_ACTION': {
			if (!state) return state
			return produce(state, (draft: AutomatedSimulation) => {
				const {
					id: actionId,
					config: {
						to: newParentActionId,
						timestamp,
						event,
						convertToScreen,
						shiftScreens,
						currentPhase,
					},
				} = action.payload
				removeActionFromParentActions(draft, actionId)
				const currentAction = draft.actions[actionId]
				if (!currentAction) {
					throw new ReducerFailedError('MOVE_ACTION: currentAction does not exist')
				}
				const convertedAction = convertActionIfNecessary(currentAction, convertToScreen || false)
				if (convertedAction) draft.actions[actionId] = convertedAction
				const simAction = draft.actions[actionId]
				if (!simAction) {
					throw new ReducerFailedError('MOVE_ACTION: simAction does not exist')
				}
				if (shiftScreens) {
					doScreenShift(simAction, newParentActionId, currentPhase, draft)
				}

				if (newParentActionId) {
					addActionToParentActions(draft, newParentActionId, simAction, event, {
						timestamp,
					})
				} else if (!isScreenAction(simAction)) {
					throw new ReducerWarningError(
						'You may only add a screen action without a target parent action'
					)
				}

				if (!allActionsAreReached(draft)) {
					throw new ReducerWarningError('Illegal action move')
				}
			})
		}

		/** Deletes an action and all of its children who otherwise would be left out of the tree */
		case 'DELETE_ACTION': {
			if (!state) return state
			return produce(state, (draft: AutomatedSimulation) => {
				const { id: actionIdToRemove } = action.payload
				sharedDeleteAction(actionIdToRemove, draft)
			})
		}

		case 'ADD_EVENT_RESULT_TO_ACTION': {
			if (!state) return state
			return produce(state, (draft: AutomatedSimulation) => {
				const {
					actionId,
					eventResultId,
					config: { event, ...extraConfig },
				} = action.payload

				if (!draft.actions[actionId]) {
					throw new ReducerFailedError('Could not find action to add to')
				} else if (!draft.actions[eventResultId]) {
					throw new ReducerFailedError('Could not find action to reference')
				} else {
					const actionToAddTo = draft.actions[actionId]

					if (event in actionToAddTo && EVENT_RESULT_ADDERS[event]) {
						EVENT_RESULT_ADDERS[event](actionToAddTo, eventResultId, extraConfig)
					} else {
						throw new ReducerFailedError('Event provided is not valid')
					}
				}
			})
		}

		/**
		 * Updates a single action and optionally adds new actions at the same time. This is generally the redux action that is dispatched
		 * when a simulation action is edited.
		 */
		case 'UPDATE_ACTION_AND_ADD_ACTIONS': {
			// TODO: Add map object id's to activate_station when its not a direct children of a map object event results.
			// i.e. onTractorBeam => timer(20sec) => ACTIVATE_TRACTOR_BEAM station
			if (!state) return state
			const { simulationAction, actions } = action.payload
			return produce(state, (draft: AutomatedSimulation) => {
				const actionId = simulationAction._id
				const keysSimulation: Array<string> = Array.from(
					new Set([
						// current action
						...keys(simulationAction),
						// previous action (could have deleted an event determiner key)
						...keys(state.actions[actionId]),
					])
				)

				// move any actions on the simulation
				const eventDeterminerKeys: EventType[] = intersection(
					keys(EVENT_RESULT_DETERMINERS),
					keysSimulation
				) as EventType[]
				eventDeterminerKeys.forEach((eventKey) => {
					const previousEventResult =
						EVENT_RESULT_DETERMINERS[eventKey](draft.actions[actionId]) ?? []
					const updatedEventResult = EVENT_RESULT_DETERMINERS[eventKey](simulationAction) ?? []
					const addedEvents = difference(updatedEventResult, previousEventResult)
					const deletedEvents = difference(previousEventResult, updatedEventResult)
					deletedEvents.forEach((eventResultId) => {
						if (draft.actions[eventResultId]) {
							const isReference =
								isScreenAction(draft.actions[eventResultId]) &&
								isScreenSimplyReferenced(
									eventResultId,
									{
										id: actionId,
										eventKey,
									},
									draft.actions,
									draft.initialScreenActionId
								)

							if (!isReference) {
								sharedDeleteAction(eventResultId, draft)
							}
						}
					})
					addedEvents.forEach((addedId) => {
						// For some actions we do NOT want to move them, we only want to reference them again on another action
						const isScreenRef = actions.some(
							(a) => a.type === 'SCREEN_REFERENCE' && a.reference === addedId
						)
						const addedAction = draft.actions[addedId]

						if (addedAction && !isScreenRef) {
							// move action
							removeActionFromParentActions(draft, addedId)
							addActionToParentActions(draft, actionId, addedAction, eventKey)
						}
					})
				})
				addNewActions(actions, draft)
				const oldAction = draft.actions[actionId]
				if (!oldAction) {
					throw new ReducerFailedError('UPDATE_ACTION_AND_ADD_ACTIONS: oldAction does not exist')
				}

				if (
					oldAction.type === 'ACTIVATE_STATION' &&
					(simulationAction.type !== 'ACTIVATE_STATION' ||
						oldAction.stationData.stationId !== simulationAction.stationData.stationId)
				) {
					removeActionFromStationQuestions(actionId, draft)
				}

				draft.actions[actionId] = simulationAction
			})
		}

		/* Deletes a map from the simulation */
		case 'DELETE_MAP': {
			if (!state) {
				return state
			}

			const { mapId } = action.payload
			return produce(state, (draft: AutomatedSimulation) => {
				removeMapIdFromState(mapId, draft)
			})
		}

		/* Deletes an objectId from the simulation */
		case 'DELETE_OBJECT': {
			if (!state) {
				return state
			}

			const { objectId } = action.payload
			const tempState = produce(state, (draft: AutomatedSimulation) => {
				removeObjectIdFromState(objectId, draft)
			})
			return tempState
		}

		/* Updates the map object event results for a given mapId and objectId and adds any created actions to the state */
		case 'UPDATE_MAP_OBJECT_RESULTS_AND_ADD_ACTIONS': {
			if (!state) {
				return state
			}

			const { mapId, objectId, mapObjectResults, actions } = action.payload
			return produce(state, (draft: AutomatedSimulation) => {
				if (draft.mapEventResults) {
					if (draft.mapEventResults[mapId]) {
						const oldMapObjectResults = draft.mapEventResults[mapId].objects[objectId]
						if (oldMapObjectResults) {
							// an EventResult array exists for given mapId and objectId
							forEachEntry(mapObjectResults, (_, eventKey) => {
								const previousEventResult = oldMapObjectResults[eventKey]
								const updatedEventResult = mapObjectResults[eventKey] as EventResult<string>
								const addedEvents = difference(updatedEventResult, previousEventResult || [])
								const deletedEvents = difference(previousEventResult, updatedEventResult)
								deletedEvents.forEach((eventResultId) => {
									if (draft.actions[eventResultId]) {
										const isScreenReferenced =
											isScreenAction(draft.actions[eventResultId]) &&
											isScreenSimplyReferenced(
												eventResultId,
												'MAP',
												draft.actions,
												draft.initialScreenActionId
											)
										const isActionReferencedInNewActions = isEventResultOfActivateStationAction(
											actions,
											eventResultId
										)

										if (!(isScreenReferenced || isActionReferencedInNewActions)) {
											sharedDeleteAction(eventResultId, draft)
										}
									}
								})
								addedEvents.forEach((eventResultId) => {
									// For some actions we do NOT want to move them, we only want to reference them again on another action
									const isScreenRef = actions.some(
										(a) => a.type === 'SCREEN_REFERENCE' && a.reference === eventResultId
									)

									if (draft.actions[eventResultId] && !isScreenRef) {
										// move action
										removeActionFromParentActions(draft, eventResultId)

										// If it's an ACTIVATE_STATION action, make sure it activates the station corresponding to `eventKey`,
										// and that it references the map object referenced by `mapId` and `objectId`
										if (draft.actions[eventResultId]?.type === 'ACTIVATE_STATION') {
											updateActivateStationActionForMapObjectResult({
												// This redux action can only happen on 4+ missions
												action: draft.actions[eventResultId] as JrPlusActivateStationAction<string>,
												eventKey,
												mapId,
												mapObjectId: objectId,
											})
										}
									}
								})
							})
						}

						draft.mapEventResults[mapId].objects[objectId] = mapObjectResults
					} else {
						draft.mapEventResults[mapId] = {
							objects: {
								[objectId]: mapObjectResults,
							},
							onAllObjectsScanned: [],
						}
					}
				} else {
					draft.mapEventResults = {
						[mapId]: {
							objects: {
								[objectId]: mapObjectResults,
							},
							onAllObjectsScanned: [],
						},
					}
				}

				addNewActions(actions, draft)
				actions.forEach((a) => {
					if (a.type !== 'SCREEN_REFERENCE') {
						const draftAction = draft.actions[a._id]

						if (draftAction?.type === 'ACTIVATE_STATION') {
							// This redux action can only happen on 4+ missions
							const activateStationAction = draftAction as JrPlusActivateStationAction<string>

							for (const eventKey_ in mapObjectResults) {
								const eventKey = eventKey_ as keyof MapObjectResults
								// We expect this condition to pass at least once during this loop
								if (mapObjectResults[eventKey]?.includes(activateStationAction._id)) {
									updateActivateStationActionForMapObjectResult({
										action: activateStationAction,
										eventKey,
										mapId,
										mapObjectId: objectId,
									})
									break
								}
							}
						}
					}
				})
			})
		}

		case SET_MAP_ORDERING: {
			return produce(state, (draft: BaseState) => {
				const { mapIds } = action.payload

				if (!draft) {
					console.error('no state')
					return
				}

				if (mapIds.length !== draft.mapIds.length) {
					console.error('Can not reorder mapIds previous and current are different lengths')
					return
				}

				const oldMapIds = new Set(draft.mapIds)

				for (const mapId of mapIds) {
					if (!oldMapIds.has(mapId)) {
						console.error(
							`A map "${mapId}" exists on the new ordering which did not exist on the old ordering`
						)
						return
					}
				}

				draft.mapIds = mapIds
			})
		}

		/**
		 * Set any key directly on the state
		 */
		case 'EDITOR_SIMULATION__SET_KEY': {
			return produce(state, (draft: BaseState) => {
				if (!draft) {
					return
				}

				const originalTeams = draft.teams
				// @ts-expect-error I'm not sure why this one is an error...
				draft[action.payload.key] = action.payload.value
				const newTeams = draft.teams

				if (newTeams.length < originalTeams.length) {
					// If teams are deleted, we need to remove them from any creative canvas stations
					const newTeamIds = new Set(newTeams.map((team) => team._id))
					const removedTeamIds = new Set(
						originalTeams.map((team) => team._id).filter((teamId) => !newTeamIds.has(teamId))
					)
					Object.values(draft.actions).forEach((simulationAction) => {
						if (
							simulationAction.type === 'ACTIVATE_STATION' &&
							simulationAction.stationData.stationId === 'CREATIVE_CANVAS' &&
							simulationAction.stationData.variant ===
								CREATIVE_CANVAS_STATION.VARIANT.ISSUE_PER_TEAM
						) {
							simulationAction.stationData.issues.forEach((issue) => {
								issue.teams = issue.teams.filter((teamId) => !removedTeamIds.has(teamId))
							})
						}
					})
				}
			})
		}

		default: {
			return state
		}
	}
}

/**
 * Updates the given `action` so that its referenced map object is the given `mapId`/`mapObjectId`, and that its stationId corresponds to the
 * given `eventKey` from `MapObjectResults`. For example, if `eventKey` is `onTransport`, the action is converted to a Transporter station, and so on.
 *
 * @param arg
 * @param arg.action The action to modify
 * @param arg.eventKey The key from `MapObjectResults` that determines the new station type
 * @param arg.mapId The new mapId to set on the action
 * @param arg.mapObjectId The new mapObjectId to set on the action
 */
function updateActivateStationActionForMapObjectResult({
	action,
	eventKey,
	mapId,
	mapObjectId,
}: {
	action: JrPlusActivateStationAction<string>
	eventKey: MapObjectResultType
	mapId: string
	mapObjectId: string
}) {
	if (eventKey === 'onScan') {
		// onScan does not have a station we want to switch out
		return
	}
	action.stationData = handleJrPlusStationChange(action.stationData, EVENT_KEY_TO_STATION[eventKey])

	if (
		action.stationData.stationId === JR_PLUS_STATION_IDS.DEFENSE_PLUS ||
		action.stationData.stationId === JR_PLUS_STATION_IDS.TRACTOR_BEAM_PLUS
	) {
		// Only update mapObject on 2.0 stations if the first target does not already have a mapObject
		if (action.stationData.targets[0] && !action.stationData.targets[0].mapObject) {
			delete action.stationData.targets[0].actionObjectInfo
			action.stationData.targets[0].mapObject = {
				mapId,
				mapObjectId,
			}
		}
	} else if (
		action.stationData.stationId !== JR_PLUS_STATION_IDS.REPAIRS &&
		action.stationData.stationId !== SHARED_STATION_IDS.CREATIVE_CANVAS
	) {
		action.stationData.mapObjectId = mapObjectId
		action.stationData.mapId = mapId
	}
}

/**
 * Function that checks to see if an action that we want to delete from the map object form has been attached to a newly created
 * station activation action.
 * There should be no other way on the map object form to put an existing action as the result of a new action, so it's ok that we
 * are only checking for station activation actions here.
 */
function isEventResultOfActivateStationAction(
	actions: (SimulationAction<string> | SimulationActionReference)[],
	actionIdInQuestion: ActionId
): boolean {
	return actions.some((action) => {
		if (action.type === 'ACTIVATE_STATION' && 'onComplete' in action.stationData) {
			return action.stationData.onComplete.some((innerAction) => innerAction === actionIdInQuestion)
		}

		return false
	})
}

export default undoable(simulationEditorReducer, {
	groupBy: (action: Action, currentState: BaseState) => {
		// The following conditions are met after the user clicks save. The action ids are all updated,
		// but the simulation doesn't change from the user's perspective. We should combine these two states
		// in the history so that they can't see the first (now invalid) state in the undo history
		// NOTE: undo/redo actions will reset the group to `null`, so this trick will not work after clicking undo/redo
		if (
			action.type === SET_EDITOR_SIMULATION &&
			action.payload.simulation._id === currentState?._id
		) {
			return getCurrentGroup()
		}

		return getNextGroup()
	},
}) // Functions used for grouping actions in the undo/redo history. The undo history group will always change, so if you want to stay
// in the same group, simply return `getCurrentGroup` from the groupBy function

const { getNextGroup, getCurrentGroup } = (function getGroupingFunctions() {
	let currentGroup = 0
	return {
		getNextGroup() {
			currentGroup = currentGroup ? 0 : 1
			return currentGroup
		},

		getCurrentGroup() {
			return currentGroup
		},
	}
})()

// SELECTORS
export function getSimulation(state: ReduxStore): AutomatedSimulation | null | undefined {
	return state.simulationEditor.present
}
export function useEditorSimulation(): AutomatedSimulation | null | undefined {
	const editorSimulation = useSelector(getSimulation)
	useDebugValue(editorSimulation)
	return editorSimulation
}
export function useUndoState(): {
	canUndo: boolean
	canRedo: boolean
} {
	const canUndo = useSelector((state: ReduxStore) => state.simulationEditor.past.length > 0)
	const canRedo = useSelector((state: ReduxStore) => state.simulationEditor.future.length > 0)
	return {
		canUndo,
		canRedo,
	}
}
const basicSelectors = {
	getAction: (
		state: ReduxStore,
		actionId: ActionId
	): SimulationAction<string> | null | undefined => {
		return getSimulation(state)?.actions[actionId]
	},
	getScreenAction: (
		state: ReduxStore,
		screenActionId: ScreenActionId
	): ScreenAction<string> | null | undefined => {
		const action = getSimulation(state)?.actions[screenActionId]
		return isScreenAction(action) ? action : null
	},
	getActionsFromIds: (state: ReduxStore, actionIds: ActionId[]): SimulationAction<string>[] => {
		const actions: SimulationAction<string>[] = []
		actionIds.forEach((actionId) => {
			const action = getSimulation(state)?.actions[actionId]
			if (action) actions.push(action)
		})
		return actions
	},
	getActions: (state: ReduxStore): ActionMap<string> | null | undefined => {
		return getSimulation(state)?.actions
	},
	getInitialScreenId: (state: ReduxStore): ScreenActionId | null | undefined => {
		return getSimulation(state)?.initialScreenActionId
	},

	getMapIds(state: ReduxStore): string[] {
		return getSimulation(state)?.mapIds || []
	},

	/* Returns the MapObjectResults for a given mapId and objectId. If a MapObjectResults doesn't exist, it returns null */
	getMapObjectResults(
		state: ReduxStore,
		mapId: string,
		objectId: string
	): MapObjectResults | null | undefined {
		return getSimulation(state)?.mapEventResults?.[mapId]?.objects[objectId] || null
	},

	isJunior(state: ReduxStore): boolean {
		return Boolean(getSimulation(state)?.controlSet === 'K-3')
	},

	getQuestions(state: ReduxStore): Question[] {
		return getSimulation(state)?.questions || []
	},
}

/**
 * Determines whether an actionId exists as one of a map objects event results.
 * @param {MapObjectResults} objectResults
 * @param {ActionId} actionId
 */
function doesObjectHaveAction(
	objectResults: MapObjectResults | null | undefined,
	actionId: ActionId | null | undefined
): boolean {
	if (
		objectResults &&
		actionId &&
		someEntry(objectResults, (events: EventResult<string>) => events.includes(actionId))
	) {
		return true
	}

	return false
}

const complexSelectors = {
	isChildOfMapObjectEvent(state: ReduxStore, actionId: ActionId): boolean {
		const action = basicSelectors.getAction(state, actionId)
		if (!action) return false

		if ('mapId' in action && 'mapObjectId' in action) {
			const objectResults = basicSelectors.getMapObjectResults(
				state,
				action.mapId,
				action.mapObjectId
			)
			return doesObjectHaveAction(objectResults, actionId)
		}

		const mapEventResults = getSimulation(state)?.mapEventResults
		return mapEventResults
			? someEntry(mapEventResults, (mapEventResult) =>
					someEntry(mapEventResult.objects, (objectResults) =>
						doesObjectHaveAction(objectResults, actionId)
					)
			  )
			: false
	},
}
export const selectors = { ...basicSelectors, ...complexSelectors }

/**
 * Determines whether an action is actually a ScreenAction.
 */
export function isScreenAction(screen: object | null | undefined): screen is ScreenAction<string> {
	return (
		!!screen &&
		'type' in screen &&
		(screen.type === 'VIDEO_SCREEN' ||
			screen.type === 'IMAGE_SCREEN' ||
			screen.type === 'NAVIGATION_SCREEN' ||
			screen.type === 'CULMINATING_MOMENT_SCREEN' ||
			screen.type === 'COLLABORATIVE_CULMINATING_MOMENT_SCREEN' ||
			screen.type === 'MAP_SCREEN' ||
			screen.type === 'QUESTION_SLOT_SCREEN')
	)
}

/**
 * Moves the screen action from the target's onEnd event results and adds the action to the given simulation action's onEnd event results.
 * Boundary cases:
 *   1. The targetActionId is null:
 *   Option 1: The new simAction could be replacing a phase reference
 *   Option 2: The new simAction could be replacing the initial screen id.
 *   We use currentPhase value to determine which option, swap the actions newPhase values,
 *   Reassign initialScreenActionId if we need to, and reassign all
 *   references throughout the action object to the new actionId
 * @param {SimulationAction} simAction
 * @param {ScreenActionId} targetActionId
 * @param {AutomatedSimulation} draft
 */
function doScreenShift(
	simAction: SimulationAction<string>,
	targetActionId: ScreenActionId | null | undefined,
	currentPhase: ScreenActionId | null | undefined,
	draft: AutomatedSimulation
) {
	if (!isScreenAction(simAction)) {
		throw new ReducerFailedError('We can only shift screen actions')
	}

	if (!draft.initialScreenActionId) {
		throw new ReducerFailedError(
			'Attempt to shift screens when there is no initial screen action defined'
		)
	}

	// We could either be at the beginning of a phase and/or at the beginning of the mission
	if (!targetActionId) {
		let swapScreen

		if (!currentPhase || currentPhase === draft.initialScreenActionId) {
			swapScreen = draft.actions[draft.initialScreenActionId]
			draft.initialScreenActionId = simAction._id
		} else {
			swapScreen = draft.actions[currentPhase]
		}

		if (!swapScreen) {
			throw new ReducerFailedError('swapScreen does not exist')
		}

		if ('newPhase' in swapScreen) {
			simAction.newPhase = swapScreen.newPhase
			delete swapScreen.newPhase
		}

		simAction.onEnd.push(swapScreen._id)
		reassignAllReferencesOfId(swapScreen._id, simAction._id, draft)
	} else {
		const target = draft.actions[targetActionId]
		const eventResultOnEndOfParent = EVENT_RESULT_DETERMINERS.onEnd(target) ?? []
		const screenEventResultId = eventResultOnEndOfParent.find((eventResultId) =>
			isScreenAction(draft.actions[eventResultId])
		)

		if (screenEventResultId) {
			EVENT_RESULT_REMOVERS.onEnd(target, screenEventResultId) // remove id from parent

			simAction.onEnd.push(screenEventResultId) // push onto new action
		}
	}
}

/**
 * Swaps all references of an action from any action's event results to a new action.
 * @param {ActionId} staleId
 * @param {ActionId} newId
 * @param {AutomatedSimulation} draft
 */
function reassignAllReferencesOfId(staleId: ActionId, newId: ActionId, draft: AutomatedSimulation) {
	const getUpdatedId = (id: string) => {
		if (id === staleId) {
			return newId
		} else return id
	}

	forEachEntry(draft.actions, (action) => {
		Object.keys(action).forEach((actionKey) => {
			if (actionKey in EVENT_RESULT_ID_UPDATERS) {
				action[actionKey as keyof SimulationAction<string>] = EVENT_RESULT_ID_UPDATERS[
					actionKey as keyof typeof EVENT_RESULT_ID_UPDATERS
				](action, getUpdatedId)
			}
		})
	})
}

/**
 * Given a simulation, returns the initial screen action.
 * @param {AutomatedSimulation} simulation
 */
function getInitialActionId(simulation: AutomatedSimulation): ActionId | null | undefined {
	return simulation.initialScreenActionId
}

/**
 * Finds all actionIds on a simulation that are never reached in the action graph.
 * @param {AutomatedSimulation} simulation
 *
 * @return ActionId[]
 */
function getUnreachableActionIds(simulation: AutomatedSimulation): ActionId[] {
	const totalActions = keys(simulation.actions).length
	if (totalActions === 0) return []
	const reachedActionIds = {}
	const initialActionId = getInitialActionId(simulation)
	if (!initialActionId) return []
	collectReachedChildrenIds(simulation.actions, initialActionId, reachedActionIds)

	if (simulation.mapEventResults) {
		collectedObjectEventResults(simulation.actions, simulation.mapEventResults, reachedActionIds)
	}

	return difference(keys(simulation.actions), keys(reachedActionIds))
}

/**
 * Checks to ensure all actions in the simulation are reachable.
 * @param {AutomatedSimulation} simulation
 *
 * @return boolean
 */
function allActionsAreReached(simulation: AutomatedSimulation): boolean {
	const unReachableActionIds = getUnreachableActionIds(simulation)
	return unReachableActionIds.length === 0
}

/*
 * Assumes that all map object event results are reachable and adds them to reachedActionIds
 */
function collectedObjectEventResults(
	actions: IdMap<SimulationAction<string>>,
	mapEventResults: Record<string, MapEventResult>,
	reachedActionIds: Record<ActionId, true>
) {
	Object.values(mapEventResults).forEach((mapEventResult) => {
		Object.values(mapEventResult.objects).forEach((eventResult) => {
			Object.values(eventResult).forEach((actionIds) => {
				actionIds.forEach((actionId) => {
					collectReachedChildrenIds(actions, actionId, reachedActionIds)
				})
			})
		})
	})
}

/**
 * Recursively traverses each child of a given action and adds that child's id to the object reachedChildrenIds,
 * guaranteeing that this function will only ever be called n times.
 *
 * @param {$ReadOnly<IdMap<SimulationAction>>} actions
 * @param {ActionId} actionId
 * @param {{ [ActionId]: true }} reachedChildrenIds
 */
function collectReachedChildrenIds(
	actions: IdMap<SimulationAction<string>>,
	actionId: ActionId,
	reachedChildrenIds: Record<ActionId, true>
): void {
	if (reachedChildrenIds[actionId]) return
	reachedChildrenIds[actionId] = true
	const children = getNextActionIdsFromAction(actionId, actions)
	children.forEach((childId) => collectReachedChildrenIds(actions, childId, reachedChildrenIds))
}

/**
 * Delete functionality shared between different reducers.
 * Given an action id to remove:
 * 1. Finds and removes the actionIdToRemove from all actions that reference it.
 * 2. If the actionIdToRemove is the initialScreenActionId, sets initialScreenActionId to null
 * 3. Deletes any actions that are now unreachable in the action tree.
 * @param {string} actionIdToRemove
 * @param {AutomatedSimulation} state
 */
function sharedDeleteAction(actionIdToRemove: string, state: AutomatedSimulation) {
	delete state.actions[actionIdToRemove]
	removeActionFromParentActions(state, actionIdToRemove)
	if (actionIdToRemove === state.initialScreenActionId) state.initialScreenActionId = null
	const otherActionIdsToRemove = getUnreachableActionIds(state)

	for (let i = 0; i < otherActionIdsToRemove.length; i++) {
		// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
		delete state.actions[otherActionIdsToRemove[i]!]
	}

	removeActionFromStationQuestions(actionIdToRemove, state)
}

/**
 * Removes reference to the provided `actionIdToRemove` from the simulation's stationQuestions.
 */
function removeActionFromStationQuestions(actionIdToRemove: string, state: AutomatedSimulation) {
	if (state.stationQuestions?.[actionIdToRemove]) {
		delete state.stationQuestions[actionIdToRemove]
	}
}

/**
 * Given a list of actions or action references, add the new ones to the draft simulation
 * @param {Array<SimulationAction | SimulationActionReference>} actions
 * @param {AutomatedSimulation} draft
 */
function addNewActions(
	actions: Array<SimulationAction<string> | SimulationActionReference>,
	draft: AutomatedSimulation
) {
	actions.forEach((newAction) => {
		if (newAction.type !== 'SCREEN_REFERENCE') {
			// We don't need to create an action for an action that is simply being referenced
			draft.actions[newAction._id] = newAction
		}
	})
}

/**
 * Adds an action to one of another action's (the parent) event results. 
 * Ensures that only one screen action is added to parent at a time.
 * 
 * @param {AutomatedSimulation} state 
 * @param {ActionId} parentId  The parent action id
 * @param {ActionId} idToAdd The action we wish to add to the parent
 * @param {EventType} event The type of event on the parent which we want to trigger the action
 * @param {{
		timestamp?: number,
		optionIndex?: number,
	}} config  Possible configurations needed for knowing exactly which event result array we will be adding to
 */
function addActionToParentActions(
	state: AutomatedSimulation,
	parentId: ActionId,
	actionToAdd: SimulationAction<string>,
	event: EventType,
	config?: {
		timestamp?: number
		optionIndex?: number
	}
) {
	const parent = state.actions[parentId]

	if (!parent) {
		throw new ReducerFailedError(
			`Parent action, ${parentId}, does not exist to add to event results`
		)
	}

	const addToParentEventResult = EVENT_RESULT_ADDERS[event]

	if (!addToParentEventResult) {
		// This should never occur. If it does, we have an error in our code and have ommited a event that yields an EventResult
		// in the EventResultMap type. To fix, add the missing event to the EVENT_RESULT_REMOVERS and EVENT_RESULT_DETERMINERS map.
		throw new ReducerFailedError(
			`Event ${event} is not registered as proper to add an event result to.`
		)
	}

	if (
		isScreenAction(actionToAdd) &&
		actionEventResultHasScreenAction(parent, event, state.actions, config)
	) {
		throw new ReducerWarningError(
			`You already have a screen action for ${event} of ${getTitle(
				parent
			)}, there cannot be more than one`
		)
	}

	const successfullyAdded = addToParentEventResult(parent, actionToAdd._id, config)
	if (!successfullyAdded)
		throw new ReducerFailedError(
			`Could not add action ${getTitle(actionToAdd)} to ${parent.type} parent with event ${event}`
		)
}

/**
 * Removes the given action from all of its parents. Inverse of addActionToParentActions.
 * Makes use of the function getParentsOfActionId to find all of the action's parents we need to remove from.
 * @param {AutomatedSimulation} state
 * @param {ActionId} idToRemove
 */
function removeActionFromParentActions(state: AutomatedSimulation, idToRemove: ActionId) {
	const isActionFirst = getInitialActionId(state) === idToRemove

	if (isActionFirst) {
		return {
			error: 'You cannot move the first screen',
			type: 'WARNING',
		}
	}

	const parentActions = getParentsOfActionId(idToRemove, state.actions)
	parentActions.forEach(({ id, triggerForChild }) => {
		const parent = state.actions[id]

		if (!parent) {
			// This should never occur. We just fetched all correct parent ids in getParentsOfActionId.
			throw new ReducerFailedError(
				`Parent action, ${id}, does not exist to remove from event results`
			)
		}

		const removeFromParentEventResult =
			EVENT_RESULT_REMOVERS[triggerForChild as keyof typeof EVENT_RESULT_REMOVERS]

		if (!removeFromParentEventResult) {
			// This should never occur. If it does, we have an error in our code and have omitted an event that yields an EventResult
			// in the EventResultMap type. To fix, add the missing event to the EVENT_RESULT_REMOVERS and EVENT_RESULT_DETERMINERS map.
			throw new ReducerFailedError(
				`Event ${triggerForChild} is not registered as proper to remove an event result from.`
			)
		}

		const successfullyRemoved = removeFromParentEventResult(parent, idToRemove)
		if (!successfullyRemoved)
			throw new ReducerFailedError(
				`Could not detach action ${idToRemove} from ${parent.type} parent with event ${triggerForChild}`
			)
	})
}

/**

/*
 * Removes the mapId from the list of mapIds, any actionId that references the mapId, and
 * from the state's mapEventResults
 */
function removeMapIdFromState(mapId: string, state: AutomatedSimulation) {
	state.mapIds = state.mapIds.filter((id) => id !== mapId)
	Object.values(state.actions).forEach((action) => {
		if ('mapId' in action && action.mapId === mapId) {
			action.mapId = ''
		}
	})

	if (state.mapEventResults?.[mapId]) {
		delete state.mapEventResults[mapId]
	}
}

/*
 * Removes the objectId from any actionId that references the objectId and
 * from the state's mapEventResults
 */
function removeObjectIdFromState(objectId: string, state: AutomatedSimulation) {
	Object.values(state.actions).forEach((action) => {
		if ('mapObjectId' in action && action.mapObjectId === objectId) {
			action.mapObjectId = ''
		}
	})

	if (state.mapEventResults) {
		Object.values(state.mapEventResults).forEach((mapEventResult) => {
			Object.keys(mapEventResult.objects).forEach((mapObjectId) => {
				if (mapObjectId === objectId) {
					delete mapEventResult.objects[objectId]
				}
			})
		})
	}
}

/**
 * Selector to get all actions in the simulation
 */
export function useEditorSimulationActions(): SimulationAction<string>[] {
	const actions = useSelector(basicSelectors.getActions)
	return actions ? Object.values(actions) : []
}

/**
 * Creates a typeguard function that verifies that the provided action is of the given `type`
 */
function actionHasType<Type extends SimulationAction<string>['type']>(type: Type) {
	return (action: SimulationAction<string>): action is SimulationAction<string> & { type: Type } =>
		action.type === type
}

/**
 * Selector to get all actions in the simulation with a certain type
 */
export function useEditorSimulationActionsByType<Type extends SimulationAction<string>['type']>(
	type: Type
): Array<SimulationAction<string> & { type: Type }> {
	const allActions = useEditorSimulationActions()
	return allActions.filter(actionHasType(type))
}

/**
 * a typeguard that confirms that the given activate station action is a Creative Canvas station
 * @param action
 * @returns
 */
function isCreativeCanvas(
	action: ActivateStationAction<string>
): action is ActivateStationActionWithStationData<CreativeCanvasStationData<string>, string> {
	return action.stationData.stationId === SHARED_STATION_IDS.CREATIVE_CANVAS
}

/**
 * Gets an array of all Creative Canvas stations in the simulation
 */
export function useCreativeCanvasStations(): Array<
	ActivateStationActionWithStationData<CreativeCanvasStationData<string>, string>
> {
	const activateStationActions: ActivateStationAction<string>[] =
		useEditorSimulationActionsByType('ACTIVATE_STATION')

	return activateStationActions.filter(isCreativeCanvas)
}

/**
 * Gets the control set of the editor simulation
 */
export function useControlSet(): ControlSet | void {
	const simulation = useEditorSimulation()
	return simulation?.controlSet
}

/**
 * A hook that gets all maps of the current editor simulation
 */
export function useMaps(): NormalizedMap[] {
	const allMaps = useSelector(tileSelectors.maps.store)
	const mapIds = useSelector(basicSelectors.getMapIds)

	return mapIds.map((mapId) => allMaps[mapId]).filter((map) => !!map)
}
