import React, { useState, useRef, useEffect } from 'react'
import { useSelector } from 'react-redux'
import styled, { ThemeProvider } from 'styled-components'
import THEME from './theme'
import ScreenList from './ScreenList'
import ActionCanvas from './ActionCanvas'
import { actionHelpers } from './helpers'
import { selectors, isScreenAction } from '../../../reducers/simulationEditor'
import { Button, ButtonGroup } from 'reactstrap'
import type { Action as ActionType, Screen as ScreenType, DurationMap, ScreensList } from './types'
import type { ScreenActionId } from '../actionDefinitions'
import type { FormConfig } from '../FormContext'
import { DragLayer } from '../DragDrop'
import ActionBank from '../ActionBank/ActionBank'
import { INITIAL_DURATION_MAP } from '../helpers/constants'
import { FormContextProvider } from '../FormContext'
import FormPopover from '../Forms/FormPopover'
import ActionPlayer from '../ActionPlayer/ActionPlayer'
import { IdMap } from '../../../types/util'
import type { Action } from '@mission.io/mission-toolkit/actions'

type Props = {
	screensList: ScreensList
	actions: ActionType[]
	durationMap: DurationMap | null | undefined
	selectedForm: FormConfig | null | undefined
}

const getActiveScreens = (
	screensList: ScreensList,
	actions: IdMap<Action<string>>,
	durations: DurationMap,
	activeScreenRef: {
		current: Record<number, string>
	}
): ScreenType[] => {
	const screens: ScreenType[] = []
	const addedIds: Record<ScreenActionId, number> = {}
	screensList.forEach((screenIdOrIds, index) => {
		let screenId: ScreenActionId
		if (typeof screenIdOrIds === 'string') screenId = screenIdOrIds
		else {
			// @ts-expect-error TS2322 SUPPRESS ERRORS FOR NEW OPTION noUncheckedIndexedAccess
			screenId =
				screenIdOrIds.find((id) => id === activeScreenRef.current[index]) || screenIdOrIds[0]
		}
		const screen = getScreenTypeFromId(screenId, actions, durations)

		if (screen) {
			if (addedIds[screenId]) {
				screens.push({ ...screen, meta: { ...screen.meta, duplicate: addedIds[screenId] } })
				// @ts-expect-error TS2532 SUPPRESS ERRORS FOR NEW OPTION noUncheckedIndexedAccess
				addedIds[screenId] += 1
			} else {
				screens.push(screen)
				addedIds[screenId] = 1
			}
		}
	})
	return screens
}

function getScreenTypeFromId(
	screenId: ScreenActionId,
	actions: IdMap<Action<string>>,
	durations: DurationMap
): ScreenType | null | undefined {
	const screen = actions[screenId]

	if (isScreenAction(screen)) {
		return actionHelpers.fromScreenToScreenType(screen, screenId, durations)
	}
}

export default function PhaseCreator(props: Props): JSX.Element {
	const { screensList, actions, durationMap, selectedForm } = props
	const editorRefs = useRef({})
	const containerRef = useRef<HTMLDivElement>(null)
	const activeScreenRef = useRef<{ [index: number]: string }>({})
	const [theme, setTheme] = useState(THEME)
	const actionMap = useSelector(selectors.getActions)
	const initialScreenId = useSelector(selectors.getInitialScreenId)
	const [screens, setScreens] = useState<ScreenType[] | null>(null)
	const [actionPlayerAction, setActionPlayerAction] = useState<string | null>(null)
	useEffect(() => {
		if (actionMap && durationMap) {
			const newScreens = getActiveScreens(screensList, actionMap, durationMap, activeScreenRef)
			setScreens(newScreens)
		}
	}, [screensList, actionMap, durationMap])

	const setScreenFocus = (screenIndex: number, screenId: ScreenActionId) => {
		if (!screens || !actionMap || !durationMap) return
		const screen = screens[screenIndex]

		if (screen && screen.id !== screenId) {
			activeScreenRef.current[screenIndex] = screenId
			setScreens(getActiveScreens(screensList, actionMap, durationMap, activeScreenRef))
		}
	}

	const changeCardWidths = (multiple: number) => {
		setTheme((theme) => ({ ...theme, pixelsPerMs: theme.pixelsPerMs * multiple }))
	}

	return (
		<CreatorStyle ref={containerRef}>
			<ThemeProvider theme={theme}>
				<DragLayer editorRefs={editorRefs} />
				<ButtonGroup css="position: absolute !important; z-index: 11;">
					<Button onClick={() => changeCardWidths(1.5)}>+</Button>
					<Button onClick={() => changeCardWidths(0.75)}>-</Button>
				</ButtonGroup>
				{durationMap && screens ? (
					<FormContextProvider selectedForm={selectedForm}>
						<CanvasContainer>
							<ScreenList
								screens={screens}
								screensList={screensList}
								setScreenFocus={setScreenFocus}
								durations={durationMap}
								screenRefs={editorRefs}
							/>
							<ActionCanvas
								screens={screens}
								actions={actions}
								durations={durationMap}
								screenRefs={editorRefs}
							/>
							<FormPopover playAction={setActionPlayerAction} />
							{actionPlayerAction && actionMap && (
								<ActionPlayer
									actionId={actionPlayerAction}
									actions={actionMap}
									onClose={() => setActionPlayerAction(null)}
								/>
							)}
						</CanvasContainer>
					</FormContextProvider>
				) : !initialScreenId ? (
					<CanvasContainer>
						<ScreenList
							screens={[]}
							screensList={[]}
							setScreenFocus={() => void 0}
							durations={INITIAL_DURATION_MAP}
							screenRefs={editorRefs}
						/>
					</CanvasContainer>
				) : (
					<Loader />
				)}

				<ActionBank />
			</ThemeProvider>
		</CreatorStyle>
	)
}

const Loader = () => <div css="text-align: center">Loading...</div>

const CreatorStyle = styled.div`
	width: 100%;
	height: 100%;
`
const CanvasContainer = styled.div`
	overflow: auto;
	max-height: 40vh;
	width: 100%;
`
