import React, { useRef, useState, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { renderObject } from './basicComponents/router'
import KeyManager from './managers/KeyManager'
import Menu from './editorMenu/Menu'
import Properties from './properties/Properties'
import styled from 'styled-components'
import ObjectOverview from './objectOverview/ObjectOverview'
import {
	DEFAULT_COLOR,
	DEFAULT_DRAWING_DATA,
	PRIMITIVE_PROPERTY_OVERRIDES,
	PROPERTIES_EDITOR,
	EditorType,
	PRIMITIVE_TYPES,
	CAMERA_WIDTH,
	VIDEO_WARNING,
	GIF_WARNING,
	BEFORE_UNLOAD_MESSAGE,
	Handles,
} from './constants'
import { useComponentSize } from 'react-use-size'
import { createDefaultCamera, resolveBoundingBox, handleHandleMovement } from './utilities'
import EditorContext from './context'
import { toast } from 'react-toastify'
import { useEntity, useMap } from './hooks'
import { refreshStore as refreshEntityStore } from '../../reducers/entities'
import { createTemporaryId } from '../../helpers/functions'
import { getNewEntityUrl } from './utilities'
import Preview from './QuickPreview'
import type { NavigationMap, Entity } from '@mission.io/navigation-map-server'

import type {
	OnSelect,
	Selected,
	ParentData,
	PrimitiveTypes,
	WorldComponent,
	SaveData,
	CollisionType,
	ControlType,
	HealthType,
} from './types'
import type { Data as PropertiesData } from './properties/Properties'
import { BlockNavigation } from '../../common/BlockNavigation'
const SVG_WIDTH = 1000
const SVG_HEIGHT = 800
type BasicProperties = {
	name: string
	description?: string
}
type State = {
	selected: Selected
	x: number
	y: number
	zoom: number
	isDragging: boolean
	isDrawing: boolean
	willDraw: PrimitiveTypes | null | undefined
	dragging: {
		lastX: number
		lastY: number
		startingObject: WorldComponent | null | undefined
	}
	isZooming: boolean
	zooming: {
		beginningX: number
		beginningY: number
		startingZoom: number
		beginningWorldX: number
		beginningWorldY: number
	}
	drawing: {
		x: number
		y: number
		width: number
		height: number
		data: {
			url?: string | null | undefined
			name: string
			collision?: (CollisionType | boolean) | null | undefined
			color?: string | null | undefined
			control?: ControlType | null | undefined
			entity?: string | null | undefined
			follow?: string | null | undefined
			health?: HealthType | null | undefined
			rotation?: number | null | undefined
		}
	}
	loaded: boolean
	worldObjects: WorldComponent[]
	editorType: EditorType
	editing: string | null | undefined
	properties: BasicProperties
}
type Props = {
	isEntityEditor: boolean
	editing?: string | null | undefined
}
export default function Editor(props: Props): JSX.Element {
	const [state, setState] = useState<State>({
		selected: {
			_id: null,
			handles: null,
		},
		x: 0,
		y: 0,
		zoom: props.isEntityEditor ? SVG_WIDTH : SVG_WIDTH / CAMERA_WIDTH,
		isDragging: false,
		isDrawing: false,
		isZooming: false,
		zooming: {
			beginningX: 0,
			beginningY: 0,
			startingZoom: SVG_WIDTH,
			beginningWorldX: 0,
			beginningWorldY: 0,
		},
		dragging: {
			lastX: 0,
			lastY: 0,
			startingObject: null,
		},
		drawing: {
			x: 0,
			y: 0,
			width: 0,
			height: 0,
			data: {
				color: DEFAULT_COLOR,
				collision: false,
				name: '',
			},
		},
		loaded: false,
		willDraw: null,
		worldObjects: [],
		editorType: props.isEntityEditor ? EditorType.ENTITY : EditorType.WORLD,
		editing: props.editing,
		properties: {
			...{
				name: 'Anonymous',
			},
			...(!props.isEntityEditor && {
				description: '',
			}),
		},
	})

	const { ref: svgCanvasWrapperRef, height, width } = useComponentSize()
	const aspectRatioWidth = width || SVG_WIDTH
	const aspectRatioHeight = height || SVG_HEIGHT

	const [isPreviewing, setIsPreviewing] = useState(false)
	const svgRef = useRef<SVGSVGElement>(null)
	const { current: keyManager } = useRef(new KeyManager())
	const hasSeenVideoWarning = useRef(false)
	const hasSeenGifWarning = useRef(false)
	const editingEntity = useEntity(props.isEntityEditor ? props.editing : null)
	const editingMap = useMap(!props.isEntityEditor ? props.editing : null)
	const dispatch = useDispatch()
	const editing: (NavigationMap | Entity) | null | undefined = props.isEntityEditor
		? editingEntity
		: editingMap
	const requiresSave = // we are creating a new map. Require save
		!props.editing || // we are editing an existing map. Only require save if the data has changed
		(!!editing &&
			(state.properties.name !== editing.name ||
				state.worldObjects !== editing.components ||
				state.properties.description !== editing.description))
	const shouldSkipBeforeUnloadMessage = useRef(false)

	const aspectRatio = aspectRatioHeight / aspectRatioWidth

	// set the world components when server data finishes loading
	useEffect(() => {
		if (!props.editing) {
			setState((state) => {
				return {
					...state,
					loaded: true,
					worldObjects: !props.isEntityEditor ? [createDefaultCamera()] : [],
				}
			})
			return
		}

		if (editing) {
			const properties: BasicProperties = {
				name: editing.name,
			}

			if (!props.isEntityEditor) {
				properties.description = editing.description || ''
			}

			setState((state) => {
				return {
					...state,
					loaded: true,
					worldObjects: editing.components,
					properties,
				}
			})
		}
	}, [editing, props.isEntityEditor, props.editing])

	const showVideoWarning = () => {
		if (hasSeenVideoWarning.current) {
			return
		}

		hasSeenVideoWarning.current = true
		toast.warn(VIDEO_WARNING, {
			autoClose: false,
		})
	}

	const showGifWarning = () => {
		if (hasSeenGifWarning.current) {
			return
		}

		hasSeenGifWarning.current = true
		toast.error(GIF_WARNING, {
			autoClose: false,
		})
	}

	useEffect(() => {
		const zoom = (proportion: number) => {
			setState((state) => {
				const zoom = state.zoom * proportion
				const zoomProportion = zoom / state.zoom
				return {
					...state,
					zoom: zoom,
					x: (SVG_WIDTH / 2) * (1 - zoomProportion) + state.x * zoomProportion,
					// apply zoom to center of the view port
					y: (SVG_HEIGHT / 2) * (1 - zoomProportion) + state.y * zoomProportion,
				}
			})
		}

		keyManager.clearCallbacks()
		keyManager.startListening()
		keyManager.mountOnPress('-', () => {
			if (keyManager.isKeyPressed('Control')) {
				zoom(0.75)
			}
		})
		keyManager.mountOnPress('=', () => {
			if (keyManager.isKeyPressed('Control')) {
				zoom(1.25)
			}
		})
		return () => {
			keyManager.stopListening()
		}
	}, [setState, keyManager])

	// creates the base coordinate system with the correct aspectRatio to avoid
	// stretching, and camera locations.  Returns an object which describes how
	// the editors coorddinate system projects onto the screens coordinate system.
	const getBaseLocationData = (): ParentData => {
		const { x, y, zoom } = state
		return {
			realParentX: x,
			// viewPort x
			realParentY: y,
			// viewPort y
			realParentWidth: zoom * aspectRatio,
			// viewPort width
			realParentHeight: zoom / aspectRatio,
			// viewPort height
			parentWidth: zoom,
			// world camera width
			parentHeight: zoom, // world camera height (same as parentWidth to avoid stretching)
		}
	}

	// handle mouse movement in the svg
	const onMouseMove = (event: React.MouseEvent) => {
		const eventData = {
			clientX: event.clientX,
			clientY: event.clientY,
		}
		setState((state) => {
			const { x: currentX, y: currentY } = getXYPosition(eventData)
			const { selected, dragging } = state

			if (selected._id && state.isDragging && !keyManager.isKeyPressed('Alt')) {
				// move the selected object
				const screenPosition = state
				const startingObject: WorldComponent | null | undefined = dragging.startingObject
				const newWorldObjects = state.worldObjects.map((worldObject) => {
					if (worldObject._id === selected._id && startingObject) {
						const deltaX = (currentX - state.dragging.lastX) / screenPosition.zoom
						const deltaY = (currentY - state.dragging.lastY) / screenPosition.zoom

						if (selected.handles && startingObject) {
							return handleHandleMovement(deltaX, deltaY, startingObject, selected.handles)
						}

						return { ...worldObject, x: startingObject.x + deltaX, y: startingObject.y + deltaY }
					}

					return worldObject
				})
				return {
					...state,
					worldObjects: newWorldObjects,
				}
			} else if (state.isDragging) {
				// pan the world
				return {
					...state,
					x: state.x + (currentX - state.dragging.lastX),
					y: state.y + (currentY - state.dragging.lastY),
					dragging: { ...state.dragging, lastX: currentX, lastY: currentY },
				}
			} else if (state.isDrawing) {
				// update the bounds being drawn on the screen
				return {
					...state,
					drawing: {
						...state.drawing,
						width: currentX - state.drawing.x,
						height: currentY - state.drawing.y,
					},
				}
			} else if (state.isZooming) {
				// handle zooming
				const { zooming } = state
				const width = SVG_WIDTH
				const height = SVG_HEIGHT
				let sign = 1
				const coordinateDistance = Math.max(
					Math.abs((zooming.beginningX - currentX) / width),
					Math.abs((zooming.beginningY - currentY) / height)
				)
				// consider the zoom direction to be out if the mouse is down or right from the starting position
				const isZoomingOut =
					(zooming.beginningX - currentX) / width + (zooming.beginningY - currentY) / height < 0

				if (isZoomingOut) {
					sign = -1
				}

				const zoom = Math.abs(
					zooming.startingZoom * (1 + 0.5 * sign * (coordinateDistance * 2) ** 2)
				)
				const zoomProportion = zoom / zooming.startingZoom
				return {
					...state,
					zoom,
					x: zooming.beginningX * (1 - zoomProportion) + zooming.beginningWorldX * zoomProportion,
					y: zooming.beginningY * (1 - zoomProportion) + zooming.beginningWorldY * zoomProportion,
				}
			}

			return state
		})
	}

	// get the x y position on the svg that an event occurred
	const getXYPosition = (event: { clientX: number; clientY: number }) => {
		const svg = svgRef.current

		if (!svg) {
			return {
				x: 0,
				y: 0,
			}
		}

		const rect = svg.getBoundingClientRect()
		return {
			x: ((event.clientX - rect.left) * SVG_WIDTH) / aspectRatioWidth,
			y:
				((((event.clientY - rect.top) * SVG_HEIGHT) / aspectRatioHeight) * SVG_WIDTH) /
				aspectRatioWidth,
		}
	}

	// handle a mouse press event
	const onMouseDown = (eventIn: React.MouseEvent) => {
		const eventData = {
			clientX: eventIn.clientX,
			clientY: eventIn.clientY,
		}
		setState((state) => {
			const { x, y } = getXYPosition(eventData)

			if (state.willDraw) {
				// start drawing a new object
				return {
					...state,
					isDrawing: true,
					drawing: { ...state.drawing, x, y, width: 0, height: 0 },
				}
			} else if (keyManager.isKeyPressed('Shift')) {
				// zoom
				return {
					...state,
					isZooming: true,
					zooming: {
						beginningX: x,
						beginningY: y,
						startingZoom: state.zoom,
						beginningWorldX: state.x,
						beginningWorldY: state.y,
					},
				}
			} else {
				// pan or drag
				let startingObject: WorldComponent | null | undefined = null

				for (let j = 0; j < state.worldObjects.length; j++) {
					// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
					const objectData = state.worldObjects[j]!

					if (objectData._id === state.selected._id) {
						startingObject = { ...objectData }
					}
				}

				return {
					...state,
					isDragging: true,
					dragging: {
						lastX: x,
						lastY: y,
						startingObject,
					},
				}
			}
		})
	}

	// add an object to the world, keeps camera on top if there is a camera in the world
	const addObject = (object: WorldComponent, currentObjects: WorldComponent[]) => {
		const newObjects = [...currentObjects, object]
		const length = newObjects.length

		if (length < 2) {
			return newObjects
		}

		// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
		if (newObjects[length - 2] && newObjects[length - 2]!.type === PRIMITIVE_TYPES.CAMERA) {
			// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
			newObjects[length - 1] = newObjects[length - 2]!
			newObjects[length - 2] = object
		}

		return newObjects
	}

	// handle mouse release event
	const onMouseUp = (event: React.MouseEvent) => {
		if (state.isDrawing) {
			// add the new object to the world
			const screenPosition = state
			const data = state.drawing.data
			let { x, y, width, height } = state.drawing

			if (width === 0 || height === 0) {
				return
			}

			;({ x, y, width, height } = resolveBoundingBox(x, y, width, height))
			setState((state) => {
				return {
					...state,
					willDraw: null,
					worldObjects: addObject(
						{
							_id: createTemporaryId(),
							// @ts-expect-error - Types need to be updated
							type: state.willDraw,
							x: (-screenPosition.x + x) / screenPosition.zoom,
							y: (-screenPosition.y + y) / screenPosition.zoom,
							width: width / screenPosition.zoom,
							height: height / screenPosition.zoom,
							...data,
						},
						state.worldObjects
					),
				}
			})
		}

		setState((state) => {
			return { ...state, isDragging: false, isDrawing: false, isZooming: false }
		})
	}

	const onClick = () => {
		setState((state) => {
			return { ...state, isDragging: false, isDrawing: false, isZooming: false }
		})
	}

	const drawingContent = () => {
		if (!state.isDrawing) {
			return null
		}

		const data = state.drawing.data
		let { x, y, width, height } = state.drawing

		if (width === 0 || height === 0) {
			return
		}

		;({ x, y, width, height } = resolveBoundingBox(x, y, width, height))
		const screenPosition = state
		return renderObject(
			{
				_id: 'Drawing',
				// @ts-expect-error - Types need to be updated
				type: state.willDraw,
				x: (-screenPosition.x + x) / screenPosition.zoom,
				y: (-screenPosition.y + y) / screenPosition.zoom,
				width: width / screenPosition.zoom,
				height: height / screenPosition.zoom,
				...data,
			},
			getBaseLocationData()
		)
	}

	const toggleDrawing = (shape: PrimitiveTypes) => {
		if (state.willDraw === shape) {
			setState((state) => {
				return { ...state, willDraw: null, isDrawing: false }
			})
		} else {
			setState((state) => ({
				...state,
				willDraw: shape,
				drawing: {
					...state.drawing,
					data: { ...DEFAULT_DRAWING_DATA[state.editorType][shape], name: '' },
				},
			}))
		}
	}

	const select: OnSelect = (
		_id: string | null | undefined,
		handles: Handles[] | null | undefined
	) => {
		setState((state) => {
			if (state.isDrawing || state.isZooming || state.isDragging) {
				return state
			}

			return {
				...state,
				selected: {
					_id,
					handles,
				},
			}
		})
	}

	const deleteObject = (_id: string) => {
		setState((state) => {
			return {
				...state,
				worldObjects: state.worldObjects.filter(
					(object) => object._id !== _id || object.type === PRIMITIVE_TYPES.CAMERA
				),
				selected: { ...state.selected, _id: null, handles: null },
			}
		})
	}

	const renderProperties = () => {
		const { _id } = state.selected
		const { willDraw, drawing } = state
		const object = state.worldObjects.find((worldObject) => worldObject._id === _id)
		let type = null

		if (willDraw) {
			type = willDraw
		} else if (object) {
			type = object.type
		}

		const data = willDraw ? drawing.data : object || state.properties
		return (
			<Properties
				forId={willDraw ? undefined : _id}
				data={data}
				typing={{
					_id: PROPERTIES_EDITOR.IGNORE,
					type: PROPERTIES_EDITOR.IGNORE,
					...PRIMITIVE_PROPERTY_OVERRIDES[state.editorType][type || 'DEFAULT'],
				}}
				changeHandlers={{
					width: (value: unknown, data: PropertiesData) => {
						if (typeof value !== 'number' || typeof data.x !== 'number') {
							return data
						}

						const { x, width } = resolveBoundingBox(data.x, 0, value, 0)
						return { ...data, x, width }
					},
					height: (value: unknown, data: PropertiesData) => {
						if (typeof value !== 'number' || typeof data.y !== 'number') {
							return data
						}

						const { y, height } = resolveBoundingBox(0, data.y, 0, value)
						return { ...data, y, height }
					},
				}}
				// eslint-disable-next-line @typescript-eslint/no-explicit-any
				onChange={(_id: string | null | undefined, data: any) => {
					setState((state) => {
						if (_id) {
							return {
								...state,
								worldObjects: state.worldObjects.map((worldObject) => {
									if (worldObject._id === _id) {
										return data
									}

									return worldObject
								}),
							}
						}

						if (willDraw) {
							return { ...state, drawing: { ...state.drawing, data } }
						}

						return { ...state, properties: data }
					})
				}}
				components={state.worldObjects}>
				{!props.isEntityEditor && !_id && !willDraw ? (
					<Buttons>
						<Button
							onClick={() => {
								window.open(getNewEntityUrl(), '_blank')
							}}>
							Create Entity
						</Button>
						<Button
							onClick={() => {
								dispatch(refreshEntityStore())
							}}>
							Update Entities
						</Button>
					</Buttons>
				) : null}
			</Properties>
		)
	}

	const swapPositions = (id1: string, id2: string) => {
		setState((state) => {
			let index1 = null
			let index2 = null
			state.worldObjects.forEach((object, index) => {
				if (object._id === id1) {
					index1 = index
				}

				if (object._id === id2) {
					index2 = index
				}
			})

			if (index1 === null || index2 === null) {
				return state
			}

			const newWorldObjects = [...state.worldObjects]
			// index1 and index2 must be valid at this point
			// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
			const temp = newWorldObjects[index1]!
			// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
			newWorldObjects[index1] = newWorldObjects[index2]!
			newWorldObjects[index2] = temp
			return { ...state, worldObjects: newWorldObjects }
		})
	}

	const getSaveData = (): SaveData => {
		return { ...state.properties, data: state.worldObjects, _id: state.editing }
	}

	const afterSave = (savedEntityOrMap: Entity | NavigationMap) => {
		if (savedEntityOrMap._id && savedEntityOrMap._id !== state.editing) {
			// redirect to new url, skipping the require save message
			shouldSkipBeforeUnloadMessage.current = true
			let url = window.location.href

			if (!url.endsWith('/')) {
				url += '/'
			}

			url += String(savedEntityOrMap._id)
			window.location.replace(url)
		}
	}

	const { worldObjects, loaded } = state

	if (!loaded) {
		return (
			<StyledEditor>
				<h1> Loading</h1>
			</StyledEditor>
		)
	}

	if (isPreviewing && !props.isEntityEditor) {
		const saveData = getSaveData()
		const map: NavigationMap = {
			_id: saveData._id || 'anonymous-map-id',
			components: saveData.data,
			description: saveData.description || 'anonymous-map-description',
			x: 0,
			y: 0,
			width: 1,
			height: 1,
			name: saveData.name || 'anonymous-map-name',
		}
		return (
			<>
				<h3 onClick={() => setIsPreviewing(false)}>Go Back</h3>
				<Preview map={map} allowDebugging={true} />
			</>
		)
	}

	return (
		<StyledEditor>
			<BlockNavigation
				getBlockMessage={() =>
					!shouldSkipBeforeUnloadMessage.current && requiresSave ? BEFORE_UNLOAD_MESSAGE : null
				}
			/>
			<ObjectOverview
				objects={worldObjects}
				onSelect={select}
				selected={state.selected._id}
				swapPositions={swapPositions}
				onDelete={deleteObject}
			/>
			<EditorContext.Provider
				value={{
					onSelect: select,
					selected: state.selected._id,
					showVideoWarning,
					showGifWarning,
				}}>
				<SvgCanvasWrapper ref={svgCanvasWrapperRef}>
					<SvgCanvas
						ref={svgRef}
						width={SVG_WIDTH}
						height={SVG_HEIGHT}
						onMouseDown={onMouseDown}
						onMouseMove={onMouseMove}
						onMouseUp={onMouseUp}
						onClick={onClick}
						preserveAspectRatio="xMidYMid meet"
						viewBox={`0 0 ${SVG_WIDTH} ${SVG_HEIGHT}`}>
						<rect
							x="0"
							y="0"
							onClick={() => select(undefined)}
							width={SVG_WIDTH}
							height={SVG_HEIGHT}
							style={{ fill: 'Transparent' }}></rect>
						{worldObjects.map((object) => renderObject(object, getBaseLocationData()))}
						{drawingContent()}
					</SvgCanvas>
				</SvgCanvasWrapper>
			</EditorContext.Provider>
			<Menu
				editorType={state.editorType}
				currentlySelected={state.willDraw}
				onSelect={toggleDrawing}
				getSaveData={getSaveData}
				afterSave={afterSave}
				setPreview={setIsPreviewing}
			/>
			{renderProperties()}
		</StyledEditor>
	)
}
const StyledEditor = styled.div`
	display: flex;
	> * {
		flex: 0 0 0;
	}
`
const SvgCanvasWrapper = styled.div`
	border: 1px solid black;
	flex: 1 1 0;
	min-width: 0;
	max-width: 100%;
`
const SvgCanvas = styled.svg`
	min-width: 100%;
	max-width: 100%;
	height: auto;
`
const Button = styled.div`
	background-color: blue;
	border-radius: 4px;
	padding: 4px;
	color: white;

	&:hover {
		background-color: green;
		cursor: pointer;
	}
`
const Buttons = styled.div`
	display: flex;
	justify-content: space-around;
`
