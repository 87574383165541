import React, { ReactNode } from 'react'
import { Input, FormGroup, Label } from 'reactstrap'
import { BiInfoCircle } from 'react-icons/bi'
import { UncontrolledTooltip } from 'reactstrap'

/**
 * Display a reactstrap switch input with a label
 *
 * @param {boolean} on whether the switch is on or off
 * @param {ReactNode} label the label to display next to the switch
 * @param {function} onChange the function to call when the switch is toggled
 * @param {{id: string, text: string}} [description] an optional description to display in a tooltip
 * @returns
 */
export function Switch({
	label,
	on,
	onChange,
	description,
	...props
}: {
	on: boolean
	label: ReactNode
	onChange: (value: boolean) => void
	disabled?: boolean
	className?: string
	style?: React.CSSProperties
	description?: {
		id: string
		text: string
	}
}): ReactNode {
	return (
		<FormGroup switch>
			<Input
				type="switch"
				role="switch"
				checked={on}
				onChange={(event) => {
					onChange(event.target.checked)
				}}
				{...props}
			/>
			<Label check className="text-[.9rem] font-bold mb-[0.5rem]">
				{label}
				{description && (
					<>
						<BiInfoCircle size={20} id={description.id} className="inline -mt-1 ml-1 opacity-75" />
					</>
				)}
				{description && (
					<UncontrolledTooltip placement="top" target={description.id}>
						{description.text}
					</UncontrolledTooltip>
				)}
			</Label>
		</FormGroup>
	)
}
