import { useRef, useMemo } from 'react'
import uuid from 'uuid/v4'
import type { Activity } from '@mission.io/mission-guide'
import type { ActivityWithDropId } from './ActivityList'

/**
 * useActivitiesWithDroppableIds - adds dropId to activities. Tries to keep the ids the same between calls to stop
 *   react-beautiful-dnd from blinking
 *
 * @param {MissionGuideActivity[]} activities - the activities to add dropIds to
 * @return {ActivityWithDropId[]} - the activities with dropIds
 */
export function useActivitiesWithDroppableIds(
	activities: Activity[] | null | undefined
): ActivityWithDropId[] {
	const activityIds = useRef<string[]>([])
	return useMemo(() => {
		return (activities || []).map((activity, index) => {
			if (index >= activityIds.current.length) {
				activityIds.current[index] = uuid()
			}

			if (!activityIds.current[index]) {
				throw new Error(`The activity id is not defined. This should be impossible.`)
			}

			return { ...activity, dropId: activityIds.current[index] }
		})
	}, [activities])
}
