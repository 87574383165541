import type { Ship, CelestialBody, OtherObject, Contact } from '../types/MapTypes'
import update, { Spec } from 'immutability-helper'
import type { ReduxStore } from '../types/ReduxStore'
import axios from 'axios'
import _ from 'lodash'
import type { AnyAction, Dispatch } from 'redux'
import { config } from '../config'

export type State = {
	ships: Record<string, Ship>
	celestialBodies: Record<string, CelestialBody>
	other: Record<string, OtherObject>
	contacts: Record<string, Contact>
}
export type MapObjectsKey = keyof State
const INITIAL_STATE: State = {
	ships: {},
	celestialBodies: {},
	other: {},
	contacts: {},
}

const mapStoreTypesToServerResource = {
	celestialBodies: 'celestial-body',
	ships: 'ship',
	other: 'other',
	contacts: 'contact',
}

enum TYPES {
	UPDATE_MAP_OBJECT_STORE = 'UPDATE_MAP_OBJECT_STORE',
	CLEAR_MAP_OBJECT_STORE = 'CLEAR_MAP_OBJECT_STORE',
	UPDATE_SERVER_SIDE_OBJECT = 'UPDATE_SERVER_SIDE_OBJECT',
}

/**
 * this is the function which actually updates the server side map object.
 * It is debounced by the arguments, meaning that if the category or string
 * differ from a previous call to this function it will consider that a new
 * debounce.  Note: in this version of lodash it appears that it does not look
 * into objects to see if they are equal but instead just checks to see if an
 * object appeared there in a previous call, meaning that this function always
 * evaluates an object as equalling any other object.
 *
 * The _.memoizeDebounce function is a mixin to lodash and is defined in the
 * /setup/lodashMixins
 */
// @ts-expect-error memoizeDebounce is defined in setup/lodashMixins
const _debouncedUpdateServerFunction = _.memoizeDebounce(
	function (
		category: 'ships' | 'celestialBodies' | 'other' | 'contacts',
		id: string,
		dispatch: Dispatch<AnyAction>,
		getState: () => ReduxStore
	) {
		const serverResource = mapStoreTypesToServerResource[category]
		const object = getState().mapObjects[category][id]
		axios
			.patch(`${config.simulationsApiUrl}/api/map-object/${serverResource}/${id}`, object)
			.then((response) => {
				const object = response.data
				dispatch(
					updateMapObjectStore({
						[category]: {
							[object._id]: {
								$set: object,
							},
						},
					})
				)
			})
			.catch((err) => {
				console.error(err)
			})
	},
	3000,
	{
		resolver: function (category: string, id: string) {
			return category + id
		},
	}
)

/**
 * this function debounces the update by the id and category.  The actual object
 * which will be saved on the server will be taken out of the redux store once
 * the debounce is finished
 */
export const debounceUpdateServerObject = function (category: string, id: string) {
	return ({ dispatch, getState }: { dispatch: Dispatch; getState: () => ReduxStore }) => {
		_debouncedUpdateServerFunction(category, id, dispatch, getState)
	}
}

type UpdateMapObjectStoreAction = {
	type: TYPES.UPDATE_MAP_OBJECT_STORE
	payload: Spec<State>
}

export function updateMapObjectStore(updateObject: Spec<State>): UpdateMapObjectStoreAction {
	return {
		type: TYPES.UPDATE_MAP_OBJECT_STORE,
		payload: updateObject,
	}
}

export default function mapObjectsReducer(
	state: State = INITIAL_STATE,
	action:
		| UpdateMapObjectStoreAction
		| {
				type: TYPES.CLEAR_MAP_OBJECT_STORE
		  }
): State {
	switch (action.type) {
		case TYPES.UPDATE_MAP_OBJECT_STORE:
			return update(state, action.payload)

		case TYPES.CLEAR_MAP_OBJECT_STORE:
			return INITIAL_STATE

		default:
			return state
	}
}
export function getObjects(state: { mapObjects: State }): State {
	return state.mapObjects
}

/**
 * Gets the map object with the given id
 */
export function getMapObject(mapObjectId: string, state: ReduxStore): OtherObject | undefined {
	return state.mapObjects.other[mapObjectId]
}
