import React, { useEffect, useRef } from 'react'

import { useCreateStandard, useStandardSets, useStandards } from './hooks'
import { groupBy } from 'lodash'
import { Container, Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap'
import FontAwesome from 'react-fontawesome'
import { EditStandard } from './EditStandard'
import type { Standard } from '../../types/AutomatedSimulation'
import styled from 'styled-components'
import classnames from 'classnames'
import { toast } from 'react-toastify'
import { useLocation } from 'react-router-dom'
import { Collapse } from '../../common/Collapse'

/**
 * A page for displaying, editing, adding, and deleting standards. These are the standards that will be available to add to simulations.
 */
export function StandardsPage(): JSX.Element {
	const { data: standards, isLoading: loadingStandards } = useStandards()
	const { data: standardSets, isLoading: loadingStandardSets } = useStandardSets()
	const [expandedSections, setExpandedSections] = React.useState<Record<string, boolean>>({})
	const [createModalIsOpen, setCreateModalIsOpen] = React.useState(false)

	const groupedStandards: { [standardSetId: string]: Array<Standard<string>> } = standards
		? groupBy(standards, (s) => s.standardSet)
		: {}

	const { hash } = useLocation()
	const handledHash = useRef(false)

	const hashStandard = !!hash && standards?.find((standard) => standard._id === hash.slice(1))

	// Expand the standard set for the `hashStandard`, and scroll to the `hashStandard`
	useEffect(() => {
		if (!hashStandard || handledHash.current) {
			return
		}

		// wait for standardSets to load before expanding the section and scrolling
		if (!standardSets) {
			return
		}

		setExpandedSections((expandedSections) => ({
			...expandedSections,
			[hashStandard.standardSet]: true,
		}))

		// Wait for the standard to get to the right location before scrolling
		setTimeout(() => {
			document.getElementById(hashStandard._id)?.scrollIntoView({
				behavior: 'smooth',
			})
		}, 500)
		handledHash.current = true
	}, [hashStandard, standardSets])

	return (
		<Container>
			<h1 className="d-flex justify-content-between align-items-center">
				Standards{' '}
				<Button color="primary" onClick={() => setCreateModalIsOpen(true)}>
					Add Standard
				</Button>
			</h1>
			{loadingStandards || loadingStandardSets || !standardSets
				? 'Loading...'
				: Object.keys(groupedStandards)
						.sort((abbr1, abbr2) => abbr1.localeCompare(abbr2))
						.map((standardSetId) => (
							<div key={standardSetId} className="rounded shadow p-2 mb-2">
								<h2
									className={classnames('d-flex align-items-center', {
										'mb-0': !expandedSections[standardSetId],
									})}>
									<Button
										css="margin-right: 8px;"
										size="sm"
										aria-label={expandedSections[standardSetId] ? 'Collapse' : 'Expand'}
										onClick={() =>
											setExpandedSections((expandedSections) => ({
												...expandedSections,
												[standardSetId]: !expandedSections[standardSetId],
											}))
										}>
										<FontAwesome
											name={expandedSections[standardSetId] ? 'chevron-up' : 'chevron-down'}
										/>
									</Button>
									{standardSets[standardSetId]?.internalName}
								</h2>
								<Collapse isOpen={!!expandedSections[standardSetId]}>
									<StandardSetData>
										<span>
											<b>Abbreviation:</b> {standardSets[standardSetId]?.abbreviation || 'None'}
										</span>
										<span>
											<b>States:</b>{' '}
											{standardSets[standardSetId]?.states
												.map((state) => {
													return state.name
												})
												.join(', ') || 'None'}
										</span>
										<span>
											<b>Subject:</b> {standardSets[standardSetId]?.subject.name || 'None'}
										</span>
									</StandardSetData>
									{/* eslint-disable-next-line @typescript-eslint/no-non-null-assertion */}
									{[...groupedStandards[standardSetId]!]
										.sort((standard1, standard2) => standard1.name.localeCompare(standard2.name))
										.map((standard) => {
											return (
												<EditStandard
													key={standard._id}
													standard={standard}
													startsExpanded={!!hashStandard && hashStandard._id === standard._id}
												/>
											)
										})}
								</Collapse>
							</div>
						))}
			<CreateStandardModal isOpen={createModalIsOpen} toggle={() => setCreateModalIsOpen(false)} />
		</Container>
	)
}

/**
 * A modal for creating a new standard.
 */
function CreateStandardModal({
	isOpen,
	toggle,
}: {
	isOpen: boolean
	toggle: () => void
}): JSX.Element {
	const defaultStandard = {
		_id: '',
		standardSet: '',
		grade: '',
		name: '',
		summary: '',
		full: '',
		keyword: '',
		url: '',
	}
	const [newStandard, setNewStandard] = React.useState<Standard<string>>(defaultStandard)
	const { mutateAsync: createStandard, isLoading } = useCreateStandard()

	return (
		<Modal isOpen={isOpen} toggle={() => toggle()} labelledBy="create-standard-header">
			<ModalHeader id="create-standard-header">Create Standard</ModalHeader>
			<ModalBody>
				<EditStandard standard={newStandard} setStandard={setNewStandard} collapsible={false} />
			</ModalBody>
			<ModalFooter>
				<Button
					color="primary"
					onClick={() => {
						createStandard(newStandard)
							.then(() => {
								toggle()
								setNewStandard(defaultStandard)
							})
							.catch((e) => {
								toast.error(`Error updating standard: ${e.message}`)
							})
					}}
					disabled={isLoading}>
					Create
				</Button>
			</ModalFooter>
		</Modal>
	)
}

const StandardSetData = styled.div`
	display: flex;
	justify-content: space-between;
	padding: 5px 20px;
`
