import { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { getTitle } from '../actionDefinitions'
import { selectors } from '../../../setup/tiles'
import { forEachEntry } from '../../../helpers/functions'
import { isScreenAction } from '../../../reducers/simulationEditor'
import { getObjects as getMapObjects } from '../../../reducers/mapObjects'
import { getFullMapsFromNormalizedMaps } from '../../maps/tiles'

import validation, {
	type ComplexValidationResultWithWarnings,
	type FaultTrace,
} from '@mission.io/simulation-validation'
import type { Action } from '@mission.io/mission-toolkit/actions'
import type { AutomatedSimulation } from '../../../types/AutomatedSimulation'
import type { FullMap } from '../../../types/MapTypes'
import { IdMap } from '../../../types/util'

/**
 * A custom hook which returns an array of full maps that the edited simulation uses.
 * This is a necessary hook because the main function, validateSimulation from the module simulation-validation
 * expects all maps to contain map objects (with information regarding if the object is hidden) and not only map object ids.
 */
export function useFullMapsFromSimulation(
	editorSimulation: AutomatedSimulation | null | undefined
): Array<FullMap> {
	const allMaps = useSelector(selectors.maps.store)
	const allMapObjects = useSelector(getMapObjects)
	const mapIds = editorSimulation?.mapIds
	return useMemo(() => {
		if (mapIds && mapIds.length > 0) {
			const normalizedMaps = mapIds.map((mapId) => allMaps[mapId])
			// @ts-expect-error TS2345 SUPPRESS ERRORS FOR NEW OPTION noUncheckedIndexedAccess
			return getFullMapsFromNormalizedMaps(normalizedMaps, allMapObjects)
		}

		return []
	}, [mapIds, allMapObjects, allMaps])
}

/**
 * Parses a validation result
 * @param {ValidationResult} result
 * @param {AutomatedSimulation} simulation
 * @return {trace, error, unreachable} stringified versions of each value from the ValidationResult.
 *    Trace: The fault trace, a string stating on which action the simulation failed to end with its location (screen / phase)
 *	  Error: An error message which the ValidationResult chose to send back
 * 	  Unreachable: A list of actions which cannot be reached on the simulation.
 */
export function stringifyValidationResult(
	result: ComplexValidationResultWithWarnings,
	simulation: AutomatedSimulation
): {
	trace?: string
	error?: string
	unreachable?: string
} {
	const stringifiedResult: {
		error?: string
		trace?: string
		unreachable?: string
	} = {}

	if (result.ending.error) {
		stringifiedResult.error = result.ending.error
	}

	if (result.ending.faultTrace) {
		stringifiedResult.trace = stringifyFaultTrace(result.ending.faultTrace, simulation)
	}

	if (result.unreachable.length > 0) {
		stringifiedResult.unreachable = result.unreachable
			.map((actionId: string) => {
				const action = simulation.actions[actionId]
				return action ? getTitle(action) : `Missing action with id: ${actionId}`
			})
			.join(',')
	}

	return stringifiedResult
}
type StoryType = keyof typeof validation.constants.FAULT_TRACE_TYPES
type Story = {
	id: string
	parents: string[]
	type: StoryType
}

/**
 * Converts a FaultTrace to an error message string. Since the fault trace is somewhat of a black box, we wrap the parser
 * in an try/catch to avoid crashing browser if the fault trace contains unexpected data.
 * @param {*} faultTrace
 * @param {*} simulation
 */
function stringifyFaultTrace(faultTrace: FaultTrace, simulation: AutomatedSimulation): string {
	const { actions } = simulation
	const stories: Story[] = []

	try {
		findFaultTraceStories(faultTrace, null, [], stories, actions)
		return stories
			.map((story) => {
				const { id, parents, type } = story
				const [actionId, extraMessage] = getActionIdAndMessageFromNodeId(id)

				if (actionId) {
					let action = actions[actionId]

					if (!action) {
						if (parents.length > 0) {
							// @ts-expect-error TS2538 SUPPRESS ERRORS FOR NEW OPTION noUncheckedIndexedAccess
							action = actions[parents[parents.length - 1]]
						} else return `Error: validation failed with ${id}: ${String(type)}`
					}

					const msg = `${getPrettyType(type)} after ${extraMessage || ''} ${getTitle(
						// @ts-expect-error TS2345 SUPPRESS ERRORS FOR NEW OPTION noUncheckedIndexedAccess
						action
					)} (${getParentInfo(parents, simulation.actions)})`
					return msg
				}

				return null
			})
			.join(`\n`)
	} catch {
		console.error(faultTrace) // Log the fault trace so a member of the dev team can help user figure out error

		return 'It appears that the simulation cannot reach an end. Unable to provide additional details. Contact dev team for additional help.'
	}
}

/**
 * Finds stories within a fault trace.
 * The function was created because it's easier to stringify an array of Story Types
 * than to stringify the fault trace graph object. The function traverses the fault trace graph recursively.
 * @param {FaultTrace} node the current node in the fault trace
 * @param {string} id  the id of the current node in the fault trace
 * @param {Story[]} stories an array containing information regarding endpoints in the fault trace
 * @param {IdMap<Action>} actions A map of all the actions on the simulation
 * @param {string[]} parents current trace of parent actions that the node was born from.
 */
function findFaultTraceStories(
	node: FaultTrace,
	id: string | null | undefined,
	parents: string[],
	stories: Story[],
	actions: IdMap<Action<string>>
) {
	/* Check for false endings: the fault trace can include a mapping of the global node to an unending loop
   (because the global node can point back to itself) this does not
   mean that we've reached the end yet.*/
	if (
		node.type === validation.constants.FAULT_TRACE_TYPES.UNENDING_LOOP &&
		id === validation.constants.GLOBAL_NODE_ID
	) {
		return
	}

	// If the node has a type, then we know we've reached an ending in the fault trace graph, and a story can be added.
	// See the type for FaultTrace for further details.
	if (node.type && typeof node.type === 'string' && id) {
		const type: StoryType = node.type
		stories.push({
			type: type,
			id: id,
			parents: parents,
		})
	} else {
		// We know that node is now a map of ids to more FaultTraces because it doesn't contain 'type'
		forEachEntry(node as Record<string, FaultTrace>, (trace, nodeId) => {
			// Check for additional nestings within the fault trace node
			if (actions[nodeId]) {
				if (parents) {
					parents = [...parents, nodeId]
				} else parents = [nodeId]
			}

			findFaultTraceStories(trace, nodeId, parents, stories, actions)
		})
	}

	return
}

/**
 * Gets user friendly word for a FaultTrace Story type
 * @param {StoryType} type
 */
function getPrettyType(type: StoryType) {
	return type === validation.constants.FAULT_TRACE_TYPES.UNENDING_LOOP
		? 'Infinite loop'
		: type === validation.constants.FAULT_TRACE_TYPES.INVALID_STATE
		? 'State is wrong'
		: type === validation.constants.FAULT_TRACE_TYPES.NO_PATH
		? 'No clear path to end'
		: `Unknown issue`
}

/**
 * Given an array of parents connected to an action, returns a user friendly string stating what phase and screen
 * the error occurred on.
 * @param {string[]} parents
 * @param {IdMap<Action>} actions
 */
function getParentInfo(parents: string[], actions: IdMap<Action<string>>): string {
	let mostRecentScreen = null
	let mostRecentPhase = null

	for (let i = parents.length - 1; i >= 0; i--) {
		// @ts-expect-error TS2538 SUPPRESS ERRORS FOR NEW OPTION noUncheckedIndexedAccess
		const latestAction = actions[parents[i]]
		if (!latestAction) continue

		if (isScreenAction(latestAction) && !mostRecentScreen) {
			mostRecentScreen = latestAction
		}

		if (isScreenAction(latestAction) && latestAction.newPhase && !mostRecentPhase) {
			mostRecentPhase = latestAction
		}
	}

	return `Found in phase: ${
		mostRecentPhase && mostRecentPhase.newPhase ? mostRecentPhase.newPhase : 'Unknown'
	}, on screen ${mostRecentScreen ? getTitle(mostRecentScreen) : 'Unknown'}`
}

/**
 * NodeIds from the simulation-validation node module contain the actionIds within them along with other
 * potential messages like 'on end' or 'timer'. This function is to help us parse through the nodeId and
 * obtain the proper message/actionId
 * @param {string} nodeId
 * @result a tuple result containing first the actionId and second a message regarding the action referenced
 */
export const getActionIdAndMessageFromNodeId = (
	nodeId: string
): [string | null | undefined, string | null | undefined] => {
	// Split the nodeIdSegments into substrings. Depending on the prefix of the nodeId, the actionId will be located in a specific segment.
	const nodeIdSegments = nodeId.split('-')
	let actionId
	let message

	if (
		nodeId.startsWith('timer') ||
		nodeId.startsWith('screen') ||
		nodeId.startsWith('on-end') ||
		nodeId.startsWith('custom-end')
	) {
		actionId = nodeIdSegments[nodeIdSegments.length - 1]

		if (nodeId.startsWith('custom-end') && nodeIdSegments[2]) {
			message = `a custom '${nodeIdSegments[2]}' event`
		} else if (nodeId.startsWith('on-end')) {
			message = 'an on end event'
		} else {
			message = `a ${nodeIdSegments[0]} event`
		}

		return [actionId, message]
	} else if (nodeId.startsWith('time-based') && nodeIdSegments[nodeIdSegments.length - 2]) {
		actionId = nodeIdSegments[nodeIdSegments.length - 2]
		return [actionId, `a time based event`]
	}

	if (nodeId === validation.constants.GLOBAL_NODE_ID) {
		return [null, null]
	}

	if (nodeId === 'result') {
		return [null, null]
	}

	return [nodeId, null]
}
