import React, { ChangeEvent } from 'react'
import { Button, Row, Col } from 'reactstrap'
import { Field } from 'redux-form'
import FontAwesome from 'react-fontawesome'
import styled from 'styled-components'
import { getIcon } from './icons'
import StationSelector from './StationSelection'
import type { SubHeader, SubHeaderType } from '../../../types'
import EffectSelector from './EffectSelector'
import { specialEffectsList } from '../specialEffects/index'
type Props = {
	detail: SubHeader
	subHeaderName: string
	remove: () => void
	selectionChange: (newSubHeader: SubHeader) => void
}
export default function GuideDetail({
	detail,
	subHeaderName,
	remove,
	selectionChange,
}: Props): JSX.Element {
	const getColLength = () => {
		if (detail.type === 'EFFECT') return 5
		if (detail.type === 'STATION') return 3
		else return 8
	}

	return (
		<div className="mb-2">
			{detail && (
				<Row>
					<Col xs="3">
						<Centered>
							<Row>
								<IconWrapper>
									<Icon>{getIcon(detail.type)}</Icon>
								</IconWrapper>
								<Field
									className="form-control w-75 d-inline"
									type="select"
									component="select"
									onChange={(e: ChangeEvent<HTMLSelectElement>) => {
										const selection = e.currentTarget.value as SubHeaderType | 'EFFECT' | 'STATION'
										let newSubHeader: SubHeader

										if (selection === 'STATION') {
											newSubHeader = {
												type: 'STATION',
												text: detail.text ? detail.text : '',
												jrStation: 'POWER',
												jrPlusStation: 'POWER',
											}
										} else if (selection === 'EFFECT') {
											newSubHeader = {
												type: 'EFFECT',
												// @ts-expect-error TS2322 SUPPRESS ERRORS FOR NEW OPTION noUncheckedIndexedAccess
												effect: specialEffectsList[0],
												text: detail.text ? detail.text : '',
											}
										} else {
											newSubHeader = {
												type: selection,
												text: detail.text ? detail.text : '',
											}
										}

										selectionChange(newSubHeader)
									}}
									name={`${subHeaderName}.type`}>
									<option value={'QUESTION'}>Question</option>
									<option value={'STATION'}>Station</option>
									<option value={'TEXT'}>Text</option>
									<option value={'EFFECT'}>Effect</option>
								</Field>
							</Row>
						</Centered>
					</Col>
					{detail.type === 'EFFECT' && (
						<Col xs={3}>
							<Centered>
								<EffectSelector
									effect={detail.effect}
									display={(value) => {
										const name = value.split('.').pop()
										if (!name) {
											throw new Error('Effect name not found')
										}
										return name
									}}
									customEffectList={true}
									onChange={(effect: string) => {
										const newGuide: SubHeader = { ...detail, effect: effect }
										selectionChange(newGuide)
									}}
								/>
							</Centered>
						</Col>
					)}
					{detail.type === 'STATION' && (
						<Col xs={5}>
							<StationSelector name={subHeaderName} guide={detail} />
						</Col>
					)}
					<Col xs={getColLength()}>
						<Centered>
							<Field
								className="form-control"
								name={`${subHeaderName}.text`}
								placeholder="Detail..."
								component="input"
							/>
						</Centered>
					</Col>

					<Col xs={1} className="pl-0">
						<Centered className="ml-1">
							<DeleteButton color="danger" onClick={() => remove()}>
								<FontAwesome name="trash" />
							</DeleteButton>
						</Centered>
					</Col>
				</Row>
			)}
		</div>
	)
}
const IconWrapper = styled.div`
	min-width: 32px;
`
const DeleteButton = styled(Button)`
	min-width: fit-content;
`
const Icon = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
`
const Centered = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	min-height: 100%;
`
