import React, { ReactNode } from 'react'
import produce from 'immer'
import type {
	LiteracyEventReadingContext,
	LiteracyEventTaskList,
	LiteracyEventTask,
	LiteracyEventAction,
	LiteracyEventMultipleChoiceTask,
	LiteracyEventSpeakingTask,
} from '@mission.io/mission-toolkit/actions'
import styled from 'styled-components'
import { TeamsEditor } from '../CreativeCanvasEditor'
import RelevanceSelector from '../../../../maps/Map/MapObjects/MapObjectForm/RelevanceSelector'

import {
	Button,
	UncontrolledCollapse,
	UncontrolledDropdown,
	DropdownToggle,
	DropdownMenu,
	DropdownItem,
	FormGroup,
	Label,
	UncontrolledTooltip,
} from 'reactstrap'
import {
	createMultipleChoiceOption,
	getNewReadingContext,
	getNewTaskList,
	getNewTask,
	isTaskTypeSupported,
} from './helpers'
import { MediaInput, StringInput, Icon, BooleanInput, TimeInput } from '../BasicFormComponents'
import MarkdownInput from '../../../../../common/MarkdownInput'
import FormComponentWrapper, { StyledLabel } from '../../FormComponentWrapper'
import {
	duplicateObjectAndReplaceIds,
	indexToAlpha,
	prettifyTypeEnum,
} from '../../../../../helpers/functions'
import { runWithConfirmationMessage } from '../../../../../helpers/uiFunctions'
import { useId } from 'react'
import { BiInfoCircle } from 'react-icons/bi'
import classnames from 'classnames'
import {
	getSpeakingTaskPreparser,
	getStudentNameCustomComponent,
	getTeamNameCustomComponent,
} from '../../../../../common/customMarkdownComponents'
import Select from 'react-select'
import { LITERACY_EVENT } from '@mission.io/mission-toolkit/constants'
import { CloseReadingTask } from './CloseReadingTask'

export type ReadingContextOnChangeEvent = {
	type: 'LITERACY_EVENT_READING_CONTEXT'
	readingContexts: LiteracyEventReadingContext<string>[]
	assignment: LiteracyEventAction<string>['assignment']
}

/**
 * A form component used to create/update/remove reading contexts for a literacy event.
 * @param {Array<LiteracyEventReadingContext>} props.value an array of reading contexts. (literacyEvent.readingContexts)
 * @param {Array<LiteracyEventReadingContext> => mixed} props.onChange called when updates are made to the reading contexts
 */
export function ReadingContextEditor({
	value: readingContexts,
	onChange: onChangeReadingContextsAndAssignment,
	rootAction,
}: {
	value: Array<LiteracyEventReadingContext<string>>
	onChange: (onChangeEvent: ReadingContextOnChangeEvent) => unknown
	rootAction: LiteracyEventAction<string>
}): JSX.Element {
	const onChangeReadingContexts = (newReadingContexts: LiteracyEventReadingContext<string>[]) => {
		onChangeReadingContextsAndAssignment({
			type: 'LITERACY_EVENT_READING_CONTEXT',
			readingContexts: newReadingContexts,
			assignment: rootAction.assignment,
		})
	}
	const onTeamAssignment = (teams: string[], readingContextId: string) => {
		if (rootAction.assignment.type !== LITERACY_EVENT.ASSIGNMENT.TYPE.PER_TEAM) {
			return
		}
		const allTeams = rootAction.assignment.teams
		const teamSet = new Set(teams)
		const assignment = produce(rootAction.assignment, (draft) => {
			draft.teams = allTeams.filter((team, index) => {
				const teamHasThisReadingContext = team.readingContexts.includes(readingContextId)
				if (teamSet.has(team.teamId)) {
					if (!teamHasThisReadingContext) {
						// @ts-expect-error TS2532 SUPPRESS ERRORS FOR NEW OPTION noUncheckedIndexedAccess
						draft.teams[index].readingContexts.push(readingContextId)
					}
					// Remove from reference set so we know which teams to add to assignment later.
					teamSet.delete(team.teamId)
				} else if (teamHasThisReadingContext && !teamSet.has(team.teamId)) {
					// If we allow multiple readingContexts in the future (string[] instead of [string]), this line will need to be here.
					// @ts-expect-error if this change is made in the future, all that should be needed is to remove these expect errors
					draft.teams[index].readingContexts = team.readingContexts.filter(
						(id) => id !== readingContextId
					)
					// @ts-expect-error see above message
					if (draft.teams[index].readingContexts.length === 0) {
						return false
					}
				}
				return true
			})
			// Add additional teams to assignment if necessary.
			for (const teamId of teamSet.values()) {
				draft.teams.push({
					teamId,
					readingContexts: [readingContextId],
				})
			}
		})
		onChangeReadingContextsAndAssignment({
			type: 'LITERACY_EVENT_READING_CONTEXT',
			readingContexts: rootAction.readingContexts,
			assignment,
		})
	}
	const isGeneralAssignment = rootAction.assignment.type === LITERACY_EVENT.ASSIGNMENT.TYPE.GENERAL
	return (
		<div>
			{readingContexts.map((readingContext, index) => {
				let teamProps = null
				if (rootAction.assignment.type === LITERACY_EVENT.ASSIGNMENT.TYPE.PER_TEAM) {
					const teams = rootAction.assignment.teams
					teamProps = {
						teamIds: teams
							.filter((team) => team.readingContexts.includes(readingContext.id))
							.map((team) => team.teamId),
						usedTeams: new Set(teams.map((team) => team.teamId)),
					}
				}
				const warningTooltipId = `warning-${readingContext.id}`
				return (
					<Boxed key={readingContext.id} $color={pickColor(index)}>
						<ReusableHeader
							className="reading-context-header"
							as="div"
							itemTitle="Reading Context"
							header={
								<div css="display: flex; gap: var(--spacing); margin-right: var(--spacing);">
									<span>Title</span>
									<StringInput
										css="height: fit-content;"
										value={readingContext.title || 'Reading Context ' + (index + 1)}
										onChange={(title) => {
											const newContexts = [...readingContexts]
											// @ts-expect-error TS2322 SUPPRESS ERRORS FOR NEW OPTION noUncheckedIndexedAccess
											newContexts[index] = { ...newContexts[index], title: title }
											onChangeReadingContexts(newContexts)
										}}
									/>
									{teamProps && (
										<div css="min-width: 40%; margin-right: var(--spacing);">
											<TeamsEditor
												placeholder={'Assign teams...'}
												onChange={(teamIds) => onTeamAssignment(teamIds, readingContext.id)}
												{...teamProps}
											/>
										</div>
									)}
									{isGeneralAssignment && index > 0 && (
										<>
											<i css="margin: auto var(--spacing);" id={warningTooltipId}>
												Warning: this will <b>NOT</b> be used in mission.
												<BiInfoCircle size={20} />
											</i>
											<UncontrolledTooltip placement="top" target={warningTooltipId}>
												{`Because assignment is set to "General", the first
										reading context will be assigned to the whole class. To assign different reading contexts 
										to different teams, toggle the assignment switch above to "Per Team".`}
											</UncontrolledTooltip>
										</>
									)}
								</div>
							}
							onDelete={() => {
								onChangeReadingContextsAndAssignment({
									type: 'LITERACY_EVENT_READING_CONTEXT',
									readingContexts: [
										...readingContexts.slice(0, index),
										...readingContexts.slice(index + 1),
									],
									assignment:
										rootAction.assignment.type === LITERACY_EVENT.ASSIGNMENT.TYPE.PER_TEAM
											? {
													type: LITERACY_EVENT.ASSIGNMENT.TYPE.PER_TEAM,
													teams: rootAction.assignment.teams.filter(
														// Right now, the readingContexts is a tuple of size one. If that gets changed, this will need to be updated.
														({ readingContexts }) => readingContexts[0] !== readingContext.id
													),
											  }
											: rootAction.assignment,
								})
							}}>
							<ReadingContext
								all={readingContexts}
								onChangeById={(id, newReadingContext) => {
									const newContexts = [...readingContexts]
									const index = newContexts.findIndex((context) => context.id === id)
									if (index > -1) {
										newContexts[index] = newReadingContext
										onChangeReadingContexts(newContexts)
									}
								}}
								readingContext={readingContext}
								onChange={(newReadingContext) => {
									const newContexts = [...readingContexts]
									newContexts[index] = newReadingContext
									onChangeReadingContexts(newContexts)
								}}
							/>
						</ReusableHeader>
					</Boxed>
				)
			})}
			<Button
				onClick={() => {
					onChangeReadingContexts([...readingContexts, getNewReadingContext()])
				}}>
				Add a reading context
			</Button>
		</div>
	)
}

// A component used to display a header for a form component that can be collapsed.
function ReusableHeader({
	header,
	children,
	itemTitle,
	onDelete,
	className,
	defaultOpen = false,
	...extraProps
}: {
	header: ReactNode
	itemTitle: string
	children: ReactNode
	onDelete?: () => void
	as?: string
	defaultOpen?: boolean
	className?: string
}) {
	const collapseId = useId()

	return (
		<>
			<Label
				{...extraProps}
				className={classnames('d-flex align-items-start', className)}
				css="margin-bottom: 0; 	font-size: 0.9rem; font-weight: 900;">
				<div css="flex:1;">{header}</div>
				<Button id={collapseId} size="sm">
					Toggle {itemTitle}
				</Button>
				{onDelete && (
					<Button
						size="sm"
						css="margin-left: var(--spacing);"
						title={'Delete ' + itemTitle}
						onClick={onDelete}
						color="danger">
						<Icon name="trash" />
					</Button>
				)}
			</Label>
			<StyledUncontrolledCollapse
				toggler={`#${CSS.escape(collapseId)}`}
				defaultOpen={Boolean(defaultOpen)}>
				{children}
			</StyledUncontrolledCollapse>
		</>
	)
}

const ANNOTATION_WARNING_STATE = {
	BEEN_WARNED: 'BEEN_WARNED',
	IS_WARNING: 'IS_WARNING',
	NOT_WARNED: 'NOT_WARNED',
}

/**
 * A form component use to create/update a single reading context for a literacy event.
 * @param {LiteracyEventReadingContext} readingContext the reading context
 * @param {LiteracyEventReadingContext => void} onChange the onChange event for the reading context when it is updated
 * @param {Array<LiteracyEventReadingContext>} all an array of all reading contexts in the literacy event
 * @param {(string, LiteracyEventReadingContext) => void} onChangeById a function that will update a reading context by id.
 */
function ReadingContext({
	all,
	onChangeById,
	readingContext,
	onChange,
}: {
	all: Array<LiteracyEventReadingContext<string>>
	onChangeById: (id: string, readingContext: LiteracyEventReadingContext<string>) => void
	readingContext: LiteracyEventReadingContext<string>
	onChange: (readingContext: LiteracyEventReadingContext<string>) => void
}) {
	const [annotationWarningState, setAnnotationWarningState] = React.useState(
		ANNOTATION_WARNING_STATE.NOT_WARNED
	)
	const onChangeText = (text: string) => {
		if (
			readingContext.taskLists.some(({ list }) =>
				list.some(
					(task) =>
						task.type === LITERACY_EVENT.TASK.TYPE.CLOSE_READING &&
						task.targetAnnotations.length > 0
				)
			)
		) {
			if (annotationWarningState === ANNOTATION_WARNING_STATE.NOT_WARNED) {
				setAnnotationWarningState(ANNOTATION_WARNING_STATE.IS_WARNING)
				runWithConfirmationMessage(
					'Editing this text might mess up the close reading task defined below. Are you sure you want to continue?',
					() => {
						setAnnotationWarningState(ANNOTATION_WARNING_STATE.BEEN_WARNED)
					},
					'Yes',
					'WARN'
				)
				return
			} else if (annotationWarningState === ANNOTATION_WARNING_STATE.IS_WARNING) {
				return
			}
		}
		onChange({ ...readingContext, text })
	}
	return (
		<>
			<FormComponentWrapper
				label="Relevance"
				css="margin: var(--spacing) var(--spacing) var(--spacing3x) 0 !important; max-width: 400px;">
				<div css="margin-left: var(--spacing);">
					<RelevanceSelector
						value={readingContext.relevance}
						onChange={(value) => {
							onChange({
								...readingContext,
								relevance: value,
							})
						}}
						name="relevance"
					/>
				</div>
			</FormComponentWrapper>

			<FormComponentWrapper label="Text">
				<MarkdownInput onChange={onChangeText} value={readingContext.text} />
			</FormComponentWrapper>
			<FormComponentWrapper label="Image" id="literacy-event-context-media">
				<MediaInput
					allowDelete
					collection="creativeCanvas"
					mediaType="VIDEO_OR_IMAGE_OR_AUDIO"
					value={readingContext.media.length === 1 ? readingContext.media[0] : null}
					onChange={(media) => {
						if (!media) {
							onChange({ ...readingContext, media: [] })
						} else {
							onChange({ ...readingContext, media: [media] })
						}
					}}
				/>
			</FormComponentWrapper>

			<TaskEditor
				myReadingContext={readingContext}
				allReadingContexts={all}
				changeReadingContextById={onChangeById}
				taskLists={readingContext.taskLists}
				onChange={(taskLists) => {
					// If by any chance the user updates the close reading task from the task lists, we will want to re-warn them about editing the main text.
					if (annotationWarningState === ANNOTATION_WARNING_STATE.BEEN_WARNED) {
						setAnnotationWarningState(ANNOTATION_WARNING_STATE.NOT_WARNED)
					}
					onChange({ ...readingContext, taskLists })
				}}
			/>
		</>
	)
}

/**
 * A form component that displays a list of tasks for a single reading context.
 * Technically it represents a list of a list of tasks. The highest level list is for a group of
 * students assigned to the literacy event reading context, where each student will be assigned a single "task list" in the array.
 * @param {LiteracyEventTaskList[]} props.taskLists an array of task lists, where each group is a "task" that will be assigned to a student.
 * @param {LiteracyEventTaskList[] => void} props.onChange called when creator updates, adds to, or removes from the task lists.
 * @param {string} props.myReadingContextId the id of the reading context that this task list is in.
 * @param {LiteracyEventReadingContext[]} props.allReadingContexts all reading contexts in the literacy event.
 * @param {(string, LiteracyEventReadingContext) => void} props.changeReadingContextById a function that will update a reading context by id.
 * @returns
 */
function TaskEditor({
	myReadingContext,
	allReadingContexts,
	changeReadingContextById,
	taskLists,
	onChange,
}: {
	myReadingContext: LiteracyEventReadingContext<string>
	allReadingContexts: LiteracyEventReadingContext<string>[]
	changeReadingContextById: (
		id: string,
		readingContext: LiteracyEventReadingContext<string>
	) => void
	taskLists: LiteracyEventTaskList<string>[]
	onChange: (taskLists: LiteracyEventTaskList<string>[]) => void
}) {
	const onTaskUpdate = (
		task: LiteracyEventTask<string>,
		taskIndex: number,
		taskListIndex: number
	) => {
		onChange(
			produce(taskLists, (draft) => {
				if (!draft[taskListIndex]) {
					draft[taskListIndex] = { list: [task] }
				} else if (!draft[taskListIndex].list[taskIndex]) {
					draft[taskListIndex].list.push(task)
				} else {
					draft[taskListIndex].list[taskIndex] = task
				}
			})
		)
	}
	const myReadingContextId = myReadingContext.id
	return (
		<>
			{taskLists.map((taskList, index) => {
				return (
					<div key={`literacy-event-task-list-${index}`}>
						<Boxed>
							<ReusableHeader
								header={<span>Task List {index + 1} </span>}
								defaultOpen
								itemTitle="list"
								onDelete={() =>
									onChange([...taskLists.slice(0, index), ...taskLists.slice(index + 1)])
								}>
								{taskList.list.map((task, taskIndex) => (
									<Boxed key={task.id}>
										<ReusableHeader
											header={
												<h6 css="flex: 1; display: flex; justify-content: space-between; margin-right: var(--spacing);">
													Task {taskIndex + 1} for Student {indexToAlpha(index)}:
													<DuplicateTo
														myReadingContextId={myReadingContextId}
														value={task}
														allReadingContexts={allReadingContexts}
														changeReadingContextById={changeReadingContextById}
													/>
												</h6>
											}
											itemTitle="Task"
											onDelete={() => {
												const newTaskLists = [...taskLists]
												newTaskLists[index] = {
													list: [
														...taskList.list.slice(0, taskIndex),
														...taskList.list.slice(taskIndex + 1),
													],
												}
												onChange(newTaskLists)
											}}>
											{task.type === LITERACY_EVENT.TASK.TYPE.MULTIPLE_CHOICE ? (
												<MultipleChoiceTask
													key={task.id}
													task={task}
													onChange={(newTask) => {
														onTaskUpdate(newTask, taskIndex, index)
													}}
												/>
											) : task.type === LITERACY_EVENT.TASK.TYPE.SPEAKING ? (
												<SpeakingTask
													key={task.id}
													task={task}
													onChange={(newTask) => {
														onTaskUpdate(newTask, taskIndex, index)
													}}
													allReadingContexts={allReadingContexts}
												/>
											) : task.type === LITERACY_EVENT.TASK.TYPE.CLOSE_READING ? (
												<CloseReadingTask
													key={task.id}
													task={task}
													onChange={(newTask) => {
														onTaskUpdate(newTask, taskIndex, index)
													}}
													readingContextText={myReadingContext.text}
												/>
											) : null}
										</ReusableHeader>
									</Boxed>
								))}
							</ReusableHeader>
							<UncontrolledDropdown size="sm">
								<DropdownToggle caret>Add Task</DropdownToggle>
								<DropdownMenu>
									{Object.keys(LITERACY_EVENT.TASK.TYPE).map((taskTypeKey) => {
										const taskType =
											LITERACY_EVENT.TASK.TYPE[taskTypeKey as keyof typeof LITERACY_EVENT.TASK.TYPE]
										return (
											<DropdownItem
												disabled={!isTaskTypeSupported(taskType)}
												key={taskTypeKey}
												onClick={() => {
													onTaskUpdate(getNewTask(taskType), taskList.list.length, index)
												}}>
												{prettifyTypeEnum(taskType)}
											</DropdownItem>
										)
									})}
								</DropdownMenu>
							</UncontrolledDropdown>
						</Boxed>
					</div>
				)
			})}
			<Button
				size="sm"
				onClick={() => {
					onChange([...taskLists, getNewTaskList()])
				}}>
				Add a task list for a different student
			</Button>
		</>
	)
}

/**
 * A helpful button that allows the user to duplicate the current task into another reading context.
 * @param props
 * @param {string} props.myReadingContextId the id of the reading context that this task is in.
 * @param {LiteracyEventTask} props.value the task that will be duplicated.
 * @param {LiteracyEventReadingContext[]} props.allReadingContexts all reading contexts in the literacy event.
 * @param {(string, LiteracyEventReadingContext) => void} props.changeReadingContextById a function that will update a reading context by id.
 */
function DuplicateTo({
	myReadingContextId,
	value,
	allReadingContexts,
	changeReadingContextById,
}: {
	myReadingContextId: string
	value: LiteracyEventTask<string>
	allReadingContexts: LiteracyEventReadingContext<string>[]
	changeReadingContextById: (
		id: string,
		readingContext: LiteracyEventReadingContext<string>
	) => void
}) {
	return (
		<UncontrolledDropdown size="sm">
			<DropdownToggle caret>Duplicate To</DropdownToggle>
			<DropdownMenu>
				{allReadingContexts.map((readingContext, index) => (
					<React.Fragment key={readingContext.id}>
						<DropdownItem header key={`header-reading-context-${readingContext.id}}`}>
							{readingContext.title || 'Reading Context ' + (index + 1)}
							{readingContext.id === myReadingContextId ? ' (this reading context)' : ''}
						</DropdownItem>
						{readingContext.taskLists.map((taskList, index) => (
							<DropdownItem
								key={`tasklist-index-${index}-${readingContext.id}`}
								onClick={() =>
									changeReadingContextById(
										readingContext.id,
										produce(readingContext, (draft) => {
											// @ts-expect-error TS2532 SUPPRESS ERRORS FOR NEW OPTION noUncheckedIndexedAccess
											draft.taskLists[index].list.push(duplicateObjectAndReplaceIds(value))
										})
									)
								}>
								{`Task List ${index + 1}`}
							</DropdownItem>
						))}
					</React.Fragment>
				))}
			</DropdownMenu>
		</UncontrolledDropdown>
	)
}

const MAX_MULTIPLE_CHOICE_OPTIONS = 4
const MAX_PROMPT_CHARACTER_LENGTH = 120
/**
 * A component used to create/update a multiple choice task.
 * @param {LiteracyEventMultipleChoiceTask} props.task the multiple choice task
 * @param {LiteracyEventMultipleChoiceTask => void} props.onChange the onChange event for the multiple choice task when it is updated
 */
function MultipleChoiceTask({
	task,
	onChange,
}: {
	task: LiteracyEventMultipleChoiceTask<string>
	onChange: (newMultipleChoiceTask: LiteracyEventMultipleChoiceTask<string>) => void
}) {
	const promptCharacterCount = task.prompt.length
	return (
		<>
			<FormComponentWrapper label="Prompt">
				<StringInput value={task.prompt} onChange={(prompt) => onChange({ ...task, prompt })} />
				<div
					css="margin-top: .5rem; display: flex; justify-content: space-between;"
					style={{ color: promptCharacterCount > MAX_PROMPT_CHARACTER_LENGTH ? 'red' : 'black' }}>
					<div>Max {MAX_PROMPT_CHARACTER_LENGTH} Characters</div>
					<b>{MAX_PROMPT_CHARACTER_LENGTH - promptCharacterCount}</b>
				</div>
			</FormComponentWrapper>
			{task.options.map(
				(
					option: {
						text: string
						correct: boolean
						explanation?: string
					},
					index
				) => (
					<Boxed key={`option_${index}`}>
						<StyledLabel className="d-flex justify-content-between" as="div">
							<h6>Option {index + 1}</h6>
							<Button
								className="ml-2"
								color="danger"
								size="sm"
								onClick={() => {
									onChange(
										produce(task, (draft: LiteracyEventMultipleChoiceTask<string>) => {
											draft.options.splice(index, 1)
										})
									)
								}}>
								<Icon name="trash" />
							</Button>
						</StyledLabel>
						<FormGroup>
							<StyledLabel className="d-flex" as="div">
								<span css="flex: 1;">Text</span>
								<span css="flex: 2;">
									Correct{' '}
									<BooleanInput
										value={option.correct}
										onChange={(newCorrect) => {
											onChange(
												produce(task, (draft: LiteracyEventMultipleChoiceTask<string>) => {
													// @ts-expect-error TS2532 SUPPRESS ERRORS FOR NEW OPTION noUncheckedIndexedAccess
													draft.options[index].correct = newCorrect
												})
											)
										}}
									/>
								</span>
							</StyledLabel>
							<MarkdownInput
								previewVertical={true}
								value={option.text}
								onChange={(newText) => {
									onChange(
										produce(task, (draft: LiteracyEventMultipleChoiceTask<string>) => {
											// @ts-expect-error TS2532 SUPPRESS ERRORS FOR NEW OPTION noUncheckedIndexedAccess
											draft.options[index].text = newText
										})
									)
								}}
							/>
						</FormGroup>

						{option.explanation != null ? (
							<FormGroup>
								<StyledLabel className="d-flex justify-content-between" as="div">
									<span>Explanation</span>
									<Button
										className="ml-2"
										size="sm"
										onClick={() => {
											onChange(
												produce(task, (draft: LiteracyEventMultipleChoiceTask<string>) => {
													// @ts-expect-error TS2532 SUPPRESS ERRORS FOR NEW OPTION noUncheckedIndexedAccess
													delete draft.options[index].explanation
												})
											)
										}}>
										Remove
									</Button>
								</StyledLabel>

								<MarkdownInput
									previewVertical={true}
									value={option.explanation}
									onChange={(newText) => {
										const newTask = produce(
											task,
											(draft: LiteracyEventMultipleChoiceTask<string>) => {
												// @ts-expect-error TS2532 SUPPRESS ERRORS FOR NEW OPTION noUncheckedIndexedAccess
												draft.options[index].explanation = newText
											}
										)
										onChange(newTask)
									}}
								/>
							</FormGroup>
						) : (
							<Button
								size="sm"
								onClick={() => {
									onChange(
										produce(task, (draft: LiteracyEventMultipleChoiceTask<string>) => {
											// @ts-expect-error TS2532 SUPPRESS ERRORS FOR NEW OPTION noUncheckedIndexedAccess
											draft.options[index].explanation = ''
										})
									)
								}}>
								Add explanation
							</Button>
						)}
					</Boxed>
				)
			)}
			<Button
				disabled={task.options.length >= MAX_MULTIPLE_CHOICE_OPTIONS}
				className="mt-3"
				color="primary"
				size="sm"
				onClick={() => {
					onChange({
						...task,
						options: [...task.options, createMultipleChoiceOption('Another Decision')],
					})
				}}>
				{task.options.length >= MAX_MULTIPLE_CHOICE_OPTIONS ? 'Option limit reached' : 'Add Option'}
			</Button>
		</>
	)
}

const STUDENT_TARGET_CUSTOM_MARKDOWN_COMPONENTS = {
	...getStudentNameCustomComponent(
		'(required) replaced by the name of the student they are providing feedback for.'
	),
	...getTeamNameCustomComponent(
		'replaced by the name of team of the student they are providing feedback for.'
	),
}

const STUDENT_TARGET_FEEDBACK_BUTTON_CUSTOM_MARKDOWN_COMPONENTS = {
	...getStudentNameCustomComponent(
		'replaced by the name of the student they are providing feedback for.'
	),
	...getTeamNameCustomComponent(
		'replaced by the name of team of the student they are providing feedback for.'
	),
}

const STUDENT_TARGET_MARKDOWN_PREPARSER = getSpeakingTaskPreparser({
	allowTeamName: true,
	allowStudentName: true,
})

/**
 * A component used to create/update a speaking task.
 *
 * @param {LiteracyEventMultipleChoiceTask} props.task the speaking task
 * @param {LiteracyEventSpeakingTask => void} props.onChange the onChange event for the multiple choice task when it is updated
 */
function SpeakingTask({
	task,
	onChange,
	allReadingContexts,
}: {
	task: LiteracyEventSpeakingTask<string>
	onChange: (speakingTask: LiteracyEventSpeakingTask<string>) => void
	allReadingContexts: LiteracyEventReadingContext<string>[]
}) {
	const taskTarget = task.target
	let specialMarkdownComponents = {}
	let preparser = null
	if (taskTarget.type === LITERACY_EVENT.TASK.SPEAKING_TASK_DISCRIMINATORS.TARGET.STUDENT) {
		specialMarkdownComponents = {
			...getStudentNameCustomComponent(
				'(required) replaced with the name of the student who should be talked to'
			),
			...getTeamNameCustomComponent(
				'replaced with the team name of the student who should be talked to'
			),
		}
		preparser = getSpeakingTaskPreparser({ allowStudentName: true, allowTeamName: true })
	}
	const readingContextIdToTitle: { [contedId: string]: string } = {}
	allReadingContexts.forEach((context, index) => {
		readingContextIdToTitle[context.id] = context.title || `Reading Context ${index + 1}`
	})

	return (
		<>
			<FormComponentWrapper label="Prompt">
				<MarkdownInput
					value={task.prompt}
					onChange={(prompt) => onChange({ ...task, prompt })}
					customComponents={specialMarkdownComponents}
					preparser={preparser}
				/>
			</FormComponentWrapper>
			<FormComponentWrapper label="Time Before Allowing Completion">
				<TimeInput
					value={task.timeBeforeAllowingCompletion}
					onChange={(time) => onChange({ ...task, timeBeforeAllowingCompletion: time })}
				/>
			</FormComponentWrapper>
			<UncontrolledDropdown size="sm">
				<DropdownToggle caret>Target: {taskTarget.type}</DropdownToggle>
				<DropdownMenu>
					{Object.keys(LITERACY_EVENT.TASK.SPEAKING_TASK_DISCRIMINATORS.TARGET).map(
						(taskTargetType) => {
							const targetGenerator =
								SPEAKING_TASK_TARGET_DATA[
									taskTargetType as keyof typeof LITERACY_EVENT.TASK.SPEAKING_TASK_DISCRIMINATORS.TARGET
								].defaultTarget
							return (
								<DropdownItem
									disabled={!targetGenerator}
									key={taskTargetType}
									onClick={() => {
										onChange({ ...task, target: targetGenerator() })
									}}>
									{prettifyTypeEnum(taskTargetType)}
									{!targetGenerator ? ' (Not yet supported)' : null}
								</DropdownItem>
							)
						}
					)}
				</DropdownMenu>
				<SpeakingTaskTargetTypeDescription>
					{SPEAKING_TASK_TARGET_DATA[taskTarget.type]?.description ?? 'unknown target type'}
				</SpeakingTaskTargetTypeDescription>
			</UncontrolledDropdown>
			{taskTarget.type === LITERACY_EVENT.TASK.SPEAKING_TASK_DISCRIMINATORS.TARGET.STUDENT ? (
				<div css="padding-top: var(--spacing);">
					<FormComponentWrapper label="Feedback Prompt">
						<MarkdownInput
							value={taskTarget.feedbackPrompt}
							onChange={(prompt) => {
								onChange({ ...task, target: { ...taskTarget, feedbackPrompt: prompt } })
							}}
							customComponents={STUDENT_TARGET_CUSTOM_MARKDOWN_COMPONENTS}
							preparser={STUDENT_TARGET_MARKDOWN_PREPARSER}
						/>
					</FormComponentWrapper>
					<FormComponentWrapper label="Affirmative Button Text">
						<MarkdownInput
							value={taskTarget.affirmativeButtonText}
							onChange={(buttonText) => {
								onChange({ ...task, target: { ...taskTarget, affirmativeButtonText: buttonText } })
							}}
							customComponents={STUDENT_TARGET_FEEDBACK_BUTTON_CUSTOM_MARKDOWN_COMPONENTS}
							preparser={STUDENT_TARGET_MARKDOWN_PREPARSER}
						/>
					</FormComponentWrapper>
					<FormComponentWrapper label="Negative Button Text">
						<MarkdownInput
							value={taskTarget.negativeButtonText}
							onChange={(buttonText) => {
								onChange({ ...task, target: { ...taskTarget, negativeButtonText: buttonText } })
							}}
							customComponents={STUDENT_TARGET_FEEDBACK_BUTTON_CUSTOM_MARKDOWN_COMPONENTS}
							preparser={STUDENT_TARGET_MARKDOWN_PREPARSER}
						/>
					</FormComponentWrapper>
					<FormComponentWrapper label="Only pair with students in these teams (optional)">
						<TeamsEditor
							onChange={(teamIds) =>
								onChange({
									...task,
									target: {
										...taskTarget,
										restrictions: {
											...taskTarget.restrictions,
											inTeam: teamIds,
										},
									},
								})
							}
							teamIds={taskTarget.restrictions?.inTeam ?? []}
							usedTeams={new Set(taskTarget.restrictions?.inTeam ?? [])}
						/>
					</FormComponentWrapper>
					<FormComponentWrapper label="Only pair with students who have been assigned one of these reading contexts (optional)">
						<Select
							isMulti
							closeMenuOnSelect={false}
							// @ts-expect-error TS2322 SUPPRESS ERRORS FOR NEW OPTION noUncheckedIndexedAccess
							options={allReadingContexts.map(({ id }) => ({
								value: id,
								label: readingContextIdToTitle[id],
							}))}
							value={
								taskTarget.restrictions?.assignedReadingContext?.map((id) => ({
									value: id,
									label: readingContextIdToTitle[id] ?? 'Unknown reading context',
								})) ?? []
							}
							onChange={(readingContexts) =>
								onChange({
									...task,
									target: {
										...taskTarget,
										restrictions: {
											...taskTarget.restrictions,
											assignedReadingContext:
												readingContexts.length === 0
													? undefined
													: readingContexts.map(({ value }) => value),
										},
									},
								})
							}
						/>
					</FormComponentWrapper>
				</div>
			) : null}
		</>
	)
}

const SPEAKING_TASK_TARGET_DATA = {
	[LITERACY_EVENT.TASK.SPEAKING_TASK_DISCRIMINATORS.TARGET.NONE]: {
		description: 'The student is not assigned to talk to any person in particular',
		defaultTarget: () => ({
			type: LITERACY_EVENT.TASK.SPEAKING_TASK_DISCRIMINATORS.TARGET.NONE,
		}),
	},
	[LITERACY_EVENT.TASK.SPEAKING_TASK_DISCRIMINATORS.TARGET.STUDENT]: {
		description: 'Student is assigned a specific person to talk to',
		defaultTarget: () => ({
			type: LITERACY_EVENT.TASK.SPEAKING_TASK_DISCRIMINATORS.TARGET.STUDENT,
			feedbackPrompt: 'How was your interaction with {{studentName}}?',
			affirmativeButtonText: 'Good',
			negativeButtonText: 'Bad',
			restrictions: {},
		}),
	},
}

const pickColor = (index: number) => {
	return [
		'rgb(255, 235, 235)',
		'rgb(235, 255, 235)',
		'rgb(235, 235, 255)',
		'rgb(255, 255, 235)',
		'rgb(235, 255, 255)',
		'rgba(255, 235, 255)',
	][index % 6]
}

const Boxed = styled.div<{ $color?: string }>`
	${({ $color }) => $color && `background-color: ${$color};`}
	border: 1px solid #ced4da;
	padding: var(--spacing);
	border-radius: 8px;

	margin-bottom: var(--spacing2x);

	.reading-context-header {
		gap: var(--spacing);
		position: sticky;
		top: calc(var(--spacing) + 2.75rem);
		padding: var(--spacing) 0;
		z-index: 1;
		${({ $color }) => $color && `background-color: ${$color};`}
	}

	&:focus-within {
		.reading-context-header {
			z-index: 2;
		}
	}
`

const SpeakingTaskTargetTypeDescription = styled.span`
	padding-left: var(--spacing);
`

// a workaround to handle css collisions between react-strap and tailwind
const StyledUncontrolledCollapse = styled(UncontrolledCollapse)`
	&.collapse.show {
		visibility: visible;
	}
`
