import type { SimulationTeam } from '../../types/AutomatedSimulation'
import { Modal as AssetModal } from '../../common/AssetManager'
import { Label, Input, Card, Button } from 'reactstrap'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'
import styled from 'styled-components'
import { TEAM_TYPES } from '../../types/AutomatedSimulation'
import React, { useState } from 'react'
import { isFinite, clamp } from 'lodash'
import { useDispatch } from 'react-redux'
import { FaTrash } from 'react-icons/fa'
import { toast } from 'react-toastify'
import { createTemporaryId } from '../../helpers/functions'
import {
	setTeams as setTeamsInEditorSimulation,
	useEditorSimulation,
} from '../../reducers/simulationEditor'
import { useSaveContextUpdate } from '../automatedSimulations/EditorSimulationSaveContext'

/**
 * Gets all `SimulationTeam`s from the editor simulation store
 */
export function useTeams(): SimulationTeam[] {
	return useEditorSimulation()?.teams ?? []
}

/**
 * ReduxTeams - a form which allows editing teams on the editor simulation
 *
 * @returns React$Node
 */
export function ReduxTeams(): JSX.Element {
	const teams = useTeams()
	const dispatch = useDispatch()
	const onUpdate = useSaveContextUpdate()

	if (!teams) {
		return <div>No Teams On Simulation</div>
	}

	return (
		<PageWrapper>
			<Teams
				teams={teams}
				setTeams={(newTeams: SimulationTeam[]) => {
					onUpdate()
					dispatch(setTeamsInEditorSimulation(newTeams))
				}}
			/>
		</PageWrapper>
	)
}

/**
 * Teams - a form to edit a list of teams
 *
 * @param  {Object} props - the react props
 * @param  {SimulationTeam[]} props.teams - the list of teams to allow editing for
 * @param  {(teams: SimulationTeam[]) => void} props.setTeams - a callback to update the list of teams
 *
 * @returns React$Node
 */
function Teams({
	teams,
	setTeams,
}: {
	teams: SimulationTeam[]
	setTeams: (teams: SimulationTeam[]) => void
}): JSX.Element {
	const [isDragging, setIsDragging] = useState(false)
	return (
		<TeamList>
			<DragDropContext
				onDragStart={() => setIsDragging(true)}
				onDragEnd={({ destination, source }) => {
					if (!destination) {
						return
					}
					const newTeams = [...teams]
					const teamBeingMoved = newTeams[source.index]
					if (!teamBeingMoved) {
						throw new Error(`The source team was not found. This should be impossible.`)
					}
					newTeams.splice(source.index, 1)
					newTeams.splice(destination.index, 0, teamBeingMoved)
					setTeams(newTeams)
					setIsDragging(false)
				}}>
				<Droppable droppableId="teams">
					{(provided, snapshot) => (
						<div {...provided.droppableProps} ref={provided.innerRef}>
							{teams.map((team, index) => (
								<Draggable key={team._id} draggableId={team._id} index={index}>
									{(provided, snapshot) => (
										<div
											ref={provided.innerRef}
											{...provided.draggableProps}
											{...provided.dragHandleProps}>
											<TeamForm
												team={team}
												key={team._id}
												updateTeam={(newTeam) => {
													const updatedTeams = [...teams]

													if (newTeam) {
														updatedTeams[index] = newTeam
													} else {
														if (teams.length === 1) {
															toast.error('There must be at least one team in the simulation')
															return
														}

														updatedTeams.splice(index, 1)
													}

													setTeams(updatedTeams)
												}}
												index={index}
											/>
										</div>
									)}
								</Draggable>
							))}
						</div>
					)}
				</Droppable>
			</DragDropContext>
			{!isDragging ? (
				<Button
					color="primary"
					onClick={() => {
						setTeams([...teams, getDefaultTeam(`Team ${teams.length}`)])
					}}>
					Add Team
				</Button>
			) : null}
		</TeamList>
	)
}

/**
 * Gets a default team with the given name
 */
export function getDefaultTeam(teamName: string): SimulationTeam {
	return {
		_id: createTemporaryId(),
		name: teamName,
		engineeringPanelComponentType: TEAM_TYPES.COMMAND,
		icon: 'https://resources-cdn.mission.io/simulations/team-icons/CommandLogo.svg',
		required: false,
		maxStudents: 4,
		minStudents: 1,
	}
}

/**
 * TeamForm - the form for a single team
 *
 * @param  {Object} props - the react props
 * @param  {SimulationTeam} props.team - the current team
 * @param  {(team: ?SimulationTeam) => void} props.updateTeam - a callback used to update a team (null if the team should be deleted)
 * @param  {number} props.index - the index of the team in the simulation
 *
 */
function TeamForm({
	team,
	updateTeam,
	index,
}: {
	team: SimulationTeam
	updateTeam: (team: SimulationTeam | null | undefined) => void
	index: number
}) {
	const [iconSelectorOpen, setIconSelectorOpen] = useState(false)
	return (
		<StyledTeamFormWrapper>
			<StyledColumn1Wrapper>
				<StyledIconWrapper className="form-control" onClick={() => setIconSelectorOpen(true)}>
					<img src={team.icon} alt="icon" />
				</StyledIconWrapper>
				<TeamBodySingleLine>
					<Label>
						<Input
							type="checkbox"
							checked={team.required}
							onChange={(e) => {
								updateTeam({ ...team, required: e.target.checked })
							}}
						/>{' '}
						Required
					</Label>
				</TeamBodySingleLine>
			</StyledColumn1Wrapper>
			<Label>
				Name
				<Input
					type="text"
					value={team.name}
					onChange={(e) => updateTeam({ ...team, name: e.target.value })}
				/>
			</Label>
			<Label>
				Engineering Panel Team Type
				<Input
					type="select"
					value={team.engineeringPanelComponentType}
					onChange={(e) =>
						updateTeam({
							...team,
							engineeringPanelComponentType: e.target.value as keyof typeof TEAM_TYPES,
						})
					}>
					{Object.keys(TEAM_TYPES).map((teamType) => (
						<option value={teamType} key={teamType}>
							{teamType.toLocaleLowerCase()}
						</option>
					))}
				</Input>
			</Label>
			<div />
			<TrashWrapper>
				<Button onClick={() => updateTeam(null)} color="danger">
					<FaTrash size={20} />
				</Button>
			</TrashWrapper>
			<Label>
				Preferred Min Students
				<Input
					type="number"
					value={team.minStudents}
					onChange={(e) => {
						const number = Number(e.target.value)

						if (isFinite(number)) {
							updateTeam({
								...team,
								minStudents: clamp(Math.floor(number), 0, team.maxStudents - 1),
							})
						}
					}}
				/>
			</Label>
			<Label>
				Preferred Max Students
				<Input
					type="number"
					value={team.maxStudents}
					onChange={(e) => {
						const number = Number(e.target.value)

						if (isFinite(number)) {
							updateTeam({
								...team,
								maxStudents: Math.max(Math.floor(number), team.minStudents + 1),
							})
						}
					}}
				/>
			</Label>
			<StyledPriority>priority {index + 1}</StyledPriority>
			<AssetModal
				collection="teamIcons"
				isOpen={iconSelectorOpen}
				onClose={() => setIconSelectorOpen(false)}
				onFileClick={(file) => {
					updateTeam({ ...team, icon: file.url })
				}}
			/>
		</StyledTeamFormWrapper>
	)
}

const StyledPriority = styled.div`
	display: flex;
	justify-content: end;
	align-items: end;

	grid-column: col4-start / trash-end;
	opacity: 0.5;
	margin-right: var(--spacing);
`
const StyledIconWrapper = styled.div`
	height: 6em;
	width: 6em;
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 4px;
	border-radius: 4px;
	margin-bottom: var(--spacing);

	&:hover {
		background-color: #0002;
		cursor: pointer;
	}

	> * {
		max-height: 100%;
		max-width: 100%;
	}
`
const PageWrapper = styled.div`
	padding: var(--spacing4x);
	display: flex;
	flex-direction: column;
	align-items: center;
`
const StyledColumn1Wrapper = styled.div`
	grid-row: row1-start / row2-end;
	grid-column: icon-start / icon-end;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
`
const StyledTeamFormWrapper = styled(Card)`
	flex-direction: column;
	padding: var(--spacing2x);
	margin-bottom: var(--spacing);
	display: grid;
	grid-template-columns: [icon-start] 6em [icon-end column-1-start] 15em [column-1-end column-2-start] 15em [column-2-end col4-start] 5em [trash-start] 3em [trash-end];
	grid-template-rows: [row1-start] 4em [row1-end row2-start] 4em [row2-end];
	gap: var(--spacing2x);
`
const TeamBodySingleLine = styled.div`
	margin-top: 0.25em;
`
const TeamList = styled.div`
	display: flex;
	flex-direction: column;
	gap: var(--spacing2x);
	width: min-content;
	justify-content: center;
	align-items: flex-end;
`
const TrashWrapper = styled.div`
	margin-top: var(--spacing);
`
